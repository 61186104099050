import {FormattedMessage, useIntl} from "react-intl";
import React, {Dispatch, SetStateAction, useContext, useEffect, useReducer, useState} from "react";
import {useFetchAttendanceServices} from "AttendanceManagement/Controllers/attendances-controller";
import DataTable, {Column} from "Core/Table/data-table";
import {
    CreateAttendanceServicesForm,
    EditAttendanceServiceForm
} from "AttendanceManagement/Forms/attendance-service-forms";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {AttendanceServiceFormatted} from "./index";
import {
    AttendanceBreaktime,
    attendancesUrl,
    CheckInActivityType,
    CheckOutActivityType, useFetchAttendanceBreaktimesDataTable,
} from "AttendanceManagement/Controllers/attendances-controller";
import {Service, useFetchServices} from "ProjectManagement/Controller/services-controller";
import {Button} from "components/button";
import {DialogClose, DialogFooter} from "components/dialog";
import {nullOrUndefined} from "Core/constants/variables";
import moment from "moment";
import {location, locationsPayload} from "AttendanceManagement/Controllers/locations-controller";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "components/tab";
import {TaimModal} from "Core/components/taim-modal";
import {Action} from "Core/Table/data-table-actions";
import {CreateAttendanceBreaktimeForm, EditAttendanceBreaktimeForm} from "AttendanceManagement/Forms/attendance-breaktime-forms";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";
import {PencilSimple, X} from "@phosphor-icons/react";
import {TimeValue} from "react-aria";
import {getHourAndMinute} from "../../../Core/functions/get-hour-and-minute";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../../components/select";
import {attendanceManagementUrl} from "../../index";
import {TimePicker} from "../../../components/date-time-picker/time-picker";


type AttendanceBreaktimeFormatted = {
    actions: Action[]
} & AttendanceBreaktime

type ManageAttendance = {
    employeeCheckIn: CheckInActivityType
    user: {
        userUUID: string
        userName: string
    }
    services: Service[]
    employeeCheckOut?: CheckOutActivityType
    rePopulateRecord: (userUUID: string) => void
}
enum GeneralActionType {
    SETVALUE = 'SETVALUE',
    SETERROR = 'SETERROR',
    SETEDIT = "SETEDIT"
}

type TGeneralInfoState = {
  checkoutTime: {
    value: TimeValue | null;
    openForEdit: boolean;
    error: string | null;
  };
  checkoutLoc: {
    value: string | null;
    openForEdit: boolean;
    error: string | null;
  };
};

type TGeneralInfoAction =
  | { type: GeneralActionType.SETVALUE; payload: { field: 'checkoutTime'; value: TimeValue | null | undefined } | { field: 'checkoutLoc'; value: string } }
  | { type: GeneralActionType.SETERROR; payload: { field: 'checkoutTime' | 'checkoutLoc'; error: string | null } }
  | { type: GeneralActionType.SETEDIT; payload: { field: 'checkoutTime' | 'checkoutLoc'; openForEdit: boolean } };

const reducer = (state: TGeneralInfoState, action: TGeneralInfoAction): TGeneralInfoState => {
  switch (action.type) {
    case GeneralActionType.SETEDIT:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          openForEdit: action.payload.openForEdit,
        },
      };
    case GeneralActionType.SETVALUE:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          value: action.payload.value,
        },
      };
    case GeneralActionType.SETERROR:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          error: action.payload.error,
        },
      };
    default:
      throw new Error('Unhandled action type');
  }
};


export const ManageAttendance: React.FC<ManageAttendance> = (
    {
        employeeCheckIn,
        user,
        employeeCheckOut
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const generalName = intl.formatMessage({id: "attendance.attendances.general", defaultMessage: "General"});
    const breaktimesName = intl.formatMessage({id: "attendance.attendances.breaktimes", defaultMessage: "Breaktimes"});
    const servicesName = intl.formatMessage({id: "attendance.services", defaultMessage: "Services"});
    const [payload, setPayload] = useState<locationsPayload>({
        start: 0,
        length:10,
        search: "",
        ordering: "",
    });
    const {servicesRequest} = useFetchServices(payload);
    const [nonFieldErrors, setNonFieldErrors] = useState<string>();
    const [
        formattedBreaktimes,
        setFormattedBreaktimes
    ] = useState<AttendanceBreaktimeFormatted[]>([]);
    const [
        formattedServices,
        setFormattedServices
    ] = useState<AttendanceServiceFormatted[]>([]);
    const {
        attendanceBreaktimesRequest,
        attendanceBreaktimesLoading,
        shouldReloadAttendanceBreaktimes
    } = useFetchAttendanceBreaktimesDataTable({userUUID: user.userUUID, attendanceID: employeeCheckIn.pk});
    const {
        attendanceServicesRequest,
        attendanceServicesLoading,
        shouldReloadAttendanceServices
    } = useFetchAttendanceServices({attendanceID: employeeCheckIn?.pk, userUUID: user.userUUID});
    const [locations, setLocations] = useState<location[]>([]);
    const [generalInfo, dispatch] = useReducer(reducer, {
        checkoutTime: {
            value: (() => {
                return employeeCheckOut?.time ? {
                    hour: getHourAndMinute(employeeCheckOut.time, "h"),
                    minute: getHourAndMinute(employeeCheckOut.time, "m")
                } as TimeValue : undefined
            })() ?? null,
            error: null,
            openForEdit: false
        },
        checkoutLoc: {
            value: locations.find(elem => elem.name === employeeCheckOut?.location)?.uuid ?? null,
            error: null,
            openForEdit: false
        }
    })

    const attendanceBreaktimesColumns: Column[] = [
        {
            backendKey: "startTime",
            frontendKey: intl.formatMessage({id: "attendance.attendances.startTime", defaultMessage: "Start Time"})
        },
        {
            backendKey: "endTime",
            frontendKey: intl.formatMessage({id: "attendance.attendances.endTime", defaultMessage: "End Time"})
        },
        {backendKey: "actions", frontendKey: "actions"},
    ]

    const attendanceServicesColumns: Column[] = [
        {
            backendKey: "projectName",
            frontendKey: intl.formatMessage({id: "attendance.attendances.project", defaultMessage: "Project"})
        },
        {
            backendKey: "serviceName",
            frontendKey: intl.formatMessage({id: "attendance.attendances.service", defaultMessage: "Service"})
        },
        {
            backendKey: "startDatetimeFormatted",
            frontendKey: intl.formatMessage({id: "attendance.attendances.startTime", defaultMessage: "Start Time"})
        },
        {
            backendKey: "endDatetimeFormatted",
            frontendKey: intl.formatMessage({id: "attendance.attendances.endTime", defaultMessage: "End Time"})
        },
        {backendKey: "actions", frontendKey: "actions", isPublic: true},
    ]

    const fetchLocations = () => {
        axiosInstance.get(attendanceManagementUrl + 'locations/')
            .then((res) => setLocations(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        const formatData: AttendanceBreaktimeFormatted[] = attendanceBreaktimesRequest.map((breaktime, index, array) => {
            return {
                ...breaktime,
                actions: [
                    {
                        type: "edit",
                        content: (
                            <EditAttendanceBreaktimeForm
                                breaktime={breaktime}
                                attendance={employeeCheckIn}
                                userUUID={user.userUUID}
                                reloadData={shouldReloadAttendanceBreaktimes}
                            />
                        ),
                        dialogContentClassName: "min-w-[550px]"
                    },
                    {type: "delete", handleAction: () => handleDeleteBreaktime(breaktime.pk)},
                ]
            }
        })

        setFormattedBreaktimes(formatData);
    }, [attendanceBreaktimesRequest]);

    useEffect(() => {
        const formatData: AttendanceServiceFormatted[] = attendanceServicesRequest.data.map((service, index, array) => {
            let updatedService: AttendanceServiceFormatted = {
                ...service,
                startDatetime: service.startDatetime,
                endDatetime: service.endDatetime,
                startDatetimeFormatted: service.startDatetime ? moment(service.startDatetime).format('HH:mm') : nullOrUndefined,
                endDatetimeFormatted: service.endDatetime ? moment(service.endDatetime).format('HH:mm') : nullOrUndefined,
                checkout: employeeCheckOut !== undefined ? moment(service.endDatetime).format('HH:mm') : null,
                actions: []
            }
            return {
                ...updatedService,
                actions: [
                    {
                        type: "edit",
                        content: (
                            <EditAttendanceServiceForm
                                userUUID={user.userUUID}
                                attendance={employeeCheckIn}
                                service={updatedService}
                                services={servicesRequest.data}
                                reloadData={shouldReloadAttendanceServices}
                            />
                        ),
                        dialogContentClassName: "min-w-[550px]"
                    },
                    {type: "delete", handleAction: () => handleDeleteService(service.pk)},
                ]
            }
        })

        setFormattedServices(formatData);
    }, [attendanceServicesRequest.data]);

    useEffect(() => {
        fetchLocations()
    }, []);

    const handleDeleteBreaktime = (id: string) => {
        if (!user && !employeeCheckIn?.pk) return;
        axiosInstance.delete(attendancesUrl + `${user.userUUID}/attendances/${employeeCheckIn.pk}/break-times/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                shouldReloadAttendanceBreaktimes(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }
    const handleDeleteService = (id: string) => {
        if (!user && !employeeCheckIn?.pk) return;
        axiosInstance.delete(attendancesUrl + `${user.userUUID}/attendances/${employeeCheckIn.pk}/services/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                shouldReloadAttendanceServices(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const handleAttendanceUpdate = (value: {[key: string]: string | null}, field: "checkoutTime" | "checkoutLoc") => {
        let url = attendancesUrl + `${user.userUUID}/attendances/${employeeCheckIn.pk}/check-out/`
        axiosInstance.put(url, {
            ...value,
            checkOutTime: generalInfo.checkoutTime.value ? moment({
                h: generalInfo.checkoutTime.value.hour,
                m: generalInfo.checkoutTime.value.minute
            }).format("HH:mm") : null,
            checkoutLocationUUID: generalInfo.checkoutLoc.value && generalInfo.checkoutLoc.value !== 'undefined' ? generalInfo.checkoutLoc.value : null,
        })
            .then((res) => {
                dispatch({
                    type:GeneralActionType.SETEDIT,
                    payload: {
                        field,
                        openForEdit: false
                    }
                })

                setNonFieldErrors(undefined)
                dispatch({
                    type: GeneralActionType.SETERROR,
                    payload: {
                        field: "checkoutLoc",
                        error: null
                    }
                })
                dispatch({
                    type: GeneralActionType.SETERROR,
                    payload: {
                        field: "checkoutTime",
                        error: null
                    }
                })
            })
            .catch((err) => {
                if (err.response?.data?.hasOwnProperty('non_field_errors')) {
                    setNonFieldErrors(err.response.data.non_field_errors)
                } else {
                    if (err.response?.data.hasOwnProperty("checkOutTime")) {
                        console.log(err.response?.data.checkOutTime[0])
                        dispatch({
                            type: GeneralActionType.SETERROR,
                            payload: {
                                field: "checkoutTime",
                                error: err.response?.data.checkOutTime[0]
                            }
                        })
                    } else if (err.response?.data.hasOwnProperty("checkOutLocationUUID")) {
                        dispatch({
                            type: GeneralActionType.SETERROR,
                            payload: {
                                field: "checkoutLoc",
                                error: err.response?.data.checkoutLocationUUID[0]
                            }
                        })
                    }
                }
            })
    }

    useEffect(() => {
        let locationUUID = locations.find(elem => elem.name === employeeCheckOut?.location)?.uuid

        if (locations.length && locationUUID) {
            dispatch({
                type: GeneralActionType.SETVALUE,
                payload: {
                    field: "checkoutLoc",
                    value: locationUUID
                }
            })
        }
    }, [locations]);

    return (
        <div className={"flex flex-col max-h-[80vh] overflow-y-scroll space-y-4 w-[550px]"}>
            <Tabs defaultValue={generalName} className="relative">
                <TabsList>
                    <TabsTrigger value={generalName}>{generalName}</TabsTrigger>
                    <TabsTrigger value={breaktimesName}>{breaktimesName}</TabsTrigger>
                    <TabsTrigger value={servicesName}>{servicesName}</TabsTrigger>
                </TabsList>

                <TabsContent value={generalName} className="relative">
                    <div className={"flex flex-col gap-4 border rounded-lg p-4"}>
                        <div className="flex flex-row">
                            <span className="w-1/2 text-gray-500">
                                <FormattedMessage id={"attendance.attendances.employeeName"} defaultMessage={"Employee Name"}/>
                            </span>
                            <span>{user?.userName ?? nullOrUndefined}</span>
                        </div>
                        <div className="flex flex-row">
                            <span className="w-1/2 text-gray-500">
                                <FormattedMessage id={"attendance.attendances.checkIn"} defaultMessage={"Check In"}/>
                            </span>
                            <span>{employeeCheckIn?.time ?? nullOrUndefined}</span>
                        </div>
                        <div className="flex flex-row items-center">
                            <span className="w-1/2 text-gray-500">
                                <FormattedMessage id={"attendance.attendances.checkOut"} defaultMessage={"Check Out"}/>
                            </span>
                            <div className="w-1/2">
                                {generalInfo.checkoutTime.openForEdit ? (
                                    <div className="flex flex-row space-x-2 items-end">
                                        <TimePicker
                                            value={generalInfo.checkoutTime.value}
                                            onChange={(value) => {
                                                dispatch({
                                                    type:GeneralActionType.SETVALUE,
                                                    payload: {
                                                        field: "checkoutTime",
                                                        value
                                                    }
                                                })
                                            }}
                                        />
                                        <div className="flex flex-row space-x-2 items-center">
                                            <X
                                                size={16}
                                                weight="thin"
                                                className={"cursor-pointer hover:border-b border-black"}
                                                onClick={() => dispatch({
                                                    type:GeneralActionType.SETEDIT,
                                                    payload: {
                                                        field: "checkoutTime",
                                                        openForEdit: false
                                                    }
                                                })}
                                            />
                                            <Button onClick={() => {
                                                handleAttendanceUpdate({
                                                    ...(generalInfo.checkoutTime.value && {
                                                        checkOutTime: generalInfo.checkoutTime.value ? moment({
                                                            h: generalInfo.checkoutTime.value.hour,
                                                            m: generalInfo.checkoutTime.value.minute
                                                        }).format('HH:mm') : undefined
                                                    })
                                                }, "checkoutTime")
                                            }} variant={"taimDefault"}>
                                                <FormattedMessage id={"attendance.attendances.apply"} defaultMessage={"Apply"}/>
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-row space-x-2 items-center">
                                        <span>{
                                            generalInfo.checkoutTime.value ?
                                            moment({h: generalInfo.checkoutTime.value.hour, m: generalInfo.checkoutTime.value.minute}).format('HH:mm') :
                                            employeeCheckOut?.time ?? nullOrUndefined
                                        }</span>
                                        <PencilSimple
                                            className={"cursor-pointer opacity-20 hover:opacity-100"}
                                            onClick={() => dispatch({
                                                type:GeneralActionType.SETEDIT,
                                                payload: {
                                                    field: "checkoutTime",
                                                    openForEdit: true
                                                }
                                            })}
                                        />
                                    </div>
                                )}

                                {generalInfo.checkoutTime.error && (
                                    <span className="text-xs text-red-500">{generalInfo.checkoutTime.error}</span>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <span className="w-1/2 text-gray-500">
                                <FormattedMessage id={"attendance.attendances.checkInLoc"} defaultMessage={"Check In Location"}/>
                            </span>
                            <span>{employeeCheckIn?.location ?? nullOrUndefined}</span>
                        </div>

                        <div className="flex flex-row items-center">
                            <span className="w-1/2 text-gray-500">
                                <FormattedMessage id={"attendance.attendances.checkOutLoc"} defaultMessage={"Check Out Location"}/>
                            </span>
                            <div className="w-1/2">
                                {generalInfo.checkoutLoc.openForEdit ? (
                                    <div className="flex flex-row space-x-2 items-center">
                                        <Select
                                            onValueChange={(value) => {
                                                dispatch({
                                                    type: GeneralActionType.SETVALUE,
                                                    payload: {
                                                        field: "checkoutLoc",
                                                        value: value
                                                    }
                                                })
                                            }}
                                            defaultValue={generalInfo.checkoutLoc.value ?? "undefined"}
                                            value={generalInfo.checkoutLoc.value ?? "undefined"}
                                        >
                                            <SelectTrigger className="w-fit">
                                                <SelectValue placeholder={"pick a location"}/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value={"undefined"}>{nullOrUndefined}</SelectItem>
                                                {locations.map((location,index) => (
                                                    <SelectItem key={index} value={location.uuid}>{location.name}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        <X
                                            size={16}
                                            weight="thin"
                                            className={"cursor-pointer hover:border-b border-black"}
                                            onClick={() => dispatch({
                                                type: GeneralActionType.SETEDIT,
                                                payload: {
                                                    field: "checkoutLoc",
                                                    openForEdit: false
                                                }
                                            })}
                                        />
                                        <Button onClick={() => {
                                            handleAttendanceUpdate({
                                                checkoutLocationUUID: generalInfo.checkoutLoc.value !== "undefined" ? generalInfo.checkoutLoc.value : null
                                            }, "checkoutLoc")
                                        }} variant={"taimDefault"}>Apply</Button>
                                    </div>
                                ) : (
                                    <div className="flex flex-row space-x-2 items-center">
                                        <span>{generalInfo.checkoutLoc.value ?
                                            generalInfo.checkoutLoc.value !== "undefined" ?
                                                locations.find(elem => elem.uuid === generalInfo.checkoutLoc.value)?.name :
                                                nullOrUndefined
                                            : employeeCheckOut?.location ?? nullOrUndefined}</span>
                                        <PencilSimple
                                            className={"cursor-pointer opacity-20 hover:opacity-100"}
                                            onClick={() => dispatch({
                                                type: GeneralActionType.SETEDIT,
                                                payload: {
                                                    field: "checkoutLoc",
                                                    openForEdit: true
                                                }
                                            })}
                                        />
                                    </div>
                                )}

                                {generalInfo.checkoutLoc.error && <span>{generalInfo.checkoutLoc.error}</span>}
                            </div>
                        </div>

                        {nonFieldErrors && <span className="text-xs text-red-500">
                            {nonFieldErrors[0].slice(0,1).toUpperCase() + nonFieldErrors[0].slice(1,)}
                        </span>}

                    </div>
                </TabsContent>

                <TabsContent value={breaktimesName} className="space-y-4">
                    {permissionContext.isAdmin ? (
                        <div className="w-full flex flex-row items-end justify-end">
                            <TaimModal
                                header={intl.formatMessage({id: "attendance.attendances.addBreaktime", defaultMessage: "Add Breaktime"})}
                                button={(
                                    <Button variant={"taimDefault2"}>
                                        <FormattedMessage
                                            id={"attendance.attendances.addBreaktime"}
                                            defaultMessage={"Add Breaktime"}
                                        />
                                    </Button>
                                )}
                                dialogContentClassName={"min-w-[500px]"}
                            >
                                <CreateAttendanceBreaktimeForm
                                    attendance={employeeCheckIn}
                                    userUUID={user.userUUID}
                                    reloadData={shouldReloadAttendanceBreaktimes}
                                />
                            </TaimModal>
                        </div>
                    ) : null}
                    <div className="min-w-full">
                        <DataTable
                            columns={attendanceBreaktimesColumns}
                            data={formattedBreaktimes}
                            loading={attendanceBreaktimesLoading}
                            payload={{
                                length: 1
                            }}
                        />
                    </div>
                </TabsContent>
                <TabsContent value={servicesName} className="space-y-4">
                    <div className="w-full flex flex-row items-end justify-end">
                        <TaimModal
                            header={intl.formatMessage({id: "attendance.addService", defaultMessage: "Add Service"})}
                            button={(
                                <Button variant={"taimDefault2"}>
                                    <FormattedMessage
                                        id={"attendance.addService"}
                                        defaultMessage={"Add Service"}
                                    />
                                </Button>
                            )}
                            dialogContentClassName={"min-w-[500px]"}
                        >
                            <CreateAttendanceServicesForm
                                userUUID={user.userUUID}
                                attendance={employeeCheckIn}
                                reloadData={shouldReloadAttendanceServices}
                            />
                        </TaimModal>
                    </div>
                    <div className="w-full">
                        <DataTable
                            columns={attendanceServicesColumns}
                            data={formattedServices}
                            loading={attendanceServicesLoading}
                            payload={{
                                length: 1
                            }}
                        />
                    </div>
                </TabsContent>
            </Tabs>

            <DialogFooter className="flex flex-row justify-between">
                <DialogClose asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                    </Button>
                </DialogClose>
            </DialogFooter>
        </div>
    )
}