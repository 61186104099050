import {Button} from "../components/button";
import {useEffect, useState} from "react";
import {cn} from "../lib/utils";

type LoadingSpinner = {
    size?: number
    color?: string
    depth?: 'thin' | 'medium' | 'thick'
    className?: string
}

const LoadingSpinner: React.FC<LoadingSpinner> = ({className, color, size, depth}) => {
    return <div
        className={cn(
            "inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white",
            className,
            depth && ((() => {
                switch (depth) {
                    case "thin":
                        return 'border-2'
                    case "medium":
                        return 'border-4'
                    case "thick":
                        return 'border-6'
                    default:
                        return 'border-2';
                }
            })())
        )}
        style={{
            ...(color && {color: color}),
            ...(size && {
                width: (size + 'px').toString(),
                height: (size + 'px').toString()
            })
        }}
        role="status"
    ></div>
}

const LoadingBtn = () => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            setLoading(false)
        }, 5000)
    }, [loading]);
    return <Button onClick={() => setLoading(true)}>{loading ? <LoadingSpinner/> : 'click'}</Button>
}

export {LoadingBtn}