import DataTable, {Column} from "../../Core/Table/data-table";
import React, {SetStateAction} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {Action} from "../../Core/Table/data-table-actions";
import {
    TimesheetDailyTrack,
    TimesheetDailyTracksPayload,
    TimesheetDailyTracksRequest, timesheetManagementUrl,
} from "../Controllers/timesheet-controller";
import {DaysTimeline} from "../../Core/components/days-timeline";
import moment from "moment";
import {useIntl} from "react-intl";
import {cn} from "../../lib/utils";

type Actions = {
    delete?: any
}

type TimesheetDailyTracksDataTableProps = {
    timesheetDailyTracksRequest: TimesheetDailyTracksRequest
    payload: TimesheetDailyTracksPayload
    setPayload: React.Dispatch<SetStateAction<TimesheetDailyTracksPayload>>
    loading: boolean
    actions?: Actions
    year?: number
    month?: number
    day: number | null
    setDay: React.Dispatch<SetStateAction<number | null>>
    isLoading?: boolean
}


const TimesheetDailyTracksDataTable = (
    {
        timesheetDailyTracksRequest,
        payload,
        setPayload,
        loading,
        actions,
        month,
        year,
        setDay,
        day,
        isLoading
    }: TimesheetDailyTracksDataTableProps) => {
    const intl = useIntl();
    const formatData = timesheetDailyTracksRequest?.data.map((dailyTrack: TimesheetDailyTrack) => {
         const isOvertime = dailyTrack.overtime.slice(0,1) !== "-";
        return {
            ...dailyTrack,
            sickNoteTotalDays: String(dailyTrack.sickNoteTotalDays),
            vacationTotalDays: String(dailyTrack.vacationTotalDays),
            overtime: <span className={cn(
                isOvertime ? "text-green-500" : "text-red-500"
            )}>{dailyTrack.overtime}</span>,
            actions: [
                {type: "delete", handleAction: () => (actions?.delete) ? actions?.delete(null) : null}
            ] as Action[]
        };
    });
    const timesheetDailyTracksColumns: Column[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.employeeName", defaultMessage: "Employee Name"}),
            sortable: true,
            isSorted: "asc",
        },
        {
            backendKey: "attendanceTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.employee.attendanceTotalHours", defaultMessage: "Attendance Hours"}),
            sortable: true
        },
        {
            backendKey: "vacationTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.vacationTotalHours", defaultMessage: "Vacation Hours"}),
            sortable: true,
        },
        {
            backendKey: "sickNoteTotalHours",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.sickNoteTotalHours", defaultMessage: "Sick Hours"}),
            sortable: true,
        },
        {
            backendKey: "spentTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.actualTime", defaultMessage: "Actual Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "plannedTime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.plannedTime", defaultMessage: "Planned Time"}),
            sortable: true,
            isSorted: "desc",
        },
        {
            backendKey: "overtime",
            frontendKey: intl.formatMessage({id: "timesheetManagement.table.dailyTrack.overtime", defaultMessage: "Overtime"}),
            sortable: true
        }
    ]

    if (!day) return <></>

    return(
        <div className="space-y-4 min-h-[40rem] flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    exportTable={{
                        fileName: `${year}_${month}_${day}_${moment().valueOf()}_daily-tracks`,
                        apiURL: timesheetManagementUrl + `${year}/${month}/${day}/daily-tracks/export/`,
                        payload: payload
                    }}
                />
                <DaysTimeline
                    day={day}
                    setDay={setDay}
                    year={year || new Date().getFullYear()}
                    month={month || new Date().getMonth() + 1}
                />
                <DataTable
                    data={formatData}
                    loading={isLoading || loading}
                    detailData={timesheetDailyTracksRequest.data}
                    setPayload={setPayload}
                    columns={timesheetDailyTracksColumns}
                    payload={payload}
                    showLoadingState={true}
                />
            </div>
            <DataTablePagination request={timesheetDailyTracksRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default TimesheetDailyTracksDataTable;