import React, {ReactNode, useEffect, useState} from "react";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "components/tab";
import {Card, CardContent, CardFooter, CardHeader, CardTitle} from "components/card";
import {TaimModal, ModalProps} from "Core/components/taim-modal";
import {cn} from "lib/utils";

export type PageEntrySchema = {
    name: string
    pagesState: PageState[]
}

type MainView = {
    title?: string
    content: ReactNode
}

type DetailView = {
    title: string
    content: ReactNode
    actions?: ReactNode;
}

export type TabProps = MainAndDetailTab | MainOnlyTab

type Modal = {
    type: "modal"
} & ModalProps

export type PageActions = Modal | {
    type: "jsxElement"
    content: ReactNode
}

type TabContent = {
    name: string;
    props?: any;
    actions?: PageActions[];
    main: MainView
};

type MainAndDetailTab = {
    type: "mainAndDetail"
    detail: DetailView
} & TabContent

type MainOnlyTab = {
    type: "mainOnly"
} & TabContent

export type PageState = {
    pageName: string
    reFetch: boolean
}

export type PageShapeProps = {
    header: string
    pageEntries: React.FC<{
        pagesState: PageState[]
        children?: React.FC<PageEntrySchema>
    }>[]
    sections: {name: string}[]
    defaultSection: string
}

export const PageShape: React.FC<PageShapeProps> = ({...pageContent}) => {
    const [tab, setTab] = useState(pageContent.defaultSection);
    const [pagesState, setPagesState] = useState<
        PageState[]
    >(pageContent.sections.map((page) => {
        return {
            pageName: page.name,
            reFetch: false
        }
    }));

    const onTabChange = async (value: string) => {
        setPagesState(prevState => prevState.map((page) => {
            if (page.pageName !== value) return page;
            return {
                pageName: value,
                reFetch: true
            }
        }));
        await new Promise(resolve => setTimeout(resolve, 0));
        setPagesState(prevState => prevState.map((page) => {
            if (page.pageName !== value) return page;
            return {
                pageName: value,
                reFetch: false
            }
        }));
        setTab(value);
    }

    useEffect(() => {
        setTab(pageContent.defaultSection);
    }, [pageContent.sections]);

    return (
        <div className="flex flex-col gap-6">
            {pageContent.header && <span className="text-[#1b2436] text-[2rem] font-semibold leading-9">
                {pageContent.header}
            </span>}
            <Tabs value={tab} onValueChange={onTabChange} className="relative w-full">
                <TabsList>
                    {pageContent?.sections.length > 0 && pageContent.sections.map((section, index) =>
                        <TabsTrigger className="capitalize" value={section.name} key={index}>{section.name}</TabsTrigger>
                    )}
                </TabsList>
                {pageContent?.pageEntries.length > 0 && pageContent.pageEntries.map((TabLayer, index) => (
                    <TabLayer pagesState={pagesState} key={index}/>
                ))}
            </Tabs>
        </div>
    )
}



export const MainTab: React.FC<TabProps> = ({...props}) => {

    return (
        <TabsContent value={props.name} className="relative">
            <div className={cn(
                `static w-fit grid grid-rows-[${props.actions?.length}] mb-2 xl:absolute xl:top-[-3rem] xl:right-0 xl:mb-0 gap-2`
            )}>
                {Array.isArray(props.actions) && props.actions.map((action, index) => {
                    if (action.type === "modal") return (
                        <div className="row-end-1">
                            <TaimModal
                                key={index}
                                {...action}
                            >
                                {action.children}
                            </TaimModal>
                        </div>
                    )
                    return <div className="row-end-1">{action.content}</div>
                })}
            </div>
            {(props.type === 'mainOnly') ? (
                <div className="grid min-h-[50rem]">
                    <Card className="flex flex-col">
                        {props.main.title ? (
                            <CardHeader>
                                <CardTitle className="capitalize text-lg xl:text-xl 2xl:text-2xl">
                                    {props.main.title}
                                </CardTitle>
                            </CardHeader>
                        ) : null}
                        <CardContent className="flex-1">
                            {props.main.content}
                        </CardContent>
                    </Card>
                </div>
            ) : (
                <div className="grid gap-4 xl:grid-cols-10 min-h-[50rem]">
                    <Card className="col-span-5 xl:col-span-6 flex flex-col">
                        {props.main.title ? (
                            <CardHeader>
                                <CardTitle
                                    className="capitalize text-lg xl:text-xl 2xl:text-2xl">{props.main.title}</CardTitle>
                            </CardHeader>
                        ) : null}
                        <CardContent className="flex-1">
                            {props.main.content}
                        </CardContent>
                    </Card>
                    <Card className="col-span-5 xl:col-span-4 h-fit min-h-[50rem]">
                        <div className="flex flex-col justify-between h-fit">
                            <div>
                                <CardHeader>
                                   <CardTitle className="capitalize text-lg xl:text-xl 2xl:text-2xl">{props.detail.title}</CardTitle>
                                </CardHeader>
                                <CardContent className="flex flex-col gap-5 px-6">
                                    {props.detail.content}
                                </CardContent>
                            </div>
                            <CardFooter className="flex flex-row w-full gap-2">
                                {props.detail?.actions}
                            </CardFooter>
                        </div>
                    </Card>
                </div>
            )}
        </TabsContent>
    )
}