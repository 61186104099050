import React, {useContext, useEffect} from "react";
import {EmployeesPayload} from "EmployeeManagement/Controllers/employee-controller";
import {Button} from "components/button";
import {FormImport} from "../Forms/form-import";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageActions, PageState} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {EmployeesTable} from "../datatables/employees_data_table";
import {useImportEmployees} from "../controller/employees_controller";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";

type EmployeesEntryProps = {
    name: string
    pagesState?: PageState[]
}

const EmployeesEntry: React.FC<EmployeesEntryProps> = ({name, pagesState}) => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext);
    const {
        employees,
        storeData,
        clearData,
        update
    } = useImportEmployees();

    useEffect(() => {
        let currPage = pagesState?.find(page => page.pageName === name);
        if (currPage && currPage.reFetch) {
            // reFetch(true);
        }

    }, [pagesState, permissionContext.isAdmin]);

    return (
        <MainTab
            type="mainOnly"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <EmployeesTable
                    employees={employees}
                    storeData={storeData}
                    update={update}
                    clearData={clearData}
                />
            }}
            actions={[
                {
                    type: 'modal',
                    button: (<Button variant='taimDefault'>
                        <FormattedMessage id="import_management.import" defaultMessage="Import"/>
                    </Button>),
                    header: intl.formatMessage({
                        id: "import_management.import",
                        defaultMessage: "Import"
                    }),
                    children: (<FormImport storeData={storeData}/>),
                    dialogContentClassName: "min-w-[550px]"
                }
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />
    )
}

export default EmployeesEntry;