import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "components/button";
import {TaimModal} from "Core/components/taim-modal";
import {CreateProjectForm} from "ProjectManagement/Forms/project-forms";
import {Employee} from "ProjectManagement/Controller/employees-controller";
import {Service} from "ProjectManagement/Controller/services-controller";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {projectManagementUrl} from "ProjectManagement";
import {Plus, Users} from "@phosphor-icons/react";
import moment from "moment";
import {Skeleton} from "components/skeleton";
import {Link} from "react-router-dom";
import {nullOrUndefined} from "Core/constants/variables";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "../../../components/dialog";
import {Project, useDashboardProjects} from "../../Controllers/Admin/project-controller";

type TProjectComponent = {
    project: Project
}

const ProjectComponent: React.FC<TProjectComponent> = ({project}) => {
    const status = (() => {
        switch (project.status) {
            case "Progress":
                return (
                    <span className="relative flex h-3 w-3">
                        <span
                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span
                            className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                );
            case "Done":
                return (
                    <span className="relative flex h-3 w-3">
                        <span
                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75"></span>
                        <span
                            className="relative inline-flex rounded-full h-3 w-3 bg-lime-400"></span>
                    </span>
                );
            case "Pending":
                return (
                    <span className="relative flex h-3 w-3">
                        <span
                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
                        <span
                            className="relative inline-flex rounded-full h-3 w-3 bg-amber-300"></span>
                    </span>
                );
            default:
                return (
                    <span className="relative flex h-3 w-3">
                        <span
                            className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
                        <span
                            className="relative inline-flex rounded-full h-3 w-3 bg-gray-400"></span>
                    </span>
                );
        }
    })();

    return (
        <Link
            to={"/project-management"}
            state={{ projectName: project.name }}
            className={"p-4 min-w-[16rem] min-h-[8rem] h-44 w-full border border-slate-200 hover:border-slate-300 bg-slate-50 hover:bg-slate-100 rounded-lg flex flex-col justify-between scale-[0.99] hover:scale-[1] cursor-pointer"}
        >
            <div className={"flex gap-3 items-center"}>
                {status}
                <div>{project.name ?? nullOrUndefined}</div>
            </div>
            <div className={"flex justify-between font-bold"}>
                <div className="flex items-end gap-1">
                    <div>{project.employeesCount}</div>
                    <Users size={24} />
                </div>
                <div className={"flex flex-col items-start"}>
                    <span className={"text-xs underline text-gray-400 font-normal"}>
                        <FormattedMessage id={"dashboard.project.endDate"} defaultMessage={"End Date"}/>
                    </span>
                    <span>{project.endDate ? moment(project.endDate).format("ddd DD MMM YYYY") : "No deadline"}</span>
                </div>
            </div>
        </Link>
    )
}

type ProjectSectionProps = {
    reloadDashboard: boolean
    setReloadDashboard: Dispatch<SetStateAction<boolean>>
}

const ProjectSection: React.FC<ProjectSectionProps> = ({reloadDashboard,setReloadDashboard}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const {
        projects,
        loadingProjects,
        setReloadProjects
    } = useDashboardProjects(reloadDashboard,setReloadDashboard);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [services, setServices] = useState<Service[]>([]);

    const fetchEmployees = () => {
        axiosInstance.get(projectManagementUrl + 'employees/')
            .then((res) => setEmployees(res.data))
            .catch((err) => console.log(err))
    }
    const fetchServices = () => {
        axiosInstance.get(projectManagementUrl + 'services/')
            .then((res) => setServices(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchEmployees();
        fetchServices();
    }, []);


    return(
        <div className={"col-span-1 flex flex-col gap-2 [&>*]:mt-2 lg:[&>*]:mx-2 lg:[&>*]:mt-0"}>
            <div className="flex justify-between items-center">
                <span className={"font-semibold text-xl"}>
                    <FormattedMessage
                        id={"dashboard.project"}
                        defaultMessage={"Project"}
                    />
                </span>
                <Link to={"/project-management"}>
                    <Button variant="link" className="text-blue-800 h-fit p-0">
                        <FormattedMessage
                            id={"dashboard.viewAll"}
                            defaultMessage={"View All"}
                        />
                    </Button>
                </Link>
            </div>
            <div className="min-h-96 max-h-[26.5rem] 2xl:h-44 overflow-y-scroll w-full flex flex-col 2xl:flex-row items-center gap-4 text-center text-gray-500 2xl:overflow-x-scroll scroll-smooth no-scrollbar">
                {loadingProjects ? (
                    Array.from({length: 6}).map(() => (
                        <div className={"p-4 flex flex-col justify-between min-w-[16rem] min-h-[8rem] h-44 w-full border border-slate-200 hover:border-slate-300 bg-slate-50 hover:bg-slate-100 rounded-lg"}>
                            <div className={"flex gap-3 items-center"}>
                                <Skeleton className="h-4 w-4 bg-slate-200"/>
                                <Skeleton className="h-4 w-1/4 bg-slate-200"/>
                            </div>
                            <div className={"flex justify-between font-bold"}>
                                <div className="flex items-center gap-1">
                                    <Skeleton className="h-4 w-8 bg-slate-200"/>
                                    <Skeleton className="h-4 w-4 bg-slate-200"/>
                                </div>
                                <Skeleton className="h-4 w-1/3 bg-slate-200"/>
                            </div>
                        </div>
                    ))
                ) : projects.length && !loadingProjects ? (
                    <>
                        {projects.map((project) => <ProjectComponent project={project}/>)}
                        <Dialog>
                            <DialogTrigger asChild>
                                <div className={"group flex items-center justify-center min-w-[16rem] min-h-[8rem] h-44 w-full border-4 border-dashed border-slate-200 hover:border-slate-300 bg-slate-50 hover:bg-slate-100 rounded-lg scale-[0.99] hover:scale-[1] cursor-pointer"}>
                                    <Plus size={36} className={"text-gray-200 group-hover:text-gray-300"}/>
                                </div>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[500px] min-w-[500px]">
                                <DialogHeader>
                                    <DialogTitle>
                                        <FormattedMessage id={"project.projects.createProject"} defaultMessage={"Create Project"}/>
                                    </DialogTitle>
                                </DialogHeader>
                                <CreateProjectForm
                                    reloadData={setReloadProjects}
                                    employees={employees}
                                    services={services}
                                />
                            </DialogContent>
                        </Dialog>
                    </>
                ) : (
                    <div className="flex items-center justify-center border w-full h-full p-4 rounded-lg bg-slate-50">
                        <div className="flex flex-col items-center justify-center text-center text-gray-500 text-sm">
                            <span>
                                <FormattedMessage
                                    id={"dashboard.project.noProject1"}
                                    defaultMessage={"You do not have any project, Click button"}
                                />
                            </span>
                            <span>
                                <FormattedMessage
                                    id={"dashboard.project.noProject2"}
                                    defaultMessage={"below to add new project"}
                                />
                            </span>
                            <TaimModal
                                header={intl.formatMessage({id: "project.projects.createProject", defaultMessage: "Create Project"})}
                                button={
                                    <Button variant="link" className="text-blue-800 h-fit pt-1">
                                        <FormattedMessage
                                            id={"dashboard.project.noProject3"}
                                            defaultMessage={"+ Add Project"}
                                        />
                                    </Button>
                                }
                            >
                                <CreateProjectForm
                                    reloadData={setReloadProjects}
                                    employees={employees}
                                    services={services}
                                />
                            </TaimModal>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProjectSection;