import DataTable, {Column} from "../../Core/Table/data-table";
import React, {SetStateAction, useEffect, useState} from "react";
import DataTableTools from "../../Core/Table/data-table-tools";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {Action} from "../../Core/Table/data-table-actions";
import {role, rolesRequest, rolesUrl} from "../Controllers/role-controller";
import {FormRoleEdit} from "../Forms/form-role-edit";
import RolesDetailView from "../DetailViews/roles-detail-view";
import {useIntl} from "react-intl";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";

type FormattedRole = {
    actions: Action[]
} & role

type RolesDataTableProps = {
    rolesRequest: rolesRequest
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch: React.Dispatch<React.SetStateAction<boolean>>
}


const RolesDataTable = ({rolesRequest, payload, setPayload, loading, reFetch}: RolesDataTableProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<FormattedRole[]>([])
    const roleColumns: Column[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({
                id: "role.table.role",
                defaultMessage: "Role"
            }),
            sortable: true
        },
        {
            backendKey: "totalEmployees",
            frontendKey: intl.formatMessage({
                id: "role.table.total",
                defaultMessage: "Total"
            }),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions"
        },
    ]

    const deleteRole = (role: role) => {
        axiosInstance.delete(rolesUrl + `${role.pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    const rePopulateRecord = (pk: string) => {
        axiosInstance.get(rolesUrl + `${pk}/`)
            .then((res) => {
                setFormattedData(prevState => prevState.map(role => {
                    if (role.pk === pk) {
                        return {
                            ...role,
                            ...res.data
                        }
                    }
                    return role;
                }))
            })
    }


    useEffect(() => {
        let formatData: FormattedRole[] = rolesRequest?.data.map((role: role) => {
            return {
                ...role,
                actions: [
                    {
                        type: "edit",
                        content: <FormRoleEdit role={role} rePopulateRecord={rePopulateRecord}/>
                    },
                    {type: "view", content: <RolesDetailView role={role}/>},
                    {type: "delete", handleAction: () => deleteRole(role)},
                ] as Action[]
            };
        })

        setFormattedData(formatData)
    }, [rolesRequest]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTable
                    data={formattedData}
                    loading={loading}
                    detailData={rolesRequest.data}
                    setPayload={setPayload}
                    columns={roleColumns}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={rolesRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default RolesDataTable;