import {zodResolver} from "@hookform/resolvers/zod"
import {useForm} from "react-hook-form"
import * as z from "zod"
import {Button} from "components/button"
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage,} from "components/form";
import {DialogClose, DialogFooter} from "components/dialog";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Employee} from "EmployeeManagement/Controllers/employee-controller";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "components/select";
import {location} from "AttendanceManagement/Controllers/locations-controller";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {attendanceManagementUrl} from "AttendanceManagement";
import {toast} from "components/use-toast";
import {months, years} from "Core/constants/date";
import {AttendancesPayload, attendancesUrl} from "AttendanceManagement/Controllers/attendances-controller";
import moment from "moment";
import SearchInput from "Core/components/search-input";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "Core/functions/use-server-errors";
import ErrorMessageList from "Core/components/ErrorMessageList";
import {Input} from "components/input";
import {Trash} from "@phosphor-icons/react";
import {TimePicker} from "components/date-time-picker/time-picker";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "components/table";
import {DatePicker} from "components/date-picker";
import {AttendanceFormatted} from "AttendanceManagement/Controllers/attendances-controller";
import {useSteps} from "../../Core/functions/use-steps";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import DetailViewCard from "../../Core/components/detail-view-card";
import {nullOrUndefined} from "../../Core/constants/variables";



const STEPS_WITH_CHECKIN_CHECKOUT = ["step_1", "step_2", "preview"]


type Breaktime = {
    timestamp: Date | null
    start: string
    end: string
}

const formSchema = z.object({
    userUUID: z.string().optional(),
    checkInDate: z.date().optional(),
    checkInTime: z.any().optional(),
    checkOutTime: z.any().optional(),
    breakTimes: z.any(),
    checkinLocationUUID: z.string().optional(),
    checkoutLocationUUID: z.string().optional(),
})

type CreateAttendanceFormProps = {
    employees?: Employee[]
    locations?: location[]
    reFetch: Dispatch<SetStateAction<boolean>>
    payload: AttendancesPayload
    setDetailData: Dispatch<SetStateAction<AttendanceFormatted | null>>
}

export const CreateAttendanceForm: React.FC<CreateAttendanceFormProps> = ({
    employees,
    locations,
    reFetch
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema)
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLButtonElement>(null);
    const [attendanceDuration, setAttendanceDuration] = useState<string>("00h 00min");
    const [breaktimes, setBreaktimes] = useState<Breaktime[]>([]);
    const [breaktime, setBreaktime] = useState<Breaktime>(
        {timestamp: null, start: "", end: ""}
    );
    const {
        steps,
        goToNextStep,
        goToPreviousStep,
        currentStep,
        setCurrentStep
    } = useSteps(STEPS_WITH_CHECKIN_CHECKOUT, "step_1")

    const addBreaktime = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (!breaktime || !breaktime.start.length || !breaktime.end.length) {
            return;
        }

        if (breaktime.start.length && breaktime.end.length) {
            let newBreaktime: Breaktime = {
                ...breaktime,
                timestamp: new Date()
            }

            setBreaktimes((prevState) => {
                return prevState.concat(newBreaktime);
            });
        }
    };

    const removeBreaktime = (value: Breaktime) => {
        setBreaktimes(prevState => prevState.filter(currBreak => currBreak !== value));
    }

    const validateFirstStep = () => {
        setApiErrors({})

        let values = form.getValues(), url = attendanceManagementUrl + `employees/${values.userUUID}/`;
        const data = {
            userUUID: values.userUUID,
            ...(values.checkInDate && {checkInDate: moment(values.checkInDate).local().format("YYYY-MM-DD")}),
            checkInTime: (values.checkInTime) ? ((): string => {
                const startHour = String(values.checkInTime?.hour);
                const startMinute = String(values.checkInTime?.minute);
                return `${startHour.length > 1 ? startHour : "0" + startHour}:${startMinute.length > 1 ? startMinute : "0" + startMinute}`;
            })() : null,
            checkOutTime: (values.checkOutTime) ? ((): string => {
                const endHour = String(values.checkOutTime?.hour);
                const endMinute = String(values.checkOutTime?.minute);
                return `${endHour.length > 1 ? endHour : "0" + endHour}:${endMinute.length > 1 ? endMinute : "0" + endMinute}`;
            })() : null,
        }

        let hasCheckOut = data.checkOutTime
        if (hasCheckOut) {
            url += 'validate-check-in-and-check-out/'
        } else {
            url += 'validate-check-in-or-check-out/'
        }

        axiosInstance.post(url, data)
            .then((res) => {
                goToNextStep()
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    }

    const validateSecondStep = () => {
        setApiErrors({})

        let values = form.getValues(), url = attendanceManagementUrl + `employees/${values.userUUID}/`;
        const data = {
            ...values,
            ...(values.checkInDate && {checkInDate: moment(values.checkInDate).local().format("YYYY-MM-DD")}),
            checkInTime: (values.checkInTime) ? ((): string => {
                const startHour = String(values.checkInTime?.hour);
                const startMinute = String(values.checkInTime?.minute);
                return `${startHour.length > 1 ? startHour : "0" + startHour}:${startMinute.length > 1 ? startMinute : "0" + startMinute}`;
            })() : null,
            checkOutTime: (values.checkOutTime) ? ((): string => {
                const endHour = String(values.checkOutTime?.hour);
                const endMinute = String(values.checkOutTime?.minute);
                return `${endHour.length > 1 ? endHour : "0" + endHour}:${endMinute.length > 1 ? endMinute : "0" + endMinute}`;
            })() : null,
        }

        let hasCheckOut = !attendanceDuration.includes("00h 00m")
        if (hasCheckOut) {
            url += 'validate-check-in-and-check-out/'
        } else {
            url += 'validate-check-in-or-check-out/'
        }

        axiosInstance.post(url, data)
            .then((res) => {
                goToNextStep()
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    }

    async function onSubmit(values: z.infer<typeof formSchema>) {
        let url = attendanceManagementUrl + `employees/${values.userUUID}/`;
        const checkIn = values.checkInTime;
        const checkOut = values.checkOutTime;
        const breaktimeValues = breaktimes.map((currBreak) => {
            return {
                startTime: currBreak.start,
                endTime: currBreak.end
            }
        });
        const data = {
            ...values,
            ...(values.checkInDate && {checkInDate: moment(values.checkInDate).local().format("YYYY-MM-DD")}),
            checkInTime: (checkIn) ? ((): string => {
                const startHour = String(checkIn?.hour);
                const startMinute = String(checkIn?.minute);
                return `${startHour.length > 1 ? startHour : "0" + startHour}:${startMinute.length > 1 ? startMinute : "0" + startMinute}`;
            })() : null,
            checkOutTime: (checkOut) ? ((): string => {
                const endHour = String(checkOut?.hour);
                const endMinute = String(checkOut?.minute);
                return `${endHour.length > 1 ? endHour : "0" + endHour}:${endMinute.length > 1 ? endMinute : "0" + endMinute}`;
            })() : null,
            breakTimes: breaktimeValues ?? []
        }

        let hasCheckOut = data.checkOutTime
        if (hasCheckOut) {
            url += 'check-in-and-check-out/'
        } else {
            url += 'check-in-or-check-out/'
        }

        axiosInstance.post(url, data)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response.data);
            })
    }


    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "checkInTime" || name === "checkOutTime") {
                let startTime = form.getValues("checkInTime") ? moment(form.getValues("checkInTime")) : null;
                let endTime = form.getValues("checkOutTime") ? moment(form.getValues("checkOutTime")) : null;
                let hours = "00h";
                let minutes = "00m";
                let formattedDuration = `${hours} ${minutes}`;

                if (startTime && endTime) {
                    let isNightShift = startTime.format("A") === "PM" && endTime.format("A") === "AM";
                    if (isNightShift) {
                        endTime.add(1, "d");
                    }

                    let duration = endTime ? moment.duration(endTime.diff(startTime)) : null;
                    if (duration) {
                        hours = Math.floor(duration.asHours()).toString();
                        minutes = duration.minutes().toString();

                        formattedDuration = (() => {
                            let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                            let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                            return `${formattedHours} ${formattedMinutes}`
                        })();
                    }
                }

                setAttendanceDuration(formattedDuration);
            }
        })

        return () => subscription.unsubscribe()
    }, [])

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Tabs value={currentStep} onValueChange={setCurrentStep}>
                    <TabsList>
                        {steps.map(step => (
                            <TabsTrigger value={step.name} disabled={!step.isOpen}>{step.title}</TabsTrigger>
                        ))}
                    </TabsList>
                    <TabsContent value={"step_1"} className="flex flex-col gap-4">
                        <FormField
                            control={form.control}
                            name="userUUID"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel area-label={field.name}>
                                        <FormattedMessage
                                            id={"attendance.attendances.createAttendance.employee"}
                                            defaultMessage={"Employee"}
                                        />
                                    </FormLabel>
                                    <SearchInput
                                        values={employees?.map((e) => {
                                            return {key: e.fullName, value: e.userUUID}
                                        }) || []}
                                        value={field.value}
                                        title={intl.formatMessage({
                                            id: "attendance.attendances.createAttendance.employeePlaceHolder",
                                            defaultMessage: "Select an employee"
                                        })}
                                        onChange={field.onChange}
                                    />
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="checkInDate"
                            render={({field}) => (
                                <FormItem className="flex flex-col">
                                    <FormLabel area-label={field.name}>
                                        <FormattedMessage
                                            id={"attendance.attendances.createAttendance.checkInDate"}
                                            defaultMessage={"Check In Date"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <DatePicker
                                            area-label={field.name}
                                            date={field.value}
                                            setDate={field.onChange}
                                            disabled={new Date()}
                                        />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <div className="flex flex-row gap-2">
                            <div className="grow">
                                <FormField
                                    control={form.control}
                                    name="checkInTime"
                                    render={({field}) => (
                                        <FormItem className="flex flex-col">
                                            <FormLabel area-label={field.name}>
                                                <FormattedMessage
                                                    id={"attendance.attendances.createAttendance.checkIn"}
                                                    defaultMessage={"Check In"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <TimePicker
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="grow">
                                <FormField
                                    control={form.control}
                                    name="checkOutTime"
                                    render={({field}) => (
                                        <FormItem className="flex flex-col">
                                            <FormLabel area-label={field.name}>
                                                <FormattedMessage
                                                    id={"attendance.attendances.createAttendance.checkOut"}
                                                    defaultMessage={"Check Out"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <TimePicker
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>

                        <ErrorMessageList errors={errors?.detailErrors} />
                        <ErrorMessageList errors={errors?.nonFieldErrors} />

                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose asChild>
                                <Button variant="outline">
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </Button>
                            </DialogClose>
                            <Button
                                variant="taimDefault"
                                type="button"
                                onClick={validateFirstStep}
                            ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                        </DialogFooter>
                    </TabsContent>

                    <TabsContent value={"step_2"} className="flex flex-col gap-4">
                        {form.getValues("checkOutTime") ? (
                            <>
                                <div>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.createAttendance.duration"}
                                            defaultMessage={"Duration"}
                                        />
                                    </FormLabel>
                                    <Input value={attendanceDuration} disabled/>
                                </div>

                                <div className={"flex flex-col gap-4"}>
                                    <FormLabel>
                                        <FormattedMessage
                                            id={"attendance.attendances.breaktimes"}
                                            defaultMessage={"Breaktimes"}
                                        />
                                        {" "}
                                        <span className="text-gray-500 text-xs">
                                            (<FormattedMessage id={"optional"} defaultMessage={"optional"}/>)
                                        </span>
                                    </FormLabel>
                                    <div className="flex flex-row gap-2 items-end">
                                        <div className="flex flex-col w-1/2">
                                            <div className="text-gray-500">Start</div>
                                            <TimePicker
                                                onChange={(value) => setBreaktime((prevState) => {
                                                    if (!value) return prevState;
                                                    let {hour, minute} = value
                                                    let start = [hour, minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':')
                                                    return {
                                                        ...prevState,
                                                        start: start
                                                    }
                                                })}
                                            />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <div className="text-gray-500">End</div>
                                            <TimePicker
                                                onChange={(value) => setBreaktime(prevState => {
                                                    /*if (!value) return prevState;
                                                    let end = value.toString().split(':').slice(0,2).join(':')
                                                    return {
                                                        ...prevState,
                                                        end: end
                                                    }*/
                                                    if (!value) return prevState;
                                                    let {hour, minute} = value
                                                    let end = [hour, minute].map(e => e.toString().length < 2 ? "0"+e : e).join(':')
                                                    return {
                                                        ...prevState,
                                                        end: end
                                                    }
                                                })}
                                            />
                                        </div>
                                        <Button
                                            variant="taimDefault2"
                                            onClick={(event) => addBreaktime(event)}
                                        ><FormattedMessage id={"add"} defaultMessage={"Add"} /></Button>
                                    </div>

                                    <FormField
                                        control={form.control}
                                        name={"breakTimes"}
                                        render={({ field}) => (
                                            <div className="flex flex-col gap-2">
                                                <Table className={"border rounded-md"}>
                                                    <TableHeader>
                                                        <TableRow>
                                                            <TableHead>
                                                                <FormattedMessage id={"attendance.attendances.startTime"} defaultMessage={"Start time"}/>
                                                            </TableHead>
                                                            <TableHead>
                                                                <FormattedMessage id={"attendance.attendances.endTime"} defaultMessage={"End time"}/>
                                                            </TableHead>
                                                            <TableHead>
                                                                <FormattedMessage id={"attendance.attendances.duration"} defaultMessage={"Duration"}/>
                                                            </TableHead>
                                                            <TableHead>
                                                                <FormattedMessage id={"attendance.attendances.action"} defaultMessage={"Action"}/>
                                                            </TableHead>
                                                        </TableRow>
                                                    </TableHeader>
                                                    <TableBody>
                                                        {breaktimes.length ? (
                                                            breaktimes.map((currBreak, index) => {
                                                                let startTime = moment(currBreak.start, "HH:mm");
                                                                let endTime = moment(currBreak.end, "HH:mm");
                                                                let hours = "00h";
                                                                let minutes = "00m";
                                                                let formattedDuration = `${hours} ${minutes}`;
                                                                let isNightShift = startTime.format("A") === "PM" && endTime.format("A") === "AM";
                                                                if (isNightShift) {
                                                                    endTime.add(1, "d");
                                                                }
                                                                let duration = moment.duration(endTime.diff(startTime));

                                                                if (duration) {
                                                                    hours = Math.floor(duration.asHours()).toString();
                                                                    minutes = duration.minutes().toString();
                                                                    formattedDuration = (() => {
                                                                        let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                                                                        let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";
                                                                        return `${formattedHours} ${formattedMinutes}`
                                                                    })();
                                                                }

                                                                return (
                                                                    <TableRow key="index">
                                                                        <TableCell>{currBreak.start}</TableCell>
                                                                        <TableCell>{currBreak.end}</TableCell>
                                                                        <TableCell>{formattedDuration}</TableCell>
                                                                        <TableCell>
                                                                            <Trash
                                                                                color={"red"}
                                                                                size={20}
                                                                                className="cursor-pointer"
                                                                                onClick={() => removeBreaktime(currBreak)}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })) : (
                                                            <TableRow className="text-gray-500 text-xs">
                                                                <TableCell colSpan={4} className={"text-center"}>
                                                                    <FormattedMessage id={"attendance.attendances.addBreaktime"} defaultMessage={"Add breaktimes here."}/>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                                <FormMessage className="max-w-[25rem]"/>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className="flex flex-row gap-2">
                                    <div className="grow">
                                        <FormField
                                            control={form.control}
                                            name="checkinLocationUUID"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel area-label={field.name}>
                                                        <FormattedMessage
                                                            id={"attendance.attendances.createAttendance.checkInLoc"}
                                                            defaultMessage={"Check In Location"}
                                                        />
                                                    </FormLabel>
                                                    <SearchInput
                                                        values={locations?.map((e) => {
                                                            return {key: e.name, value: e.uuid}
                                                        }) || []}
                                                        value={field.value}
                                                        title={intl.formatMessage({
                                                            id: "attendance.attendances.createAttendance.locationPlaceHolder",
                                                            defaultMessage: "Select a location"
                                                        })}
                                                        onChange={field.onChange}
                                                    />
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                    <div className="grow">
                                        <FormField
                                            control={form.control}
                                            name="checkoutLocationUUID"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel area-label={field.name}>
                                                        <FormattedMessage
                                                            id={"attendance.attendances.createAttendance.checkOutLoc"}
                                                            defaultMessage={"Check Out Location"}
                                                        />
                                                    </FormLabel>
                                                    <SearchInput
                                                        values={locations?.map((e) => {
                                                            return {key: e.name, value: e.uuid}
                                                        }) || []}
                                                        value={field.value}
                                                        title={intl.formatMessage({
                                                            id: "attendance.attendances.createAttendance.locationPlaceHolder",
                                                            defaultMessage: "Select a location"
                                                        })}
                                                        onChange={field.onChange}
                                                    />
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="grow">
                                <FormField
                                    control={form.control}
                                    name="checkinLocationUUID"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel area-label={field.name}>
                                                <FormattedMessage
                                                    id={"attendance.attendances.createAttendance.checkInLoc"}
                                                    defaultMessage={"Check In Location"}
                                                />
                                            </FormLabel>
                                            <SearchInput
                                                values={locations?.map((e) => {
                                                    return {key: e.name, value: e.uuid}
                                                }) || []}
                                                value={field.value}
                                                title={intl.formatMessage({
                                                    id: "attendance.attendances.createAttendance.locationPlaceHolder",
                                                    defaultMessage: "Select a location"
                                                })}
                                                onChange={field.onChange}
                                            />
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        )}

                        <ErrorMessageList errors={errors?.detailErrors} />
                        <ErrorMessageList errors={errors?.nonFieldErrors} />

                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <Button variant="outline" onClick={goToPreviousStep}>
                                <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                            </Button>
                            <Button
                                variant="taimDefault"
                                type="button"
                                onClick={validateSecondStep}
                            ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                        </DialogFooter>
                    </TabsContent>
                    <TabsContent value={"preview"} className="flex flex-col gap-4">

                        <DetailViewCard rows={[
                            {
                                name: intl.formatMessage({id: "attendance.attendances.createAttendance.employee", defaultMessage: "Employee"}),
                                value: employees?.find(elem => elem?.userUUID === form.getValues('userUUID'))?.fullName
                            },
                            {
                                name: intl.formatMessage({id: "attendance.attendances.createAttendance.checkInDate", defaultMessage: "Attendance Date"}),
                                value: form.getValues('checkInDate') ? moment(form.getValues('checkInDate')).local().format("ddd DD MMM YYYY") : nullOrUndefined
                            },
                            {
                                name: "Check In",
                                value: (() => {
                                    let hour,minute
                                    if (form.getValues('checkInTime')) {
                                        hour = form.getValues('checkInTime').hour
                                        minute = form.getValues('checkInTime').minute
                                    }
                                    return (hour !== undefined && minute !== undefined) ? moment({h: hour, m: minute}).format('HH:mm') : nullOrUndefined
                                })()
                            },
                            {
                                name: "Check Out",
                                value: (() => {
                                    let hour,minute
                                    if (form.getValues('checkOutTime')) {
                                        hour = form.getValues('checkOutTime').hour
                                        minute = form.getValues('checkOutTime').minute
                                    }
                                    return (hour !== undefined && minute !== undefined) ? moment({h: hour, m: minute}).format('HH:mm') : nullOrUndefined
                                })()
                            },
                            {
                                name: intl.formatMessage({id: "attendance.attendances.createAttendance.checkInLoc", defaultMessage: "Check In Location"}),
                                value: (() => {
                                    let locationUUID = form.getValues('checkinLocationUUID')
                                    return locations?.find(elem => elem.uuid === locationUUID)?.name ?? nullOrUndefined
                                })()
                            },
                            {
                                name: intl.formatMessage({id: "attendance.attendances.createAttendance.checkOutLoc", defaultMessage: "Check Out Location"}),
                                value: (() => {
                                    let locationUUID = form.getValues('checkoutLocationUUID')
                                    return locations?.find(elem => elem.uuid === locationUUID)?.name ?? nullOrUndefined
                                })()
                            }
                        ]}/>

                        <ErrorMessageList errors={errors?.detailErrors} />
                        <ErrorMessageList errors={errors?.nonFieldErrors} />

                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose ref={closeModal} asChild>
                                <Button variant="outline" className={"hidden"}>
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </Button>
                            </DialogClose>
                            <Button variant="outline" onClick={goToPreviousStep}>
                                <FormattedMessage id={"button.back"} defaultMessage={"Back"}/>
                            </Button>
                            <Button
                                variant="taimDefault"
                                type="submit"
                            ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                        </DialogFooter>
                    </TabsContent>
                </Tabs>
            </form>
        </Form>
    )
}

const exportFormSchema = z.object({
    userUUID: z.string().optional(),
    year: z.string(),
    month: z.string()
})

export const AttendanceMonthlyExport = ({employees}: {employees?: Employee[]}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof exportFormSchema>>({
        resolver: zodResolver(exportFormSchema),
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const employeesValues = employees?.map((e) => {
        return {key: e.fullName, value: e.userUUID}
    }) || []

    const handleExport = ({year, month, userUUID}: {year: string, month: string, userUUID?: string}) => {
        let apiParams = `month=${month}&year=${year}${userUUID ? `&user_uuid=${userUUID}` : ""}`;
        axiosInstance.post(attendancesUrl + `monthly-export/?${apiParams}`,null,{
            responseType: "blob"
        })
            .then((res) => {
                const blob = new Blob([res.data])
                const blobUrl = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${year}_${month}_${moment().valueOf()}_month_attendances.xlsx`;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(blobUrl);

                closeModal.current?.click();
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                });
                setApiErrors({});
            })
            .catch((err) => {
                setApiErrors(err.response.data);
                toast({
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: intl.formatMessage({id: "toast.success.errorOccured", defaultMessage: "An error occurred. Please try again later."}),
                    variant: "destructive"
                });
            })
    }

    const onSubmit = (values: z.infer<typeof exportFormSchema>) => {
        handleExport(values)
    }

    return (
        <Form {...form}>
            <form method="post" onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <FormField
                    control={form.control}
                    name="userUUID"
                    render={({ field }) => (
                        <FormItem className="grow flex flex-col">
                            <FormLabel area-label={field.name}>
                                <FormattedMessage
                                    id={"attendance.attendances.monthExport.employee"}
                                    defaultMessage={"Employee"}
                                />{" "}<span className="text-xs text-slate-500">(optional)</span>
                            </FormLabel>
                            <SearchInput
                                values={employeesValues}
                                value={field.value}
                                title={intl.formatMessage({
                                    id: "attendance.attendances.createAttendance.employeePlaceHolder",
                                    defaultMessage: "Select an employee"
                                })}
                                onChange={field.onChange}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            </div>
            <div className="flex flex-row gap-2">
                <div className="grow">
                    <FormField
                        control={form.control}
                        name="month"
                        render={({ field }) => (
                            <FormItem className="grow flex flex-col">
                                <FormLabel area-label={field.name}>
                                    <FormattedMessage
                                        id={"attendance.attendances.monthExport.month"}
                                        defaultMessage={"Month"}
                                    />
                                </FormLabel>
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <div>
                                        <SelectTrigger>
                                            <SelectValue placeholder={intl.formatMessage({
                                            id: "attendance.attendances.monthExport.monthPlaceHolder",
                                            defaultMessage: "Select a month"
                                        })} />
                                        </SelectTrigger>
                                    </div>
                                    <SelectContent>
                                        {months && months.map((month, index) => (
                                            <SelectItem key={index} value={(index + 1).toString()}>
                                                <FormattedMessage id={month} defaultMessage={month}/>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>
                <div className="grow">
                    <FormField
                        control={form.control}
                        name="year"
                        render={({ field }) => (
                            <FormItem className="grow flex flex-col">
                                <FormLabel area-label={field.name}>
                                    <FormattedMessage
                                        id={"attendance.attendances.monthExport.year"}
                                        defaultMessage={"Year"}
                                    />
                                </FormLabel>
                                <Select onValueChange={field.onChange} defaultValue={field.value}>
                                    <div>
                                        <SelectTrigger>
                                            <SelectValue placeholder={intl.formatMessage({
                                                id: "attendance.attendances.monthExport.yearPlaceHolder",
                                                defaultMessage: "Select a year"
                                            })} />
                                        </SelectTrigger>
                                    </div>
                                    <SelectContent>
                                        {years && years.map((year, index) => (
                                            <SelectItem key={index} value={year.toString()}>{year}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>
            </div>

            <ErrorMessageList errors={errors.detailErrors}/>
            <ErrorMessageList errors={errors.nonFieldErrors}/>

            <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                <DialogClose ref={closeModal as any} asChild>
                    <Button variant="outline">
                        <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                    </Button>
                </DialogClose>
                <Button
                    variant="taimDefault"
                    type="submit"
                ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
            </DialogFooter>
        </form>
        </Form>
    );
}