import axios from "axios";
import * as process from "process";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {PermissionContext} from "./PermissionProvider";

const domain = (process.env.NODE_ENV === "development") ? process.env.REACT_APP_DOMAIN_DEV : process.env.REACT_APP_DOMAIN_PROD;
const apiDomain = (process.env.NODE_ENV === "development") ? process.env.REACT_APP_API_DOMAIN_DEV : process.env.REACT_APP_API_DOMAIN_PROD;

const getToken = () => localStorage.getItem("token");
const getUserState = () => localStorage.getItem("isAdminView");

export const axiosInstanceOld = axios.create({
    baseURL: (process.env.NODE_ENV === "development") ? apiDomain : window.location.origin + apiDomain
});

axiosInstanceOld.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            const newConfig = { ...config };
            newConfig.headers.Authorization = `Token ${token}`;
            return {...newConfig}
        }
        return {...config}
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstanceOld.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.replace(domain + "account-management/login");
        }
        return Promise.reject(error);
    }
);





// axios instance for managing states
export const useAxiosInstance = () => {
    const axiosInstance = axios.create({
        baseURL: (process.env.NODE_ENV === "development") ? apiDomain : window.location.origin + apiDomain
    })
    const navigate = useNavigate();
    const permissionContext = useContext(PermissionContext);

    axiosInstance.interceptors.request.use(
        (config) => {
            let newConfig = { ...config };
            const token = getToken();

            let system = localStorage.getItem("currSystem");

            if (system) {
                newConfig.headers["CURRENT-SYSTEM"] = system;
            }

            if (token) {
                newConfig.headers.Authorization = `Token ${token}`;
            }

            return {...newConfig};
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            let localStorageUserState = getUserState()?.toLowerCase()
            let responseHeadersUserState = response.headers["is-admin-view"]?.toLowerCase()
            let environment = response.headers?.environment


            if (environment) {
                localStorage.setItem("environment", environment)
            }

            if (
                localStorageUserState !== responseHeadersUserState &&
                !response.config.url?.includes("switch-admin-state") &&
                !response.config.url?.includes("login")
            ) {
                permissionContext.changeState(responseHeadersUserState === 'true' ? "admin" : "user", true)
            }

            return response;
        },
        (error) => {
            if (error && error.response && error.response.status === 401) {

                for (let localStorageKey in localStorage) {
                    if (
                        localStorageKey !== "currSystem" &&
                        localStorageKey !== "currSystemRepr" &&
                        localStorageKey !== "environment"
                    ) {
                        localStorage.removeItem(localStorageKey)
                    }
                }
                navigate("/account-management/login");
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}