import React, {useContext, useEffect, useRef, useState} from "react";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {WORKING_TIME_ACCOUNT_URL} from "./index";
import DataTable, {Column} from "../../../Core/Table/data-table";
import DataTablePagination from "../../../Core/Table/data-table-pagination";
import {DataTablePayload, DataTableRequest} from "../../../Core/Table/interfaces";
import {Action} from "../../../Core/Table/data-table-actions";
import {TaimModal} from "../../../Core/components/taim-modal";
import {Button} from "../../../components/button";
import {FormattedMessage, useIntl} from "react-intl";
import {useForm} from "react-hook-form";
import * as z from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {ServerErrorsType, useServerErrors} from "../../../Core/functions/use-server-errors";
import moment from "moment/moment";
import {toast} from "../../../components/use-toast";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from "../../../components/form";
import ErrorMessageList from "../../../Core/components/ErrorMessageList";
import {DialogClose, DialogFooter} from "../../../components/dialog";
import {
    Select,
    SelectClear,
    SelectContent,
    SelectItem,
    SelectNull,
    SelectTrigger,
    SelectValue
} from "../../../components/select";
import {Switch} from "../../../components/switch";
import {getYearsArray} from "../../../Core/constants/variables";

const DROPDOWN_YEARS = getYearsArray()

type WorkingTimeAccountStatuses = {
    pk: number
    user: number
    isActivated: boolean
    startMonthDate: string
    endMonthDate: string
}

type FormattedWorkingTimeAccountStatuses = WorkingTimeAccountStatuses & {
    actions: Action[]
    startMonthDateFormatted: string
    endMonthDateFormatted: string
}

export interface WorkingTimeAccountStatusesResponse extends DataTableRequest {
    data: WorkingTimeAccountStatuses[]
}

const workingTimeAccountStatusesSchema = z.object({
    isActivated: z.any(),
    start: z.object({
        year: z.any(),
        month: z.any()
    }),
    end: z.object({
        year: z.any(),
        month: z.any()
    })
});

const CreateForm: React.FC<{userUUID: string, handleRefetch: (v: boolean) => void}> = ({userUUID, handleRefetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof workingTimeAccountStatusesSchema>>({
        resolver: zodResolver(workingTimeAccountStatusesSchema),
        defaultValues: {
            isActivated: false
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const months = moment.months();

    const onSubmit = (values: z.infer<typeof workingTimeAccountStatusesSchema>) => {
        let payload = {
            isActivated: values.isActivated,
            startMonthDate: (values.start.year && values.start.month) ? moment({
                year: values.start.year,
                month: values.start.month
            }).format('YYYY-MM-DD') : null,
            endMonthDate: (values.end.year && values.end.month) ? moment({
                year: values.end.year,
                month: values.end.month
            }).format('YYYY-MM-DD') : null,
        }

        if (values.end.year || values.end.month) {
            if (!values.end.year || !values.end.month) {
                if (!values.end.year) form.setError('end.year', {message: 'Field is required.'})
                if (!values.end.month) form.setError('end.month', {message: 'Field is required.'})
                return;
            }
        }

        axiosInstance.post(WORKING_TIME_ACCOUNT_URL + `${userUUID}/working-time-account-statuses/`, payload)
            .then((res) => {
                setApiErrors({});
                closeModal.current?.click();
                handleRefetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    useEffect(() => {
        let start = form.getValues('start')

        if (errors.fieldErrors['startMonthDate']) {
            if (!start.year) form.setError('start.year', {message: 'Field is required.'})
            if (!start.month) form.setError('start.month', {message: 'Field is required.'})
        }
    }, [errors.fieldErrors]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">

                <FormField
                    control={form.control}
                    name="isActivated"
                    render={({ field }) => (
                        <FormItem className="flex space-x-4 items-center border rounded-lg px-4 py-2">
                            <div className="w-1/2">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"activate"}
                                        defaultMessage={"Activate"}
                                    />
                                </FormLabel>
                            </div>
                            <FormControl className={"w-1/2"}>
                                <div>
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"start.date"}
                            defaultMessage={"Start Date"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="start.year"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"year"}
                                            defaultMessage={"Year"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[45vh]">
                                                <SelectNull field={field}/>
                                                {DROPDOWN_YEARS.map((year) => (
                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="start.month"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"month"}
                                            defaultMessage={"Month"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[45vh]">
                                                <SelectNull field={field}/>
                                                {months.map((month, index) => (
                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>


                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"end.date"}
                            defaultMessage={"End Date"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="end.year"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"year"}
                                            defaultMessage={"Year"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {DROPDOWN_YEARS.map((year) => (
                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="end.month"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"month"}
                                            defaultMessage={"Month"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {months.map((month, index) => (
                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}

const EditForm: React.FC<{record: WorkingTimeAccountStatuses, userUUID: string, handleRefetch: (v: boolean) => void}> = ({record, userUUID, handleRefetch}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof workingTimeAccountStatusesSchema>>({
        resolver: zodResolver(workingTimeAccountStatusesSchema),
        defaultValues: {
            isActivated: record.isActivated,
            ...(record.startMonthDate && {
                start: {
                    year: moment(record.startMonthDate, 'YYYY-MM-DD').get('year').toString(),
                    month: moment(record.startMonthDate, 'YYYY-MM-DD').get('month').toString()
                },
            }),
            ...(record.endMonthDate && {
                end: {
                    month: moment(record.endMonthDate, 'YYYY-MM-DD').get('month').toString(),
                    year: moment(record.endMonthDate, 'YYYY-MM-DD').get('year').toString(),
                }
            })
        }
    });
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const closeModal = useRef<HTMLElement>();
    const months = moment.months();

    const onSubmit = (values: z.infer<typeof workingTimeAccountStatusesSchema>) => {
        let payload = {
            isActivated: values.isActivated,
            startMonthDate: (values.start.year && values.start.month) ? moment({
                year: values.start.year,
                month: values.start.month
            }).format('YYYY-MM-DD') : null,
            endMonthDate: (values.end.year && values.end.month) ? moment({
                year: values.end.year,
                month: values.end.month
            }).format('YYYY-MM-DD') : null,
        }

        if (values.end.year || values.end.month) {
            if (!values.end.year || !values.end.month) {
                if (!values.end.year) form.setError('end.year', {message: 'Field is required.'})
                if (!values.end.month) form.setError('end.month', {message: 'Field is required.'})
                return;
            }
        }

        axiosInstance.put(WORKING_TIME_ACCOUNT_URL + `${userUUID}/working-time-account-statuses/${record.pk}/`, payload)
            .then((res) => {
                closeModal.current?.click();
                handleRefetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data)
            })
    };

    useEffect(() => {
        let start = form.getValues('start')

        if (errors.fieldErrors['startMonthDate']) {
            if (!start.year) form.setError('start.year', {message: 'Field is required.'})
            if (!start.month) form.setError('start.month', {message: 'Field is required.'})
        }
    }, [errors.fieldErrors]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">

                <FormField
                    control={form.control}
                    name="isActivated"
                    render={({ field }) => (
                        <FormItem className="flex space-x-4 items-center border rounded-lg px-4 py-2">
                            <div className="w-1/2">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"activate"}
                                        defaultMessage={"Activate"}
                                    />
                                </FormLabel>
                            </div>
                            <FormControl className={"w-1/2"}>
                                <div>
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"start.date"}
                            defaultMessage={"Start Date"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="start.year"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"year"}
                                            defaultMessage={"Year"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[45vh]">
                                                <SelectNull field={field}/>
                                                {DROPDOWN_YEARS.map((year) => (
                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="start.month"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"month"}
                                            defaultMessage={"Month"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[45vh]">
                                                <SelectNull field={field}/>
                                                {months.map((month, index) => (
                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>


                <div className="flex flex-col space-y-1">
                    <div>
                        <FormattedMessage
                            id={"end.date"}
                            defaultMessage={"End Date"}
                        />
                    </div>
                    <div className="flex space-x-2">
                        <FormField
                            control={form.control}
                            name="end.year"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"year"}
                                            defaultMessage={"Year"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {DROPDOWN_YEARS.map((year) => (
                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="end.month"
                            render={({ field }) => (
                                <FormItem className="w-1/2">
                                    <FormLabel className="text-gray-500">
                                        <FormattedMessage
                                            id={"month"}
                                            defaultMessage={"Month"}
                                        />
                                    </FormLabel>
                                    <FormControl>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <SelectTrigger className="select-none">
                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                            </SelectTrigger>
                                            <SelectContent className="max-h-[50vh]">
                                                <SelectNull field={field}/>
                                                {months.map((month, index) => (
                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>


                <ErrorMessageList errors={errors.detailErrors}/>
                <ErrorMessageList errors={errors.nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}


export const useWorkingTimeAccountStatuses = (payload: DataTablePayload, userUUID: string, refetch: boolean, handleRefetch: (v: boolean) => void) => {
    const intl = useIntl();
    const [response, setResponse] = useState<WorkingTimeAccountStatusesResponse>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [formattedData, setFormattedData] = useState<FormattedWorkingTimeAccountStatuses[]>([])
    const [loading, setLoading] = useState(false)
    const permissionContext = useContext(PermissionContext)
    const axiosInstance = useAxiosInstance()

    const fetchData = () => {
        setLoading(true);

        axiosInstance.get(WORKING_TIME_ACCOUNT_URL + `${userUUID}/working-time-account-statuses/`, {
            params: payload
        })
            .then(res => {
                let data: FormattedWorkingTimeAccountStatuses[] = res.data?.data?.map((rec: WorkingTimeAccountStatuses) => ({
                    ...rec,
                    isActivated: rec.isActivated ? intl.formatMessage({id: 'yes', defaultMessage: 'Yes'}) : intl.formatMessage({id: 'no', defaultMessage: 'No'}),
                    ...(rec.startMonthDate && {startMonthDateFormatted: moment(rec.startMonthDate, 'YYYY-MM-DD').format('MMM YYYY')}),
                    ...(rec.endMonthDate ? {
                        endMonthDateFormatted: moment(rec.endMonthDate, 'YYYY-MM-DD').format('MMM YYYY')
                    } : {
                        endMonthDateFormatted: intl.formatMessage({id: "allTime", defaultMessage: "All Time"})
                    }),
                    actions: [
                        {
                            type: 'edit',
                            content: <EditForm record={rec} userUUID={userUUID} handleRefetch={handleRefetch}/>
                        },
                        {
                            type: 'delete',
                            handleAction: () => deleteRecord(rec.pk)
                        },
                    ]
                }))
                setResponse(res.data)
                setFormattedData(data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
                handleRefetch(false)
            })
    }

    const deleteRecord = (pk: number) => {
        axiosInstance.delete(WORKING_TIME_ACCOUNT_URL + `${userUUID}/working-time-account-statuses/${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                handleRefetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }


    useEffect(() => {
        fetchData();
    }, [payload, userUUID, refetch, permissionContext.isAdmin]);

    return {response, formattedData, loading}
}

export const WorkingTimeAccountStatusesTable: React.FC<{userUUID: string}> = ({userUUID}) => {
    const intl = useIntl()
    const permissionContext = useContext(PermissionContext)
    const [refetch, setRefetch] = useState(false)
    const handleRefetch = (v: boolean) => setRefetch(v)
    const [payload, setPayload] = useState<DataTablePayload>({
        start: 0,
        length: 5
    })
    const {
        response,
        formattedData,
        loading
    } = useWorkingTimeAccountStatuses(payload, userUUID, refetch, handleRefetch)

    const columns: Column[] = [
        {
            frontendKey: intl.formatMessage({id: "employees.detail.working_time_account.start", defaultMessage: "Start"}),
            backendKey: 'startMonthDateFormatted'
        },
        {
            frontendKey: intl.formatMessage({id: "employees.detail.working_time_account.end", defaultMessage: "End"}),
            backendKey: 'endMonthDateFormatted'
        },
        {
            frontendKey: intl.formatMessage({id: "employees.detail.working_time_account.activated", defaultMessage: "Activated"}),
            backendKey: 'isActivated'
        },
        {
            frontendKey: 'actions',
            backendKey: 'actions'
        }
    ]



    return (
        <div className="flex flex-col space-y-4">
            <DataTable
                columns={columns}
                data={formattedData}
                loading={loading}
                payload={payload}
                setPayload={setPayload}
            />
            <DataTablePagination request={response} payload={payload} setPayload={setPayload}/>
            {permissionContext.isAdmin && (
                <TaimModal
                    button={(
                        <Button variant="taimDefault2">
                            <FormattedMessage id="employees.detail.working_time_account.working_time_account.create" defaultMessage="Add Working Time Account"/>
                        </Button>
                    )}
                    header={intl.formatMessage({id: "employees.detail.working_time_account.working_time_account.create", defaultMessage: "Add Working Time Account"})}
                    // dialogContentClassName={"min-w-[600px]"}
                >
                    <CreateForm userUUID={userUUID} handleRefetch={handleRefetch}/>
                </TaimModal>
            )}
        </div>
    )
}