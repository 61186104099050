import DataTable, {Column} from "Core/Table/data-table";
import React, {SetStateAction, useState} from "react";
import DataTableTools, {Option} from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {
    AttendancesPayload,
    AttendancesRequest,
    attendancesUrl,
} from "AttendanceManagement/Controllers/attendances-controller";
import {AttendanceFormatted} from "AttendanceManagement/Controllers/attendances-controller";
import moment from "moment";
import {useIntl} from "react-intl";
import Calendar from "AttendanceManagement/DataTables/AttendanceManagementCalendar";

export const statuses: Option[] = [
  {
    value: "Check In",
    label: "Check In",
      translationName: 'check.in'
  },
  {
    value: "Check Out",
    label: "Check Out",
      translationName: 'check.out'
  },
  {
    value: "Missing",
    label: "Missing",
      translationName: 'missing'
  },
]

type AttendanceDataTableProps = {
    request: AttendancesRequest
    formattedData: AttendanceFormatted[]
    detailData: AttendanceFormatted | null
    setDetailData: React.Dispatch<AttendanceFormatted | null>
    payload: AttendancesPayload
    setPayload: React.Dispatch<SetStateAction<AttendancesPayload>>
    loading: boolean
}

const AttendancesDataTable = (
    {
        request,
        formattedData,
        detailData,
        setDetailData,
        payload,
        setPayload,
        loading
}: AttendanceDataTableProps) => {
    const intl = useIntl();
    const [isDailyTrack, setDailyTrack] = useState<boolean>(false);
    const attendanceColumns: Column[] = [
        {
            backendKey: "userFullName",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.employee", defaultMessage: "Employee"}),
            sortable: true,
            isSorted: "asc"
        },
        {
            backendKey: "checkInTime",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.checkIn", defaultMessage: "Check In"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "checkOutTime",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.checkOut", defaultMessage: "Check Out"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "totalDuration",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.duration", defaultMessage: "Duration"}),
            sortingValue: "duration",
            sortable: true
        },
        {
            backendKey: "statusBadge",
            frontendKey: intl.formatMessage({id: "attendance.attendances.table.status", defaultMessage: "Status"}),
            sortable: true
        }
    ]


    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                            filterBy: 'filter.status',
                            options: statuses ?? [],
                        },
                        {
                            type: "date",
                            date: "filter.checkInDate",
                            defaultValue: new Date(),
                            disabled: new Date()
                        },
                        {
                            type: "switch",
                            label: "daily tracker",
                            value: isDailyTrack,
                            dispatch: setDailyTrack
                        }
                    ]}
                    exportTable={{
                        apiURL: attendancesUrl + "export/",
                        fileName: `${moment().valueOf()}_attendance`,
                        payload: payload
                    }}
                />
                {isDailyTrack ? (
                    <DataTable
                        data={formattedData}
                        columns={[]}
                        loading={loading}
                        detailData={detailData}
                        setPayload={setPayload}
                        setDetailData={setDetailData}
                        payload={payload}
                        dailyTrack={
                            <Calendar
                                employees={formattedData}
                                date={payload["filter.checkInDate"]}
                                setDetailData={setDetailData}
                            />
                        }
                    />
                ) : (
                    <DataTable
                        data={formattedData}
                        loading={loading}
                        setDetailData={setDetailData}
                        detailData={detailData}
                        setPayload={setPayload}
                        columns={attendanceColumns}
                        payload={payload}
                        showLoadingState={true}
                    />
                )}
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default AttendancesDataTable;