import React, {useContext, useEffect, useRef, useState} from "react";
import AbsencesDataTable from "AttendanceManagement/DataTables/absences-data-table";
import AbsencesDetailView from "AttendanceManagement/DetailViews/absences-detail-view";
import {CreateVacation} from "AttendanceManagement/Forms/absences";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {
    useFetchVacationsDataTable,
    vacationsPayload,
    vacation,
    vacationsUrl
} from "AttendanceManagement/Controllers/vactions-controller";
import {Button} from "components/button";
import {VacationFormatted} from "AttendanceManagement/Controllers/vactions-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {MainTab, PageEntrySchema} from "Core/layout/page-shape";
import {cn} from "../../lib/utils";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {TaimModal} from "../../Core/components/taim-modal";
import {RadioGroup, RadioGroupItem} from "../../components/radio-group";
import {Label} from "../../components/label";
import {DialogClose, DialogFooter} from "../../components/dialog";
import {nullOrUndefined} from "../../Core/constants/variables";


const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "-dateFrom,userFullName",
    "filter.status": ""
}

const Accept: React.FC<{
    isDisabled?: boolean
    handleAction: (input: vacation | null, closeModal: React.RefObject<HTMLElement>, sickNoteSubmitted?: string) => void
    vacationData: vacation | null
}> = ({isDisabled = false, handleAction, vacationData}) => {
    const intl = useIntl()
    const [
        sickNoteSubmitted,
        setSickNoteSubmitted
    ] = useState<string | undefined>()
    const closeModal = useRef<HTMLElement>(null)

    const absenceRequestHasAtLeastOneSickLeave = () => {
        return vacationData?.records.find(elem => elem.type === 'sickLeave')
    }

    const handleSickNoteSubmittedChange = (value: string) => {
        setSickNoteSubmitted(value)
    }

    return (
        <>
            {isDisabled ? (
                <Button
                    variant="taimDefault"
                    className="w-1/2"
                    disabled={isDisabled}
                ><FormattedMessage id={"attendance.vacations.detail.accept"} defaultMessage={"Accept"}/></Button>
            ) : (
                <TaimModal
                    header={intl.formatMessage({id: "attendance.vacations.detail.accept.title", defaultMessage: "Accept absence request"})}
                    button={(
                        <Button
                            variant="taimDefault"
                            className="w-full"
                        ><FormattedMessage id={"attendance.vacations.detail.accept"} defaultMessage={"Accept"}/></Button>
                    )}
                    dialogTriggerClassName={"grow"}
                    onOpenChange={(v) => {
                        if (v) setSickNoteSubmitted(() => absenceRequestHasAtLeastOneSickLeave() ? "no" : undefined)
                    }}
                >
                    <div className="flex flex-col space-y-4">
                        <div className='text-gray-500'>
                            <FormattedMessage
                                id="attendance.absences.accept.sentence"
                                defaultMessage="By submitting, the absence request for employee <strong>{fullName}</strong> will be accepted.<br></br>The absence will start on <strong>{dateFrom}</strong> and end on <strong>{dateTo}</strong>."
                                values={{
                                    strong: chunks => <span className="font-semibold">{chunks}</span>,
                                    br: chunks => <br/>,
                                    fullName: vacationData?.userFullName,
                                    dateFrom:vacationData?.dateFrom,
                                    dateTo: vacationData?.dateTo,
                                }}
                            />
                        </div>
                        {absenceRequestHasAtLeastOneSickLeave() ? (
                            <div className="flex flex-col items-start space-y-3">
                                <Label>
                                    <FormattedMessage
                                        id={"attendance.absences.accept.sick_notes_label"}
                                        defaultMessage={"Have the Sick Leave documents been provided ?"}
                                    />
                                </Label>
                                <RadioGroup value={sickNoteSubmitted} onValueChange={handleSickNoteSubmittedChange} className="flex space-x-2">
                                    <div className="flex items-center space-x-2">
                                        <RadioGroupItem value="yes" id="r1" />
                                        <Label htmlFor="r1"><FormattedMessage id={"yes"} defaultMessage={"Yes"}/></Label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <RadioGroupItem value="no" id="r2" />
                                        <Label htmlFor="r2"><FormattedMessage id={"no"} defaultMessage={"No"}/></Label>
                                    </div>
                                </RadioGroup>
                            </div>
                        ) : null}
                        <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                            <DialogClose asChild ref={closeModal as any}>
                                <Button variant="outline">
                                    <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                                </Button>
                            </DialogClose>
                            <Button
                                variant="taimDefault"
                                type="button"
                                onClick={() => {
                                    handleAction(vacationData, closeModal, sickNoteSubmitted)
                                }}
                            ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                        </DialogFooter>
                    </div>
                </TaimModal>
            )}
        </>
    )
}
const Reject: React.FC<{
    isDisabled?: boolean
    handleAction: (input: vacation | null) => void
    vacationData: vacation | null
}> = ({isDisabled = false, handleAction, vacationData}) => (
    <Button
        variant="outline"
        className="grow"
        onClick={() => handleAction(vacationData)}
        disabled={isDisabled}
    ><FormattedMessage id={"attendance.vacations.detail.reject"} defaultMessage={"Reject"}/></Button>
)


const AcceptAndRejectComponent: React.FC<{
     getVacationData: () => vacation | undefined
     handleAccept: (input: vacation | null, closeModal: React.RefObject<HTMLElement>, sickNoteSubmitted?: string) => void
     handleReject: (input: vacation | null) => void
}> = ({getVacationData, handleAccept, handleReject}) => {
    let vacationData = getVacationData()

    return (
        <>
            {vacationData && (
                <>
                    <Reject handleAction={handleReject} vacationData={vacationData} isDisabled={(vacationData.status.toLowerCase() === "rejected")}/>
                    <Accept handleAction={handleAccept} vacationData={vacationData} isDisabled={(vacationData.status.toLowerCase() === "accepted")}/>
                </>
            )}
        </>
    )
}

const AbsencesEntry: React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext)
    const axiosInstance = useAxiosInstance();
    const [payload, setPayload] = useState<vacationsPayload>(initialPayload);
    const vacationsRequest = useFetchVacationsDataTable(payload);
    const [selectedVacation, setSelectedVacation] = useState<string | null>(null);
    const [modalWidth, setModalWidth] = useState<'s' | 'l' | 'xl'>('s')

    const handleAccept = (vacation: vacation | null, closeModal: React.RefObject<HTMLElement>, sickNoteSubmitted?: string) => {
        axiosInstance.post(vacationsUrl + `${vacation?.pk}/accept/`, {
            ...(sickNoteSubmitted && {sickNoteSubmitted: sickNoteSubmitted === "yes"})
        })
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                });
                vacationsRequest.setRefetch(true);
                closeModal.current?.click();
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."})
                });
            })
    }

    const handleReject = (vacation: vacation | null) => {
        axiosInstance.post(vacationsUrl + `${vacation?.pk}/reject/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                });
                vacationsRequest.setRefetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."})
                });
            })
    }

    const getVacationData = () => vacationsRequest.response.data.find(elem => elem.pk === selectedVacation)


    useEffect(() => {
        let currPage = pagesState?.find(page => page.pageName === name);
        if (currPage && currPage.reFetch) {
            // setPayload(initialPayload);
            vacationsRequest.setRefetch(true);
        }
    }, [pagesState]);

    return (
        <MainTab
            type="mainAndDetail"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <AbsencesDataTable
                    selectedVacation={selectedVacation}
                    setSelectedVacation={setSelectedVacation}
                    vacationsRequest={vacationsRequest.response}
                    payload={payload}
                    setPayload={setPayload}
                    loading={vacationsRequest.loading}
                    reFetch={vacationsRequest.setRefetch}
                />
            }}
            detail={{
                title: intl.formatMessage({id: "attendance.vacations.detail", defaultMessage: "Vacations Request Detail"}),
                content: <AbsencesDetailView
                    vacation={getVacationData()}
                    loading={vacationsRequest.loading}
                    shouldReloadVacations={vacationsRequest.setRefetch}
                    refetchTrigger={vacationsRequest.refetch}
                />,
                actions: (
                    <>
                        {(permissionContext.isAdmin && selectedVacation) ?
                            <>
                                <AcceptAndRejectComponent
                                    getVacationData={getVacationData}
                                    handleAccept={handleAccept}
                                    handleReject={handleReject}
                                />
                            </>
                        : null}
                    </>
                )
            }}
            actions={[
                {
                    type: "modal",
                    button: (
                        <Button variant="taimDefault">
                            <FormattedMessage
                                id={"attendance.vacations.create"}
                                defaultMessage={"Create Absence"}
                            />
                        </Button>
                    ),
                    header: intl.formatMessage({id: "attendance.vacations.create", defaultMessage: "Create Absence"}),
                    children: <CreateVacation reFetch={vacationsRequest.setRefetch} setModalWidth={setModalWidth}/>,
                    dialogContentClassName: cn((() => {
                        switch (modalWidth) {
                            case "s":
                                return 'min-w-fit'
                            case "l":
                                return 'min-w-[650px]'
                            case "xl":
                                return 'min-w-[650px]'
                            default:
                                return 'min-w-fit'
                        }
                    })())
                }
            ]}
        />
    )
}

export default AbsencesEntry;