import React, {useContext, useEffect, useState} from "react";
import AttendancesDataTable from "AttendanceManagement/DataTables/attendances-data-table";
import {
    AttendancesPayload,
    attendancesUrl, EmployeeAttendance,
    useFetchAttendancesDataTable
} from "AttendanceManagement/Controllers/attendances-controller";
import {AttendanceDetailView} from "AttendanceManagement/DetailViews/AttendanceDetails";
import {Button} from "../../components/button";
import {AttendanceMonthlyExport, CreateAttendanceForm} from "AttendanceManagement/Forms/attendance-forms";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {attendanceManagementUrl} from "AttendanceManagement";
import {Employee} from "EmployeeManagement/Controllers/employee-controller";
import {location} from "AttendanceManagement/Controllers/locations-controller";
import {AttendanceFormatted} from "AttendanceManagement/Controllers/attendances-controller";
import {Service, servicesUrl} from "ProjectManagement/Controller/services-controller";
import {FormattedMessage, useIntl} from "react-intl";
import {Badge} from "components/badge";
import statusVariants from "Core/functions/status-variants";
import {MainTab, PageActions, PageEntrySchema} from "Core/layout/page-shape";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import moment from "moment";

const initialPayload = {
    start: 0,
    length:10,
    search: "",
    ordering: "-checkInTime,-checkOutTime,userFullName",
    "filter.status": "",
}

const AttendanceEntry: React.FC<PageEntrySchema> = ({name, pagesState}) => {
    const intl = useIntl();
    const [payload, setPayload] = useState<AttendancesPayload>(initialPayload);
    const {
        request,
        loading,
        reFetchAttendances
    } = useFetchAttendancesDataTable(payload);
    const [attendanceDetail, setAttendanceDetail] = useState<AttendanceFormatted | null>(null);
    const [employees, setEmployees] = useState<Employee[]>();
    const [locations, setLocations] = useState<location[]>();
    const [services, setServices] = useState<Service[]>([]);
    const [formattedData, setFormattedData] = useState<AttendanceFormatted[]>([]);
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);

    const fetchEmployees = () => {
        axiosInstance.get(attendanceManagementUrl + 'employees/')
            .then((res) => setEmployees(res.data))
            .catch((err) => console.log(err))
    }

    const fetchLocations = () => {
        axiosInstance.get(attendanceManagementUrl + 'locations/')
            .then((res) => setLocations(res.data))
            .catch((err) => console.log(err))
    }

    const fetchServices = () => {
        axiosInstance.get(servicesUrl)
            .then((res) => setServices(res.data))
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchServices();
        fetchEmployees();
        fetchLocations();
    },[permissionContext.isAdmin]);

    useEffect(() => {
        let currPage = pagesState?.find(page => page.pageName === name);
        if (currPage && currPage.reFetch) {
            // setPayload(initialPayload);
            reFetchAttendances(true);
        }
    }, [pagesState]);

    const rePopulateRecord = (userUUID: string) => {
        let checkInDate = payload["filter.checkInDate"];
        axiosInstance.get(attendancesUrl + `${userUUID}/`, {
            params: {
                "filter.checkInDate": checkInDate,
            }
        })
            .then((res) => {
                setFormattedData(prevState => prevState.map(currAttendance => {
                    if (currAttendance.userUUID !== userUUID) return currAttendance;

                    let newAttendance: EmployeeAttendance = res.data

                    let updatedAttendance: AttendanceFormatted = {
                        ...currAttendance,
                        ...newAttendance,
                        ...(newAttendance.status && {
                            statusBadge: <Badge variant={statusVariants(newAttendance?.status)}>{newAttendance?.status}</Badge>
                        })
                    }

                    setAttendanceDetail(updatedAttendance);
                    return updatedAttendance;
                }))
            })
            .catch((err) => {
                console.log(err);
                setFormattedData(prevState => {
                    let newAttendances = prevState.filter(currAttendance => currAttendance.userUUID !== userUUID);
                    setAttendanceDetail(null);
                    return newAttendances;
                })
            })
    }

    useEffect(() => {
        const formatData: AttendanceFormatted[] = request?.data.map((currAttendance: EmployeeAttendance) => {
            let formattedAttendance: AttendanceFormatted = {
                ...currAttendance,
                rowID: `${currAttendance.pk}_${currAttendance.checkInDate}`,
                statusBadge: <Badge variant={statusVariants(currAttendance?.status)}>{currAttendance?.status}</Badge>
            }
            if (currAttendance.pk === attendanceDetail?.pk) {
                setAttendanceDetail(formattedAttendance);
            }
            return formattedAttendance;
        })
        setFormattedData(formatData);
    }, [request.data]);

    return (
        <MainTab
            type="mainAndDetail"
            name={name}
            main={{
                title: intl.formatMessage({id: "table.list", defaultMessage: "List"}),
                content: <AttendancesDataTable
                    request={request}
                    formattedData={formattedData}
                    payload={payload}
                    detailData={attendanceDetail}
                    setDetailData={setAttendanceDetail}
                    loading={loading}
                    setPayload={setPayload}
                />
            }}
            detail={{
                title: intl.formatMessage({id: "attendance.attendance.detail", defaultMessage: "Attendance Detail"}),
                content: <AttendanceDetailView
                    rePopulateRecord={rePopulateRecord}
                    services={services}
                    employeeAttendance={attendanceDetail}
                    // loading={loading}
                />
            }}
            actions={[
                {
                    type: "modal",
                    button: (
                        <Button variant="taimDefault">
                            <FormattedMessage
                                id={"attendance.attendances.monthExport"}
                                defaultMessage={"Month Export"}
                            />
                        </Button>
                    ),
                    header: intl.formatMessage({id: "attendance.attendances.monthExport.header", defaultMessage: "Export Month Attendance"}),
                    children: <AttendanceMonthlyExport employees={employees}/>
                },
                {
                    type: "modal",
                    button: (
                        <Button variant="taimDefault">
                            <FormattedMessage
                                id={"attendance.attendances.createAttendance"}
                                defaultMessage={"Create Attendance"}
                            />
                        </Button>
                    ),
                    header: intl.formatMessage({id: "attendance.attendances.createAttendance", defaultMessage: "Create Attendance"}),
                    children: (<CreateAttendanceForm
                        employees={employees}
                        locations={locations}
                        reFetch={reFetchAttendances}
                        payload={payload}
                        setDetailData={setAttendanceDetail}
                    />)
                },
            ].filter((elem) => {
                if (permissionContext.isAdmin) return elem
            }) as PageActions[]}
        />)
}

export default AttendanceEntry;