import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {
    Form,
    FormControl, FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../components/form"
import { Input } from "../../components/input"
import FormTitle from "../../Core/components/form-title";
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useEffect, useRef, useState} from "react";
import {
    EmployeeCreationProps,
    employeesUrl,
    EmploymentType,
    federalState,
    Role
} from "../Controllers/employee-controller";
import {DatePicker} from "../../components/date-picker";
import {Switch} from "../../components/switch";
import formatDate from "../../Core/functions/format-date";
import DetailViewCard from "../../Core/components/detail-view-card";
import {TableCell, TableRow} from "../../components/table";
import {MagicWand} from "@phosphor-icons/react";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {toast} from "../../components/use-toast";
import {TimePicker} from "../../components/date-time-picker/time-picker";
import {TimeValue} from "react-aria";
import {getYearsArray, nullOrUndefined} from "../../Core/constants/variables";
import {FormattedMessage, useIntl} from "react-intl";
import {ServerErrorsType, useServerErrors} from "../../Core/functions/use-server-errors";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import SearchInput from "../../Core/components/search-input";
import moment from "moment";
import {useWorkingPlan} from "../../Core/WorkingPlan/use-working-plan";
import {WorkingPlanBody} from "../../Core/WorkingPlan/components";
import {TWorkingPlanBreak} from "../../Core/WorkingPlan/interfaces";
import {employeeManagementUrl} from "../index";
import {
    Select,
    SelectContent,
    SelectItem, SelectNull,
    SelectTrigger,
    SelectValue
} from "../../components/select";
import {transformHoursAndMinutesToDuration} from "../../Core/functions/transform-duration";

const DROPDOWN_YEARS = getYearsArray()

function formatTimeValue (time?: TimeValue | null) {
    if (time === undefined || time === null) return;

    const updatedHour = (time.hour.toString().length === 1) ? `0${time.hour}` : time.hour;
    const updatedMinute = (time.minute.toString().length === 1) ? `0${time.minute}` : time.minute;
    return `${updatedHour}:${updatedMinute}`
}

const formSchema = z.object({
    firstName: z.any(),
    lastName: z.any(),
    email: z.any(),
    phoneNumber: z.any(),
    roleID: z.any(),
    employmentTypeID: z.any(),
    entranceDate: z.any(),
    stateCode: z.any(),
    vacationDays: z.any(),
    hasDifferentExpiryDate: z.any(),
    expiryDate: z.any(),
    hasCarryover: z.any(),
    manualCarryoverDays: z.any(),
    nfcUUID: z.any(),
    isActive: z.any(),
    password: z.any(),
    confirmPassword: z.any(),
    isTestUser: z.any(),
    workingTimePlanData: z.any(),
    personalNumber: z.any(),
    agendaData: z.any(),
    hasWorkingTimeAccount: z.any(),
    workingTimeAccountStatusStartMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    workingTimeAccountStatusEndMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    hasBalanceBoundary: z.any(),
    balanceBoundaryStartMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    balanceBoundaryEndMonthDate: z.object({
        year: z.any(),
        month: z.any()
    }),
    balanceBoundaryUpperLimit: z.object({
        hours: z.any(),
        minutes: z.any()
    }),
    balanceBoundaryLowerLimit: z.object({
        hours: z.any(),
        minutes: z.any()
    })
})

type FormEmployeeCreateProps = {
    reFetch?: any
    defaultValues?: {
        firstName?: string
        lastName?: string
        entranceDate?: string | Date
        personalNumber?: string
        agendaData?: string
        phoneNumber?: string
        email?: string
        state?: string
        stateCode?: string
    }
    sideFunctions?: (() => void)[]
}

type WorkingPlanSchemaType = {
    pk?: string
    day: number
    dayName: string
    startTime: TimeValue | null | undefined
    endTime: TimeValue | null | undefined
    checked: boolean
    breaktimes: TWorkingPlanBreak[]
    breaktimeHelper: TWorkingPlanBreak
}

type WorkingPlanDurationSchemaType = {
    day: number
    duration: moment.Duration
    formattedDuration: string
}

const WorkingPlanSchema: WorkingPlanSchemaType[] = [
    {
        day: 1,
        dayName: "Monday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 2,
        dayName: "Tuesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 3,
        dayName: "Wednesday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 4,
        dayName: "Thursday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 5,
        dayName: "Friday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 6,
        dayName: "Saturday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    },
    {
        day: 7,
        dayName: "Sunday",
        startTime: null,
        endTime: null,
        checked: false,
        breaktimes: [],
        breaktimeHelper: {}
    }
]

const WorkingPlanDurations: WorkingPlanDurationSchemaType[] = [
    {
        day: 1,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 2,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 3,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 4,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 5,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 6,
        duration: moment.duration(0),
        formattedDuration: "0"
    },
    {
        day: 7,
        duration: moment.duration(0),
        formattedDuration: "0"
    }
]

export const FormEmployeeCreate: React.FC<FormEmployeeCreateProps>  = ({
    reFetch,
    defaultValues,
    sideFunctions
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            isActive: true,
            isTestUser: false,
            workingTimePlanData: (() => {
                return WorkingPlanSchema.map((day) => {
                    return {
                        pk: null,
                        day: day.day,
                        startTime: null,
                        endTime: null,
                        checked: false
                    }
                })
            })(),
            hasDifferentExpiryDate: false,
            hasCarryover: false,
            ...defaultValues
        },
    });
    const [isValidated, setValidation] = useState(false);
    const [startingDate, setStartingDate] = useState<Date | undefined>()
    const [workingPlan, setWorkingPlan] = useState<WorkingPlanSchemaType[]>(WorkingPlanSchema)
    const [workingPlanDurations, setWorkingPlanDurations] = useState<WorkingPlanDurationSchemaType[]>(WorkingPlanDurations);
    const [totalDurations, setTotalDurations] = useState<moment.Duration | null>(moment.duration(0));
    const closeModal = useRef<HTMLElement>();
    const [apiErrors, setApiErrors] = useState<ServerErrorsType>({});
    const errors = useServerErrors(apiErrors, form);
    const {createWithAI, handleSwitchChange} = useWorkingPlan(setWorkingPlan);
    const [hasCarryover,setHasCarryover] = useState(false)
    const [hasDifferentExpiryDate,setHasDifferentExpiryDate] = useState(false)
    const [hasWorkingTimeAccount,setHasWorkingTimeAccount] = useState(false)
    const [hasBalanceBoundary,setHasBalanceBoundary] = useState(false)
    const [hasErrorOnFirstStep,setHasErrorOnFirstStep] = useState(false)
    const [roles, setRoles] = useState<Role[]>([]);
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>([]);
    const [federalStates, setFederalStates] = useState<federalState[]>([]);
    const months = moment.months();

    const fetchRoles = () => {
        axiosInstance.get(employeeManagementUrl + 'roles/')
            .then((res) => {
                setRoles(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchFederalStates = () => {
        axiosInstance.get(employeeManagementUrl + 'states/')
            .then((res) => {
                setFederalStates(res.data)
            })
            .catch((err) => console.log(err))
    }

    const validateEmployeeData = (employee: EmployeeCreationProps) => {
        return new Promise((resolve, reject) => {
            axiosInstance.post(employeesUrl + 'validate-only/', employee)
                .then((res) => {
                    resolve({
                        isValid: true
                    });
                })
                .catch((err) => {
                    reject({
                        isValid: false,
                        errors: err.response.data
                    });
                });
        });
    }

    async function handleValidation(values: z.infer<typeof formSchema>) {
        const data: z.infer<typeof formSchema> = {
            ...values,
            expiryDate: values.hasDifferentExpiryDate ? moment(values.expiryDate).format("YYYY-MM-DD") : null,
            manualCarryoverDays: values.hasCarryover ? values.manualCarryoverDays : null,
            nfcUUID: (values.nfcUUID) ? values.nfcUUID : null,
            entranceDate: formatDate(values?.entranceDate as Date),
            ...(values.hasWorkingTimeAccount && {
                workingTimeAccountStatusStartMonthDate: (values.workingTimeAccountStatusStartMonthDate.year && values.workingTimeAccountStatusStartMonthDate.month) ? moment({
                    year: values.workingTimeAccountStatusStartMonthDate.year,
                    month: values.workingTimeAccountStatusStartMonthDate.month
                }).format('YYYY-MM-DD') : null,
                workingTimeAccountStatusEndMonthDate: (values.workingTimeAccountStatusEndMonthDate.year && values.workingTimeAccountStatusEndMonthDate.month) ? moment({
                    year: values.workingTimeAccountStatusEndMonthDate.year,
                    month: values.workingTimeAccountStatusEndMonthDate.month
                }).format('YYYY-MM-DD') : null,
            }),
            ...(values.hasBalanceBoundary && {
                balanceBoundaryStartMonthDate: (values.balanceBoundaryStartMonthDate.year && values.balanceBoundaryStartMonthDate.month) ? moment({
                    year: values.balanceBoundaryStartMonthDate.year,
                    month: values.balanceBoundaryStartMonthDate.month
                }).format('YYYY-MM-DD') : null,
                balanceBoundaryEndMonthDate: (values.balanceBoundaryEndMonthDate.year && values.balanceBoundaryEndMonthDate.month) ? moment({
                    year: values.balanceBoundaryEndMonthDate.year,
                    month: values.balanceBoundaryEndMonthDate.month
                }).format('YYYY-MM-DD') : null,
                balanceBoundaryLowerLimit : (values.balanceBoundaryLowerLimit.hours || values.balanceBoundaryLowerLimit.minutes) 
                    ? transformHoursAndMinutesToDuration({
                        hours: values.balanceBoundaryLowerLimit.hours,
                        minutes: values.balanceBoundaryLowerLimit.minutes
                    })
                    : null,
                balanceBoundaryUpperLimit : (values.balanceBoundaryUpperLimit.hours || values.balanceBoundaryUpperLimit.minutes) 
                    ? transformHoursAndMinutesToDuration({
                        hours: values.balanceBoundaryUpperLimit.hours,
                        minutes: values.balanceBoundaryUpperLimit.minutes
                    })
                    : null
            })
        }

        if (data.roleID === "no-value") {
            return form.setError("roleID", {message: "Role is requierd"});
        } else {
            form.clearErrors("roleID");
        }

        if (data.employmentTypeID === "no-value") {
            return form.setError("employmentTypeID", {message: "Employment type is required"});
        } else {
            form.clearErrors("employmentTypeID");
        }

        validateEmployeeData(data as any)
            .then((res: any) => {
                setValidation(res.isValid);
                setApiErrors({});
                form.clearErrors();
                setStartingDate(values.entranceDate);
            })
            .catch((err: any) => {
                form.clearErrors();
                setApiErrors(err.errors);
                setValidation(err.isValid);
            })
    }

    const createEmployee = (employee: EmployeeCreationProps) => {
        axiosInstance.post(employeesUrl, employee)
            .then((res) => {
                setApiErrors({})
                closeModal.current?.click();
                sideFunctions?.forEach((func) => func());
                reFetch(true);
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.actionCompleted", defaultMessage: "Your action was completed successfully."})
                });
            })
            .catch((err) => {
                setApiErrors(err.response?.data);
            })
    }

    const onSubmit = () => {
        let values = form.getValues()
        const data = {
            ...values,
            expiryDate: values.hasDifferentExpiryDate ? moment(values.expiryDate).format("YYYY-MM-DD") : null,
            manualCarryoverDays: values.hasCarryover ? values.manualCarryoverDays : null,
            nfcUUID: (values.nfcUUID) ? values.nfcUUID : null,
            entranceDate: formatDate(values?.entranceDate as Date),
            workingTimePlanData: (() => {
                const filteredRecords = workingPlan?.filter((record: any) => record.checked)
                return {
                    ...filteredRecords?.reduce((acc: any, day: any) => {
                        let dayName = day.dayName?.toLowerCase();
                        acc[dayName + "TimeFrom"] = formatTimeValue(day?.startTime as TimeValue);
                        acc[dayName + "TimeTo"] = formatTimeValue(day?.endTime as TimeValue);
                        return acc;
                    }, {}),
                    startingDate: moment(startingDate).local().format("YYYY-MM-DD"),
                }
            })(),
            ...(values.hasWorkingTimeAccount && {
                workingTimeAccountStatusStartMonthDate: (values.workingTimeAccountStatusStartMonthDate.year && values.workingTimeAccountStatusStartMonthDate.month) ? moment({
                    year: values.workingTimeAccountStatusStartMonthDate.year,
                    month: values.workingTimeAccountStatusStartMonthDate.month
                }).format('YYYY-MM-DD') : null,
                workingTimeAccountStatusEndMonthDate: (values.workingTimeAccountStatusEndMonthDate.year && values.workingTimeAccountStatusEndMonthDate.month) ? moment({
                    year: values.workingTimeAccountStatusEndMonthDate.year,
                    month: values.workingTimeAccountStatusEndMonthDate.month
                }).format('YYYY-MM-DD') : null,
            }),
            ...(values.hasBalanceBoundary && {
                balanceBoundaryStartMonthDate: (values.balanceBoundaryStartMonthDate.year && values.balanceBoundaryStartMonthDate.month) ? moment({
                    year: values.balanceBoundaryStartMonthDate.year,
                    month: values.balanceBoundaryStartMonthDate.month
                }).format('YYYY-MM-DD') : null,
                balanceBoundaryEndMonthDate: (values.balanceBoundaryEndMonthDate.year && values.balanceBoundaryEndMonthDate.month) ? moment({
                    year: values.balanceBoundaryEndMonthDate.year,
                    month: values.balanceBoundaryEndMonthDate.month
                }).format('YYYY-MM-DD') : null,
                balanceBoundaryLowerLimit : (values.balanceBoundaryLowerLimit.hours || values.balanceBoundaryLowerLimit.minutes)
                    ? transformHoursAndMinutesToDuration({
                        hours: values.balanceBoundaryLowerLimit.hours,
                        minutes: values.balanceBoundaryLowerLimit.minutes
                    })
                    : null,
                balanceBoundaryUpperLimit : (values.balanceBoundaryUpperLimit.hours || values.balanceBoundaryUpperLimit.minutes)
                    ? transformHoursAndMinutesToDuration({
                        hours: values.balanceBoundaryUpperLimit.hours,
                        minutes: values.balanceBoundaryUpperLimit.minutes
                    })
                    : null
            })
        }
        createEmployee(data as any)
    };

    useEffect(() => {
        setWorkingPlanDurations((prevState) => {
            return prevState.map((elem) => {
                let currentDay = workingPlan.find(day => elem.day === day.day)

                if (!currentDay) return elem;

                let startTime = currentDay ? moment().set({
                    hours: currentDay.startTime?.hour,
                    minutes: currentDay.startTime?.minute,
                }) : null;
                let endTime = currentDay ? moment().set({
                    hours: currentDay.endTime?.hour,
                    minutes: currentDay.endTime?.minute,
                }) : null;
                let hours = "00h";
                let minutes = "00m";
                let formattedDuration = `${hours} ${minutes}`;
                let duration: moment.Duration = moment.duration(0);

                let isReady = !!(
                    String(currentDay.startTime?.hour ?? "").length &&
                    String(currentDay.startTime?.minute ?? "").length &&
                    String(currentDay.endTime?.hour ?? "").length &&
                    String(currentDay.endTime?.minute ?? "").length
                );

                if (
                    isReady &&
                    startTime &&
                    endTime
                ) {

                    if (startTime.isAfter(endTime)) {
                        endTime.add(1, "d")
                    }

                    duration = endTime ? moment.duration(endTime.diff(startTime)) : moment.duration(0);
                    if (duration) {
                        hours = Math.floor(duration.asHours()).toString();
                        minutes = duration.minutes().toString();

                        formattedDuration = (() => {
                            let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                            let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                            return `${formattedHours} ${formattedMinutes}`
                        })();
                    }
                }

                return {...elem, formattedDuration, duration};
            })
        })
    }, [workingPlan]);

    useEffect(() => {
        const totalDurationArray = workingPlanDurations.map(elem => elem.duration)
        const totalDurationsCalculated = totalDurationArray.reduce((accumulator, currentValue) => {
            return accumulator?.add(currentValue)
        })
        setTotalDurations(totalDurationsCalculated)
    }, [workingPlanDurations]);

    useEffect(() => {
        const subscription = form.watch((value, { name, type }) => {
            if (name === "hasCarryover") {
                setHasCarryover(value.hasCarryover)
            } else if (name === "hasDifferentExpiryDate") {
                setHasDifferentExpiryDate(value.hasDifferentExpiryDate)
            } else if (name === "hasWorkingTimeAccount") {
                setHasWorkingTimeAccount(() => {
                    form.setValue("hasBalanceBoundary", false)
                    form.setValue("workingTimeAccountStatusStartMonthDate", {})
                    form.setValue("workingTimeAccountStatusEndMonthDate", {})
                    return value.hasWorkingTimeAccount
                })
            } else if (name === "hasBalanceBoundary") {
                form.setValue("balanceBoundaryLowerLimit", {})
                form.setValue("balanceBoundaryUpperLimit", {})
                form.setValue("balanceBoundaryStartMonthDate", {})
                form.setValue("balanceBoundaryEndMonthDate", {})
                setHasBalanceBoundary(value.hasBalanceBoundary)
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        fetchRoles();
        fetchFederalStates();
        fetchEmployeeTypes();
    }, []);

    useEffect(() => {
        let FIRST_STEP_ERRORS = Object.keys(formSchema.shape).filter(elem => !["workingTimePlanData", "entranceDate"].includes(elem))

        if (isValidated) {
            let objKeys = Object.keys(form.formState.errors)

            if (objKeys.length) {
                let hasErrorFromFirstStep = false
                for (let key of objKeys) {
                    if (FIRST_STEP_ERRORS.includes(key)) {
                        hasErrorFromFirstStep = true
                        break;
                    }
                }

                setHasErrorOnFirstStep(hasErrorFromFirstStep)
            } else {
                setHasErrorOnFirstStep(false)
            }
        }
    }, [form.formState.errors]);

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
            {!isValidated ? (
            <>
                <FormTitle
                    title={intl.formatMessage({
                        id: "employees.forms.createEmployee.employeeData",
                        defaultMessage: "Employee Data"
                    })}
                />
                <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.firstName"} defaultMessage={"First Name"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.firstName",
                                        defaultMessage: "first name"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.lastName"} defaultMessage={"Last Name"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.lastName",
                                        defaultMessage: "last name"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.email"} defaultMessage={"Email"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.email",
                                        defaultMessage: "email address"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.phoneNumber"} defaultMessage={"Phone Number"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.phoneNumber",
                                        defaultMessage: "phone number"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormTitle title={
                    intl.formatMessage({
                        id: "employees.forms.createEmployee.professionalData",
                        defaultMessage: "Professional Data"
                    })
                }/>
                <FormField
                    name={"personalNumber"}
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.employeeData.personalNumber"} defaultMessage={"Personal Number"}/> <span className="text-xs text-slate-500">(optional)</span>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={intl.formatMessage({
                                        id: "employees.forms.createEmployee.employeeData.personalNumber",
                                        defaultMessage: "Personal number"
                                    })}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="roleID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.role"} defaultMessage={"Role"}/>
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "employees.forms.createEmployee.professionalData.role",
                                    defaultMessage: "Role"
                                })}
                                values={roles?.map((role, index) => {
                                    return {
                                        key: role.name,
                                        value: String(role.pk)
                                    }
                                })}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="employmentTypeID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.employmentType"} defaultMessage={"Employment Type"}/>
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "employees.forms.createEmployee.professionalData.employmentType",
                                    defaultMessage: "Employment Type"
                                })}
                                values={employmentTypes?.map((employmentType, index) => {
                                    return {
                                        key: employmentType.type,
                                        value: String(employmentType.pk)
                                    }
                                })}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="entranceDate"
                    render={({ field }) => (
                        <FormItem className="flex flex-col">
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.entranceDate"} defaultMessage={"Entrance Date"}/>
                            </FormLabel>
                            <FormControl>
                                <DatePicker date={field.value} setDate={field.onChange}/>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="stateCode"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.federalState"} defaultMessage={"Federal State"}/>
                            </FormLabel>
                            <SearchInput
                                title={intl.formatMessage({
                                    id: "employees.forms.createEmployee.professionalData.federalState",
                                    defaultMessage: "Federal State"
                                })}
                                values={federalStates.map((federalState, index) => {
                                    return {
                                        key: federalState.stateName,
                                        value: federalState.stateCode
                                    }
                                }) ?? []}
                                value={field.value}
                                onChange={field.onChange}
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormTitle
                    title={intl.formatMessage({
                        id: "employees.forms.createEmployee.vacationData",
                        defaultMessage: "Vacation Data"
                    })}
                />
                <FormField
                    control={form.control}
                    name="vacationDays"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.professionalData.vacation"} defaultMessage={"Vaction Days Per Year"}/>
                            </FormLabel>
                            <FormControl>
                                <Input placeholder="0" type="number" min="0" max="365" {...field}/>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="hasCarryover"
                    render={({ field }) => (
                        <FormItem className="flex flex-col space-y-4">
                            <span className="text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.carryoverSentence"}
                                    defaultMessage={"Carry over vacation days from the previous year to the current year?"}
                                />
                            </span>
                            <FormControl>
                                <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    {field.value ? <FormattedMessage id={"yes"} defaultMessage={"Yes"}/> : <FormattedMessage id={"no"} defaultMessage={"No"}/>}
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {hasCarryover ? (
                    <FormField
                        control={form.control}
                        name="manualCarryoverDays"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"employees.forms.createEmployee.manualCarryoverDays"} defaultMessage={"Carry Over Days"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input placeholder="0" type="number" {...field}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                ) : null}

                <FormField
                    control={form.control}
                    name="hasDifferentExpiryDate"
                    render={({ field }) => (
                        <FormItem className="flex flex-col space-y-4">
                            <span className="text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.expiryDateSentence"}
                                    defaultMessage={"Should vacation carryovers expire at a different time?"}
                                />
                            </span>
                            <FormControl>
                                <div className="flex flex-row items-center gap-2 text-xs text-gray-500 capitalize">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    {field.value ? <FormattedMessage id={"yes"} defaultMessage={"Yes"}/> : <FormattedMessage id={"no"} defaultMessage={"No"}/>}
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {hasDifferentExpiryDate ? (
                    <FormField
                        control={form.control}
                        name="expiryDate"
                        render={({ field }) => (
                            <FormItem className="flex flex-col space-y-4">
                                <FormLabel>
                                    <FormattedMessage
                                        id={"employees.forms.createEmployee.expiryDate"}
                                        defaultMessage={"Expiry Date"}
                                    />
                                </FormLabel>
                                <FormControl>
                                    <DatePicker date={field.value} setDate={field.onChange}/>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                ) : null}



                <FormTitle
                    title={intl.formatMessage({
                        id: "employees.forms.createEmployee.working_time_account",
                        defaultMessage: "Working Time Account"
                    })}
                />
                <FormField
                    control={form.control}
                    name="hasWorkingTimeAccount"
                    render={({ field }) => (
                        <FormItem className="flex space-x-4 justify-between border rounded-lg px-4 py-2">
                            <div>
                                <FormLabel>
                                    <FormattedMessage
                                        id={"employees.forms.createEmployee.working_time_account"}
                                        defaultMessage={"Working Time Account"}
                                    />
                                </FormLabel>
                                <FormDescription>
                                    <FormattedMessage
                                        id={"employees.forms.createEmployee.working_time_account.sentence"}
                                        defaultMessage={"If this employee needs a working time account, please enable this."}
                                    />
                                </FormDescription>
                            </div>
                            <FormControl>
                                <div>
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                {hasWorkingTimeAccount ? (
                    <div className="flex flex-col space-y-4 p-4 border rounded-lg">
                        <div className="flex flex-col space-y-1">
                            <div>
                                <FormattedMessage
                                    id={"start.date"}
                                    defaultMessage={"Start Date"}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccountStatusStartMonthDate.year"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"year"}
                                                    defaultMessage={"Year"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select
                                                    onValueChange={field.onChange}
                                                    value={field.value}
                                                    defaultValue={''}
                                                >
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {DROPDOWN_YEARS.map((year) => (
                                                            <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccountStatusStartMonthDate.month"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"month"}
                                                    defaultMessage={"Month"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select
                                                    onValueChange={field.onChange}
                                                    value={field.value}
                                                >
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {months.map((month, index) => (
                                                            <SelectItem value={index.toString()}>{month}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col space-y-1">
                            <div>
                                <FormattedMessage
                                    id={"end.date"}
                                    defaultMessage={"End Date"}
                                />
                            </div>
                            <div className="flex space-x-2">
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccountStatusEndMonthDate.year"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"year"}
                                                    defaultMessage={"Year"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange} value={field.value}>
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {DROPDOWN_YEARS.map((year) => (
                                                            <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="workingTimeAccountStatusEndMonthDate.month"
                                    render={({ field }) => (
                                        <FormItem className="w-1/2">
                                            <FormLabel className="text-gray-500">
                                                <FormattedMessage
                                                    id={"month"}
                                                    defaultMessage={"Month"}
                                                />
                                            </FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange} value={field.value}>
                                                    <SelectTrigger className="select-none">
                                                        <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                                    </SelectTrigger>
                                                    <SelectContent className="max-h-[50vh]">
                                                        <SelectNull field={field}/>
                                                        {months.map((month, index) => (
                                                            <SelectItem value={index.toString()}>{month}</SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        {errors.fieldErrors["workingTimeAccountStatusStartMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["workingTimeAccountStatusStartMonthDate"]}/>}
                        {errors.fieldErrors["workingTimeAccountStatusEndMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["workingTimeAccountStatusEndMonthDate"]}/>}
                    </div>
                ) : null}

                {hasWorkingTimeAccount ? (
                    <>
                        <FormField
                            control={form.control}
                            name="hasBalanceBoundary"
                            render={({ field }) => (
                                <FormItem className="flex space-x-4 justify-between border rounded-lg px-4 py-2">
                                    <div>
                                        <FormLabel>
                                            <FormattedMessage
                                                id={"employees.forms.createEmployee.balance_boundary"}
                                                defaultMessage={"Balance Boundary"}
                                            />
                                        </FormLabel>
                                        <FormDescription>
                                            <FormattedMessage
                                                id={"employees.forms.createEmployee.balance_boundary.sentence"}
                                                defaultMessage={"if you want to add a balance boundary, please enable this."}
                                            />
                                        </FormDescription>
                                    </div>
                                    <FormControl>
                                        <div>
                                            <Switch
                                                className="data-[state=checked]:bg-green-500"
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {hasBalanceBoundary ? (
                            <div className="flex flex-col space-y-4 p-4 border rounded-lg">
                                <div className="flex flex-col space-y-1">
                                    <div>
                                        <FormattedMessage
                                            id={"lower.limit"}
                                            defaultMessage={"Lower Limit"}
                                        />
                                    </div>
                                    <div className="flex space-x-2">
                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryLowerLimit.hours"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"hours"}
                                                            defaultMessage={"Hours"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                                            min={0}
                                                            type={"number"}
                                                            onClick={(e) => e.currentTarget.select()}
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryLowerLimit.minutes"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"minutes"}
                                                            defaultMessage={"Minutes"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder={intl.formatMessage({id: "set.minutes", defaultMessage: "Set minutes"})}
                                                            min={0}
                                                            max={59}
                                                            type={"number"}
                                                            onClick={(e) => e.currentTarget.select()}
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-1">
                                    <div>
                                        <FormattedMessage
                                            id={"upper.limit"}
                                            defaultMessage={"Upper Limit"}
                                        />
                                    </div>
                                    <div className="flex space-x-2">
                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryUpperLimit.hours"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"hours"}
                                                            defaultMessage={"Hours"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder={intl.formatMessage({id: "set.hours", defaultMessage: "Set hours"})}
                                                            min={0}
                                                            type={"number"}
                                                            onClick={(e) => e.currentTarget.select()}
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryUpperLimit.minutes"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"minutes"}
                                                            defaultMessage={"Minutes"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder={intl.formatMessage({id: "set.minutes", defaultMessage: "Set minutes"})}
                                                            min={0}
                                                            max={59}
                                                            type={"number"}
                                                            onClick={(e) => e.currentTarget.select()}
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <div>
                                        <FormattedMessage
                                            id={"start.date"}
                                            defaultMessage={"Start Date"}
                                        />
                                    </div>
                                    <div className="flex space-x-2">
                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryStartMonthDate.year"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"year"}
                                                            defaultMessage={"Year"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} value={field.value}>
                                                            <SelectTrigger className="select-none">
                                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                                            </SelectTrigger>
                                                            <SelectContent className="max-h-[50vh]">
                                                                <SelectNull field={field}/>
                                                                {DROPDOWN_YEARS.map((year) => (
                                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                                ))}
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryStartMonthDate.month"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"month"}
                                                            defaultMessage={"Month"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} value={field.value}>
                                                            <SelectTrigger className="select-none">
                                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                                            </SelectTrigger>
                                                            <SelectContent className="max-h-[50vh]">
                                                                <SelectNull field={field}/>
                                                                {months.map((month, index) => (
                                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                                ))}
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <div>
                                        <FormattedMessage
                                            id={"end.date"}
                                            defaultMessage={"End Date"}
                                        />
                                    </div>
                                    <div className="flex space-x-2">
                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryEndMonthDate.year"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"year"}
                                                            defaultMessage={"Year"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} value={field.value}>
                                                            <SelectTrigger className="select-none">
                                                                <SelectValue placeholder={intl.formatMessage({id: "pick.year", defaultMessage: "Pick year"})}/>
                                                            </SelectTrigger>
                                                            <SelectContent className="max-h-[50vh]">
                                                                <SelectNull field={field}/>
                                                                {DROPDOWN_YEARS.map((year) => (
                                                                    <SelectItem value={(year).toString()}>{year}</SelectItem>
                                                                ))}
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="balanceBoundaryEndMonthDate.month"
                                            render={({ field }) => (
                                                <FormItem className="w-1/2">
                                                    <FormLabel className="text-gray-500">
                                                        <FormattedMessage
                                                            id={"month"}
                                                            defaultMessage={"Month"}
                                                        />
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select onValueChange={field.onChange} value={field.value}>
                                                            <SelectTrigger className="select-none">
                                                                <SelectValue placeholder={intl.formatMessage({id: "pick.month", defaultMessage: "Pick month"})}/>
                                                            </SelectTrigger>
                                                            <SelectContent className="max-h-[50vh]">
                                                                <SelectNull field={field}/>
                                                                {months.map((month, index) => (
                                                                    <SelectItem value={index.toString()}>{month}</SelectItem>
                                                                ))}
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                                {errors.fieldErrors["balanceBoundaryStartMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryStartMonthDate"]}/>}
                                {errors.fieldErrors["balanceBoundaryEndMonthDate"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryEndMonthDate"]}/>}
                                {errors.fieldErrors["balanceBoundaryLowerLimit"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryLowerLimit"]}/>}
                                {errors.fieldErrors["balanceBoundaryUpperLimit"] && <ErrorMessageList errors={errors.fieldErrors["balanceBoundaryUpperLimit"]}/>}
                            </div>
                        ) : null}
                    </>
                ) : null}



                <FormTitle title={
                    intl.formatMessage({
                        id: "employees.forms.createEmployee.account",
                        defaultMessage: "Account"
                    })
                }/>
                <FormField
                    control={form.control}
                    name="nfcUUID"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Nfc uuid <span className="text-xs text-slate-500">(optional)</span></FormLabel>
                            <FormControl>
                                <Input placeholder="Nfc uuid" {...field}/>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="flex flex-row">
                <FormField
                    control={form.control}
                    name="isActive"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormControl>
                                <div className="flex flex-row items-center gap-2">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    <FormLabel className="text-xs text-slate-500">{field.value ? (
                                        <FormattedMessage id={"employees.forms.createEmployee.account.activated"} defaultMessage={"Activated"}/>
                                    ) : (
                                        <FormattedMessage id={"employees.forms.createEmployee.account.deactivated"} defaultMessage={"Deactivated"}/>
                                    )}</FormLabel>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="isTestUser"
                    render={({field}) => (
                        <FormItem className="w-1/2">
                            <FormControl>
                                <div className="flex flex-row items-center gap-2">
                                    <Switch
                                        className="data-[state=checked]:bg-green-500"
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                    />
                                    <FormLabel className="text-xs text-slate-500">
                                        <FormattedMessage id={"employees.forms.createEmployee.account.isTestUser"} defaultMessage={"Test User"}/>
                                    </FormLabel>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                </div>
                <FormTitle title={
                    intl.formatMessage({
                        id: "employees.forms.createEmployee.password",
                        defaultMessage: "Password"
                    })
                }/>
                <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.password"} defaultMessage={"Password"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={
                                        intl.formatMessage({
                                            id: "employees.forms.createEmployee.password",
                                            defaultMessage: "Password"
                                        })
                                    }
                                    type="password"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="confirmPassword"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>
                                <FormattedMessage id={"employees.forms.createEmployee.confirmPassword"} defaultMessage={"Confirm Password"}/>
                            </FormLabel>
                            <FormControl>
                                <Input
                                    placeholder={
                                        intl.formatMessage({
                                            id: "employees.forms.createEmployee.confirmPassword",
                                            defaultMessage: "Confirm Password"
                                        })
                                    }
                                    type="password"
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="button"
                        onClick={() => handleValidation(form.getValues())}
                    ><FormattedMessage id={"button.next"} defaultMessage={"Next"}/></Button>
                </DialogFooter>
            </>
        ) : (
            <div className="flex flex-col gap-4 overflow-auto no-scrollbar">
                <DetailViewCard rows={[
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.typeOfEmployment",
                            defaultMessage: "Type Of Employment"
                        }),
                        value: employmentTypes.find((employmentType) => {
                            return employmentType.pk.toString() === form.getValues("employmentTypeID")
                        })?.type ?? form.getValues("employmentTypeID")
                    },
                    {
                        name: intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.entranceDate",
                            defaultMessage: "Entrance Date"
                        }),
                        value: form.getValues("entranceDate") ? moment(form.getValues("entranceDate")).format("DD MMM YYYY") : nullOrUndefined
                    },
                ]}/>
                <div className="flex flex-row justify-between items-center">
                    <div>
                        <FormTitle title={intl.formatMessage({
                            id: "employees.forms.createEmployee.workingPlan.workingPlan",
                            defaultMessage: "Working Plan"
                        })}/>
                        <span className="text-slate-500 text-xs">
                            <FormattedMessage
                                id={"employees.forms.createEmployee.workingPlan.workingPlan.sentence"}
                                defaultMessage={"Configure at first the Time Plots for the working days"}
                            />
                        </span>
                    </div>
                    <Button
                        type="button"
                        variant={"taimDefault2"}
                        className="flex flex-row gap-2"
                        onClick={createWithAI}
                    >
                        <span>
                            <FormattedMessage
                                id={"employees.forms.createEmployee.workingPlan.workingPlan.createWithAI"}
                                defaultMessage={"Create with AI"}
                            />
                        </span>
                        <MagicWand size={16} color="#233B99"/>
                    </Button>
                </div>
                <WorkingPlanBody>
                    {workingPlan ? (
                        workingPlan.map((day, index) => (
                            <TableRow className="border-none">
                                <TableCell>
                                    <FormattedMessage
                                        id={day.dayName?.toLowerCase()}
                                        defaultMessage={day.dayName}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormField
                                        control={form.control}
                                        name={`workingTimePlanData.${index}.startTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.startTime}
                                                        onChange={(value) => {
                                                            setWorkingPlan(prevState => (
                                                                prevState.map(elem => {
                                                                    if (elem.day === day.day) {
                                                                        return {
                                                                            ...elem,
                                                                            startTime: value
                                                                        }
                                                                    }
                                                                    return  elem;
                                                                })
                                                            ))
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormField
                                        control={form.control}
                                        name={`workingTimePlanData.${index}.endTime`}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <TimePicker
                                                        label={field.name}
                                                        isDisabled={!day?.checked}
                                                        value={day.endTime}
                                                        onChange={(value) => {
                                                            setWorkingPlan(prevState => (
                                                                prevState.map(elem => {
                                                                    if (elem.day === day.day) {
                                                                        return {
                                                                            ...elem,
                                                                            endTime: value
                                                                        }
                                                                    }
                                                                    return  elem;
                                                                })
                                                            ))
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormMessage/>
                                            </FormItem>
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    {workingPlanDurations.find(elem => elem.day === day.day)?.formattedDuration}
                                </TableCell>
                                <TableCell>
                                    <Switch
                                        checked={day.checked}
                                        className="data-[state=checked]:bg-blue-900"
                                        onClick={() => {
                                            handleSwitchChange(index)
                                            setWorkingPlan(prevState => (
                                                prevState.map(elem => {
                                                    if (elem.day === day.day) {
                                                        return {
                                                            ...elem,
                                                            startTime: null,
                                                            endTime: null,
                                                            checked: !day.checked
                                                        }
                                                    }
                                                    return elem;
                                                })
                                            ))
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    ) : null}
                    <TableRow>
                        <TableCell colSpan={3} className={"text-right"}>
                            <span className="">Total</span>
                        </TableCell>
                        <TableCell colSpan={2}>{(() => {
                            let hours = "00h";
                            let minutes = "00m";
                            let formattedDuration = `${hours} ${minutes}`;

                            if (totalDurations) {
                                hours = Math.floor(totalDurations.asHours()).toString();
                                minutes = totalDurations.minutes().toString();

                                formattedDuration = (() => {
                                    let formattedHours = (hours.length > 1 ? hours : `0${hours}`) + 'h';
                                    let formattedMinutes = (minutes.length > 1 ? minutes : `0${minutes}`) + "min";

                                    return `${formattedHours} ${formattedMinutes}`
                                })();
                            }
                            return <span>{formattedDuration}</span>
                        })()}</TableCell>
                    </TableRow>
                </WorkingPlanBody>
                <DetailViewCard rows={[
                    {jsxElement:
                        <div className="flex flex-col gap-3">
                            <div className="text-slate-700 text-base">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.workingPlan.startingDate"}
                                    defaultMessage={"Starting Date"}
                                />
                            </div>
                            <DatePicker
                                date={startingDate}
                                setDate={setStartingDate}
                                disabled={form.getValues('entranceDate')} daysToDisable={"old"}
                            />
                            <span className="text-slate-500 text-sm">
                                <FormattedMessage
                                    id={"employees.forms.createEmployee.workingPlan.startingDate.sentence"}
                                    defaultMessage={`This plan will be active starting from {startingDate} until deleted or replaced`}
                                    values={{
                                        startingDate: moment(startingDate).format("ddd, DD MMM YYYY") ?? nullOrUndefined,
                                    }}
                                />
                            </span>
                        </div>
                    }
                ]}/>

                <ErrorMessageList errors={errors.nonFieldErrors}/>
                <ErrorMessageList errors={errors.detailErrors}/>
                {errors.fieldErrors.hasOwnProperty('entranceDate')
                    ? <div className="text-sm text-red-500">* {errors.fieldErrors['entranceDate']}</div>
                    : null
                }
                {hasErrorOnFirstStep
                    ? <div className="text-sm text-red-500">* {"Error on first step, go back and fix them before submitting."}</div>
                    : null
                }

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button className="hidden">close</Button>
                    </DialogClose>
                    <Button
                        variant="outline"
                        onClick={() => setValidation(false)}
                    ><FormattedMessage id={"button.back"} defaultMessage={"Back"}/></Button>
                    <Button
                        variant="taimDefault"
                        type="submit"
                        onClick={onSubmit}
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </div>
        )}
        </form>
    </Form>
    )
}
