export const nullOrUndefined = "-/-";

export const FROM_YEAR = new Date(1970, 0)

export const TO_YEAR = new Date(new Date().getFullYear() + 2, 11)

export const getYearsArray = (from: number = FROM_YEAR.getFullYear(), to: number = TO_YEAR.getFullYear()): number[] => {
    let newArray: number[] = [];
    if (from > to) {
        throw new Error('From date should be less than to date.');
    } else {
        for (let i = from; i <= to; i++) {
            newArray.unshift(i)
        }
    }

    return newArray
}