import DataTable, {Column, DataTableI} from "Core/Table/data-table";
import React, {useEffect, useState} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {useIntl} from "react-intl";
import {Employee, ProjectEmployeesRequest} from "../Controller/employees-controller";
import {Action} from "Core/Table/data-table-actions";
import {ChangeDefaultServiceForm} from "../Forms/employee-forms";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {projectManagementUrl} from "../index";

export interface EmployeeFormatted extends Employee {
    rowID?: string
    actions: Action[]
}
interface EmployeeTable extends DataTableI<Employee> {
    request: ProjectEmployeesRequest
    reloadData: (value: boolean) => void
}


export const EmployeeTable: React.FC<EmployeeTable> = (
    {
        payload,
        setPayload,
        detailData,
        setDetailData,
        loading,
        request
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<EmployeeFormatted[]>([]);
    const employeeColumns: Column[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "project.employee.name", defaultMessage: "Employee Name"}),
            sortable: true,
        },
        {
            backendKey: "roleName",
            frontendKey: intl.formatMessage({id: "project.employee.role", defaultMessage: "Role"}),
            sortable: true,
        },
        {
            backendKey: "defaultProjectName",
            frontendKey: intl.formatMessage({id: "project.employee.project", defaultMessage: "Project"}),
            sortable: true,
        },
        {
            backendKey: "defaultServiceName",
            frontendKey: intl.formatMessage({id: "project.employee.service", defaultMessage: "Service"}),
            sortable: true,
        },
        {backendKey: "actions", frontendKey: "actions"},
    ];

    const rePopulateRecord = (userUUID: string) => {
        axiosInstance.get(projectManagementUrl + `employees/${userUUID}/`)
            .then((res) => {
                setFormattedData((prevState) => {
                    return prevState.map((employee) => {
                        if (employee.userUUID === userUUID) {
                            let updatedRecord = {
                                ...employee,
                                ...res.data,
                                actions: [
                                    {
                                        type: "edit",
                                        content: <ChangeDefaultServiceForm
                                            rePopulateRecord={rePopulateRecord}
                                            employee={employee}
                                        />
                                    }
                                ]
                            }
                            setDetailData(updatedRecord);
                            return updatedRecord;
                        }
                        return employee
                    })
                })
            })
    }

    useEffect(() => {
        const formatData: EmployeeFormatted[] = request?.data.map((employee) => {
            return {
                ...employee,
                actions: [
                    {
                        type: "edit",
                        content: <ChangeDefaultServiceForm
                            rePopulateRecord={rePopulateRecord}
                            employee={employee}
                        />
                    }
                ]
            }
        });

        setFormattedData(formatData);
    }, [request.data]);

    return (
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTable
                    data={formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    detailData={detailData}
                    setDetailData={setDetailData}
                    columns={employeeColumns}
                    loading={loading}
                />
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

