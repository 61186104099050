import React from "react";
import AttendanceEntry from "./Pages/attendance";
import VacationsEntry from "./Pages/absences";
import TimeCorrectionsEntry from "./Pages/time-corrections";
import SickNotesEntry from "./Pages/sick-notes";
import PublicHolidaysEntry from "./Pages/public-holidays";
import LocationsEntry from "./Pages/locations";
import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";

export const attendanceManagementUrl: string = 'attendance-management/';

const Attendance = () => {
    const intl = useIntl();
    const attendanceName = intl.formatMessage({id: "attendance.attendances", defaultMessage: "Attendances"});
    const timeCorrectionsName = intl.formatMessage({id: "attendance.timeCorrections", defaultMessage: "Time Corrections"});
    const vacationsName = intl.formatMessage({id: "attendance.vacations", defaultMessage: "Absences"});
    // const sickNotesName = intl.formatMessage({id: "attendance.sickNotes", defaultMessage: "Sick Notes"});
    const publicHolidaysName = intl.formatMessage({id: "attendance.publicHolidays", defaultMessage: "Public Holidays"});
    const locationsName = intl.formatMessage({id: "attendance.locations", defaultMessage: "Locations"});

    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "attendance", defaultMessage: "Attendance Management"}),
        sections: [
            {name: attendanceName},
            {name: timeCorrectionsName},
            {name: vacationsName},
            // {name: sickNotesName},
            {name: publicHolidaysName},
            {name: locationsName},
        ],
        pageEntries: [
            props => <AttendanceEntry name={attendanceName} pagesState={props.pagesState}/>,
            props => <TimeCorrectionsEntry name={timeCorrectionsName} pagesState={props.pagesState}/>,
            props => <VacationsEntry name={vacationsName} pagesState={props.pagesState}/>,
            // props => <SickNotesEntry name={sickNotesName} pagesState={props.pagesState}/>,
            props => <PublicHolidaysEntry name={publicHolidaysName} pagesState={props.pagesState}/>,
            props => <LocationsEntry name={locationsName} pagesState={props.pagesState}/>,
        ],
        defaultSection: attendanceName
    }

    return <PageShape {...PageContent}/>
}

export default Attendance;