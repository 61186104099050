import React, {useContext, useEffect} from "react";
import ManagerEntry from "./Pages/manager";
import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import {useNavigate} from "react-router-dom";

const ShiftManagement = () => {
    const intl = useIntl();

    const shiftManagerName = intl.formatMessage({id: "shift_management.shift_manager", defaultMessage: "Shift Manager"});

    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "shift_management.header", defaultMessage: "Shift Management"}),
        sections: [
            {name: shiftManagerName},
        ],
        pageEntries: [
            props => <ManagerEntry name={shiftManagerName} pagesState={props.pagesState}/>,
        ],
        defaultSection: shiftManagerName
    }

    return <PageShape {...PageContent}/>
}

export default ShiftManagement;