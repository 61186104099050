import React, {useContext, useEffect} from "react";
import EmployeesEntry from "./Pages/employees";
import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import {useNavigate} from "react-router-dom";

const ImportManagement = () => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext);
    const navigate = useNavigate()

    const employeesName = intl.formatMessage({id: "import_management.employees", defaultMessage: "Employees"});

    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "import_management.header", defaultMessage: "Import Management"}),
        sections: [
            {name: employeesName},
        ],
        pageEntries: [
            props => <EmployeesEntry name={employeesName} pagesState={props.pagesState}/>,
        ],
        defaultSection: employeesName
    }

    useEffect(() => {
        if (!permissionContext.isAdmin) {
            navigate('/')
        }
    }, []);

    return <PageShape {...PageContent}/>
}

export default ImportManagement;