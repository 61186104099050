import {Card, CardContent, CardHeader, CardTitle} from "components/card";
import AbsenceCard from "Dashboard/components/absence-card";
import ComingSoon from "Dashboard/components/ComingSoon";
import {Absence} from "Dashboard/Controllers/Admin/employees-absences-controller";
import React from "react";
import {Skeleton} from "components/skeleton";
import {FormattedMessage} from "react-intl";
import {getRandomInt} from "Core/functions/random";

type AbsencesSectionProps = {
    absences: Absence[]
    loading: boolean
}

const AbsencesSection: React.FC<AbsencesSectionProps> = ({absences, loading}) => {
    return(
        <div className="col-span-2 flex flex-col gap-4">
            <Card className="pb-4">
                <CardHeader>
                    <CardTitle className="capitalize 2xl:text-[24px] xl:text-[20px]">
                        <FormattedMessage id="dashboard.absences.title" defaultMessage={"Absences"}/>
                    </CardTitle>
                </CardHeader>
                <CardContent className="pr-1">
                    <div className="space-y-8 min-h-[26rem] max-h-[16rem] overflow-auto scrollbar">
                        {absences.length > 0 && (loading || !loading) ? (
                            absences?.map((absence, index) => <AbsenceCard key={index} absence={absence}/>)
                        ) : (absences.length === 0 && loading) ? (
                            Array.from({length: 5}).map(() => (
                                <div className="flex flex-row items-center gap-4">
                                    <Skeleton className="w-[40px] h-[35px] rounded-full"/>
                                    <div className="flex flex-col gap-2 w-full">
                                        <Skeleton className={`w-1/5 h-[10px]`}/>
                                        <Skeleton className={`w-1/${getRandomInt(2,4)} h-[10px]`}/>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <FormattedMessage id={"no_results"} defaultMessage={"No results."}/>
                        )}
                    </div>
                </CardContent>
            </Card>
            <ComingSoon/>
        </div>
    )
}

export default AbsencesSection;