import DataTable, {Column, DataTableI} from "Core/Table/data-table";
import React, {useEffect, useState} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {useIntl} from "react-intl";
import {Device} from "../Controller/devices-controller";
import {Action} from "Core/Table/data-table-actions";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {DataTableRequest} from "../../Core/Table/interfaces";
import {EditDevice} from "../Forms/device-forms";
import {systemsUrl} from "../index";
import {toast} from "../../components/use-toast";

export interface DeviceFormatted extends Device {
    actions: Action[]
}
interface DevicesTable extends DataTableI<Device> {
    request: DataTableRequest
    reloadData: (value: boolean) => void
}


export const DevicesTable: React.FC<DevicesTable> = (
    {
        payload,
        setPayload,
        loading,
        request,
        reloadData
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<DeviceFormatted[]>([]);
    const employeeColumns: Column[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({id: "sd.Devices.name", defaultMessage: "Name"}),
            sortable: true,
        },
        {
            backendKey: "deviceType",
            frontendKey: intl.formatMessage({id: "sd.Devices.deviceType", defaultMessage: "Type"}),
            sortable: true,
        },
        {
            backendKey: "ipv4Address",
            frontendKey: intl.formatMessage({id: "sd.Devices.ipv4Address", defaultMessage: "ipv4 Address"}),
            sortable: true,
        },
        {
            backendKey: "ipv6Address",
            frontendKey: intl.formatMessage({id: "sd.Devices.ipv6Address", defaultMessage: "ipv6 Address"}),
            sortable: true,
        },
        {backendKey: "actions", frontendKey: "actions"},
    ];


    const deleteDevice = (pk: string) => {
        axiosInstance.delete(systemsUrl + `${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reloadData(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    useEffect(() => {
        const formatData: DeviceFormatted[] = request?.data.map((device) => {
            return {
                ...device,
                deviceType: <span className={"capitalize"}>{device.deviceType}</span>,
                actions: [
                    {
                        type: "edit",
                        content: <EditDevice device={device} reFetch={reloadData}/>
                    },
                    {
                        type: "delete",
                        handleAction: () => deleteDevice(device.pk)
                    }
                ]
            }
        });

        setFormattedData(formatData);
    }, [request.data]);

    return (
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTable
                    data={formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    columns={employeeColumns}
                    loading={loading}
                />
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

