import {useIntl} from "react-intl";
import {Projects} from "./Pages/Projects";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import {Employees} from "./Pages/Employees";
import {Services} from "./Pages/Services";


export const projectManagementUrl = 'project-management/';

export const ProjectManagement = () => {
    const intl = useIntl();
    const employeeName = intl.formatMessage({id: "project.employee", defaultMessage: "Employee"});
    const projectsName = intl.formatMessage({id: "project.projects", defaultMessage: "Projects"});
    const servicesName = intl.formatMessage({id: "project.services", defaultMessage: "Services"});


    const pageContent: PageShapeProps = {
        header: intl.formatMessage({id: "project.header", defaultMessage: "Project Management"}),
        pageEntries: [
            props => <Projects name={projectsName} pagesState={props.pagesState}/>,
            props => <Employees name={employeeName} pagesState={props.pagesState}/>,
            props => <Services name={servicesName} pagesState={props.pagesState}/>,
        ],
        sections: [
            {name: projectsName},
            {name: servicesName},
            {name: employeeName},
        ],
        defaultSection: projectsName
    }

    return <PageShape {...pageContent}/>
}