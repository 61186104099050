import React, {useEffect, useState} from "react";
import {EmployeeImported, EmployeeImportedRefined, refineAgendaDataToJson} from "../controller/employees_controller";
import DataTable, {Column} from "../../Core/Table/data-table";
import {Action} from "../../Core/Table/data-table-actions";
import {FormEmployeeCreate} from "../../EmployeeManagement/Forms/form-employee-create";
import {Button} from "../../components/button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../../components/alert-dialog";
import {FormattedMessage, useIntl} from "react-intl";

type EmployeeImportedDataTableRefined = ( EmployeeImportedRefined & {actions: Action[]} )

type EmployeesTableProps = {
    employees: EmployeeImportedRefined[]
    storeData: (employees: EmployeeImported[]) => void
    update: (key: string, item: EmployeeImportedRefined) => Promise<void>
    clearData: () => void
}

export const EmployeesTable: React.FC<EmployeesTableProps> = (
    {
        employees,
        update,
        clearData,
    }) => {
    const intl = useIntl();
    const columns : Column[] = [
        {
            frontendKey: intl.formatMessage({id: "import_management_table.personal_number", defaultMessage: 'Personal Number'}),
            backendKey: 'employeeNumber'
        },
        {
            frontendKey: intl.formatMessage({id: "import_management_table.first_name", defaultMessage: 'First Name'}),
            backendKey: 'firstName'
        },
        {
            frontendKey: intl.formatMessage({id: "import_management_table.last_name", defaultMessage: 'Last Name'}),
            backendKey:'lastName'
        },
        {
            frontendKey: intl.formatMessage({id: "import_management_table.phone_number", defaultMessage: 'Phone Number'}),
            backendKey: 'phoneNumber1'
        },
        {
            frontendKey: intl.formatMessage({id: "import_management_table.status", defaultMessage: 'Status'}),
            backendKey: 'status'
        },
        {frontendKey: 'actions', backendKey: 'actions'},
    ]
    const [formattedData, setFormattedData] = useState<EmployeeImportedDataTableRefined[]>([])

    useEffect(() => {
        const formatData: EmployeeImportedDataTableRefined[] = employees.map((employee) => {
            return {
                ...employee,
                ...(employee.phoneNumber1 && {phoneNumber1: `+49${employee.phoneNumber1}`}),
                ...(employee.phoneNumber2 && {phoneNumber2: `+49${employee.phoneNumber2}`}),
                actions: [
                    {
                        type: "custom",
                        title: intl.formatMessage({id: "import_management.create", defaultMessage: "Create"}),
                        isModal: true,
                        isDisabled: employee.status === "Saved",
                        content: <FormEmployeeCreate
                            defaultValues={{
                                firstName: employee.firstName,
                                lastName: employee.lastName,
                                entranceDate: employee.entryDate,
                                personalNumber: String(employee.employeeNumber),
                                phoneNumber: employee.phoneNumber1 ? employee.phoneNumber1 : employee.phoneNumber2,
                                email: employee.email,
                                agendaData: refineAgendaDataToJson(employee),
                                stateCode: employee.stateCode ?? undefined
                            }}
                            sideFunctions={[
                                () => {
                                    update(String(employee.id), {
                                        ...employee,
                                        status: employee.status === "Saved" ? "Pending" : "Saved"
                                    }).finally(() => console.log('done'))
                                }
                            ]}
                        />,
                        dialogContentClassName: "min-w-[600px]"
                    }
                ]
            }
        })

        setFormattedData(formatData)
    }, [employees]);

    return (
        <div className="flex flex-col space-y-4">
            <div className="w-full flex justify-end">
                <AlertDialog>
                    <AlertDialogTrigger className="w-fit">
                        <Button variant="destructive">
                            <FormattedMessage id={"import_management.clear_imports"} defaultMessage={"Clear Imports"}/>
                        </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>
                                <FormattedMessage id={"import_management.clear_imports"} defaultMessage={"Clear Imports"}/>
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                                <FormattedMessage id={"import_management.clear_imports_sentence"} defaultMessage={"Are you sure, all the imports you did will be lost."}/>
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel><FormattedMessage id={"button.cancel"} defaultMessage={"Cancel"}/></AlertDialogCancel>
                            <AlertDialogAction
                                variant="destructive"
                                onClick={clearData}
                            >
                                <FormattedMessage id={"continue"} defaultMessage={"Continue"}/>
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </div>
            <DataTable
                data={formattedData}
                columns={columns}
            />
        </div>
    );
};