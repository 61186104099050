import DataTable from "Core/Table/data-table";
import React, {useContext} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {DailyTrackRequest} from "Dashboard/Controllers/Admin/daily-track-controller";
import Calendar from "../Calendar";
import {cn} from "lib/utils";
import {PermissionContext} from "../../Core/utilities/PermissionProvider";
import {DataTablePayload} from "../../Core/Table/interfaces";
import {useIntl} from "react-intl";

export const statuses = [
  {
    value: "Check In",
    label: "Check In",
    translationName: "check.in"
  },
  {
    value: "Day Off",
    label: "Day Off",
    translationName: "day.off"
  },
  {
    value: "Vacation",
    label: "Vacation",
    translationName: "vacation"
  },
  {
    value: "Sick Leave",
    label: "Sick Leave",
    translationName: "sick.leave"
  },
  {
    value: "On Break",
    label: "On Break",
      translationName: "on.break"
  },
  {
    value: "Check Out",
    label: "Check Out",
      translationName: "check.out"
  },
]

type DataTableProps = {
    request: DailyTrackRequest
    payload: DataTablePayload
    setPayload: any
    loading: boolean
}

const DailyTrackDataTable = ({request, payload, setPayload, loading}: DataTableProps) => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext);

    return(
        <div className={cn(
            "space-y-4 min-h-fit flex flex-col justify-between",
            permissionContext.isAdmin && "min-h-[45rem]"
        )}>
            <div className="flex flex-col gap-4">
                {permissionContext.isAdmin ? (
                    <DataTableTools
                        payload={payload}
                        setPayload={setPayload}
                        filters={[
                            {
                                type: "simple",
                                title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                                filterBy: 'filter.status',
                                options: statuses ?? [],
                            },
                        ]}
                    />
                ) : null}
                <DataTable
                    data={request.data}
                    columns={[]}
                    loading={loading}
                    detailData={request.data}
                    setPayload={setPayload}
                    payload={payload}
                    dailyTrack={<Calendar employees={request.data || []}/>}
                />
            </div>
            {permissionContext.isAdmin ? (
                <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
            ) : null}
        </div>
    )
}

export default DailyTrackDataTable;