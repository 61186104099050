import DataTable, {Column} from "Core/Table/data-table";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {Action} from "Core/Table/data-table-actions";
import {
    PublicHoliday,
    PublicHolidaysPayload,
    PublicHolidaysRequest,
    publicHolidaysUrl
} from "AttendanceManagement/Controllers/public-holidays-controller";
import {PublicHolidayFormatted} from "AttendanceManagement/Controllers/public-holidays-controller";
import {useIntl} from "react-intl";
import moment from "moment";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";


type DataTableProps = {
    publicHolidaysRequest: PublicHolidaysRequest
    detailData: PublicHolidayFormatted | null
    setDetailData: Dispatch<SetStateAction<PublicHolidayFormatted | null>>
    payload: DataTablePayload
    setPayload: Dispatch<SetStateAction<PublicHolidaysPayload>>
    loading: boolean
    reFetch: Dispatch<SetStateAction<boolean>>
}


const VacationsDataTable = ({publicHolidaysRequest, detailData, setDetailData, payload, setPayload, loading, reFetch}: DataTableProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<PublicHolidayFormatted[]>([]);
    const publicHolidaysColumns: Column[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({id: "attendance.publicHolidays.table.holidayName", defaultMessage: "Holiday Name"}),
            sortable: true,
            isSorted: "asc"
        },
        {
            backendKey: "stateName",
            frontendKey: intl.formatMessage({id: "attendance.publicHolidays.table.state", defaultMessage: "State"}),
            sortingValue: "stateCode",
            sortable: true,
            isSorted: "asc"
        },
        {
            backendKey: "date",
            frontendKey: intl.formatMessage({id: "attendance.publicHolidays.table.date", defaultMessage: "Date"}),
            sortable: true,
            isSorted: "desc"
        },
        {backendKey: "actions", frontendKey: "actions"},
    ]

    const deletePublicHoliday = (id: string) => {
        axiosInstance.delete(publicHolidaysUrl + `${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                setDetailData(null);
                reFetch(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    useEffect(() => {
        const formatData: PublicHolidayFormatted[] = publicHolidaysRequest?.data.map((publicHoliday: PublicHoliday) => {
            return {
                ...publicHoliday,
                date: moment(publicHoliday.date).format("ddd DD MMM YYYY"),
                rowID: `${publicHoliday.pk}_${publicHoliday.name}`,
                actions: [
                    {type: "delete", handleAction: () => deletePublicHoliday(publicHoliday.pk)}
                ] as Action[]
            };
        });

        setFormattedData(formatData);
    }, [publicHolidaysRequest.data]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTable
                    data={formattedData}
                    loading={loading}
                    setDetailData={setDetailData}
                    detailData={detailData}
                    setPayload={setPayload}
                    columns={publicHolidaysColumns}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={publicHolidaysRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default VacationsDataTable;