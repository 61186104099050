import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import DetailViewEmployeeAvatar from "../../Core/components/detail-view-employee-avatar";
import DetailViewCard from "../../Core/components/detail-view-card";
import {Progress} from "../../components/progress";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../../components/tab";
import {
    Employee,
    employeesUrl,
    EmploymentType, useEmployeeWorkingPlans,
    useEmployeeWorkingTimeProgress,
    WorkingTimeProgress
} from "../Controllers/employee-controller";
import {useAxiosInstance} from "../../Core/utilities/AxiosInstance";
import {nullOrUndefined} from "../../Core/constants/variables";
import {WorkingPlan} from "./WorkingPlan";
import {FormattedMessage, useIntl} from "react-intl";
import {EmploymentHistory} from "./EmploymentHistory";
import {AnnualVacation} from "./AnnualVacation";
import {TaimModal} from "../../Core/components/taim-modal";
import {Button} from "../../components/button";
import {toast} from "../../components/use-toast";
import moment from "moment";
import {VacationCredits} from "./VacationCredits";
import {ManualVacationCarryOvers} from "./ManualVacationCarryOvers";
import {VacationExceptionRules} from "./VacationExceptionRules";
import {TaimInfo} from "../../Core/components/taim-info";
import WorkingTimeAccount from "./WorkingTimeAccount";
import {employeeManagementUrl} from "../index";

type EmployeesDetailViewProps = {
    employee: Employee
    loading?: boolean
    rePopulateRecord: (employeeID: string) => void
}

type ContentComponentProps = {
    employee: Employee
    workingTimeProgress: WorkingTimeProgress
    reLoadWorkingTimeProgress: Dispatch<SetStateAction<boolean>>
    employmentType?: EmploymentType
    loading?: boolean
    rePopulateRecord: (employeeID: string) => void
}

const ContentComponent: React.FC<ContentComponentProps> = ({
    employee,
    workingTimeProgress,
    reLoadWorkingTimeProgress,
    employmentType,
    rePopulateRecord
}) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const informationTab = intl.formatMessage({
        id: "employees.detail.information",
        defaultMessage: "Information"
    })
    const workingPlanTab = intl.formatMessage({
        id: "employees.detail.workingPlan",
        defaultMessage: "Working Plan"
    })
    const vacationPlanTab = intl.formatMessage({
        id: "employees.detail.vacationPlan",
        defaultMessage: "Vacation Plan"
    })
    const workingTimeAccountPlanTab = intl.formatMessage({
        id: "employees.detail.working_time_account",
        defaultMessage: "Working Time Account"
    })
    const [tab, setTab] = useState(informationTab);
    const workingPlans = useEmployeeWorkingPlans(employee?.userUUID);
    const [reloadVacationPlanTab, setReloadVacationPlanTab] = useState(false)

    const onTabChange = (value: string) => {
        setTab(value);
    }

    const generateAuthCode = () => {
        axiosInstance.post(employeesUrl + `${employee?.userUUID}/generate-auth-token/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.requestProcessed", defaultMessage: "Your request has been processed successfully."})
                })
                rePopulateRecord(employee.userUUID)
            })
            .catch((err) => {
                toast({
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: intl.formatMessage({id: "toast.error.unableToProcess", defaultMessage: "Unable to process your request at the moment."}),
                    variant: "destructive"
                })
            })
    }

    return(<>
        {employee ? (
            <>
                <div className="flex flex-row justify-between items-center">
                    <DetailViewEmployeeAvatar
                        username={employee?.fullName}
                        isActive={employee?.isActive}
                    />
                    <TaimModal
                        header={"QR Code"}
                        button={<img className="object-cover h-16 w-16" src={employee.qrCode} alt=""/>}
                    >
                        <div className="flex flex-col items-center gap-3 text-slate-500">
                            <img alt="" src={employee.qrCode} className="object-cover h-44 w-44"/>
                            <span>QR Code URL</span>
                            <a className="underline text-blue-800 cursor-pointer" href={employee?.qrCode}>URL</a>
                            {employee?.isTestUser ? (
                                <>
                                    <span>Authentication Code</span>
                                    <span className="font-bold">{employee?.authCode ?? nullOrUndefined}</span>
                                    <div className="w-full text-right" onClick={generateAuthCode}>
                                        <Button variant="taimDefault2">Generate Auth Code</Button>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </TaimModal>
                </div>
                <div className="flex flex-col gap-2">
                    <Progress className="bg-blue-800" value={Number(workingTimeProgress?.percentage)}/>
                    <div className="flex justify-between">
                        <span>{workingTimeProgress?.actualHours}</span>
                        <span className="text-slate-600">{workingTimeProgress?.plannedHours}</span>
                    </div>
                </div>
                <Tabs value={tab} onValueChange={onTabChange} className="w-full">
                    <TabsList className="flex flex-row justify-start overflow-auto w-fit">
                        <TabsTrigger value={informationTab}>{informationTab}</TabsTrigger>
                        <TabsTrigger value={workingPlanTab}>{workingPlanTab}</TabsTrigger>
                        <TabsTrigger value={vacationPlanTab}>{vacationPlanTab}</TabsTrigger>
                        <TabsTrigger value={workingTimeAccountPlanTab}>{workingTimeAccountPlanTab}</TabsTrigger>
                    </TabsList>
                    <TabsContent value={informationTab}>
                        <DetailViewCard rows={[
                            {
                                name: intl.formatMessage({id: "employees.detail.information.personalNumber", defaultMessage: "Personal Number"}),
                                value: employee?.personalNumber ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.role", defaultMessage: "Role"}),
                                value: employee?.role ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.email", defaultMessage: "Email"}),
                                value: employee?.email ?? nullOrUndefined
                            },
                            {
                                name: intl.formatMessage({id: "employees.detail.information.nfcUUID", defaultMessage: "NFC UUID"}),
                                value: employee?.nfcUUID ?? nullOrUndefined},
                            {
                                name: intl.formatMessage({id: "employees.detail.information.vacationDays", defaultMessage: "Vacation Days"}),
                                value: employee?.totalVacationDays ?? nullOrUndefined
                            },
                            /*{
                                name: intl.formatMessage({id: "employees.detail.information.sickDays", defaultMessage: "Sick Days"}),
                                value: employee?.totalSickNoteDays ?? nullOrUndefined
                            },*/
                            {
                                name: intl.formatMessage({id: "employees.detail.information.entranceDate", defaultMessage: "Entrance Date"}),
                                value: employee?.entranceDate ? moment(employee?.entranceDate).format("ddd DD MMM YYYY") : nullOrUndefined
                            },
                        ]}/>
                    </TabsContent>
                    <TabsContent value={workingPlanTab} className={"space-y-4"}>
                        <DetailViewCard
                            header={intl.formatMessage({id: "employees.detail.workingPlans", defaultMessage: "Working Plans"})}
                            rows={[
                                {jsxElement: (
                                    <WorkingPlan
                                        userUUID={employee.userUUID}
                                        employmentType={employmentType}
                                        rePopulateRecord={rePopulateRecord}
                                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                                        workingPlans={workingPlans.data}
                                        loadingWorkingPlans={workingPlans.loading}
                                        reFetchWorkingPlans={workingPlans.setRefetchWorkingPlans}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                        <DetailViewCard
                            header={intl.formatMessage({id: "employees.detail.employmentHistory", defaultMessage: "Employment History"})}
                            rows={[
                                {jsxElement: (
                                    <EmploymentHistory
                                        userUUID={employee.userUUID}
                                        rePopulateRecord={rePopulateRecord}
                                        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
                                        reFetchWorkingPlans={workingPlans.setRefetchWorkingPlans}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                    </TabsContent>
                    <TabsContent value={vacationPlanTab} className={"space-y-4"}>
                        <DetailViewCard
                            header={intl.formatMessage({id: "employees.detail.vacationCredits", defaultMessage: "Vacation Credits"})}
                            rows={[
                                {jsxElement: (
                                    <VacationCredits
                                        userUUID={employee.userUUID}
                                        reloadVacationPlanTab={reloadVacationPlanTab}
                                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                        <DetailViewCard
                            header={intl.formatMessage({id: "employees.detail.annualVacation", defaultMessage: "Annual Vacation"})}
                            rows={[
                                {jsxElement: (
                                    <AnnualVacation
                                        userUUID={employee.userUUID}
                                        reloadVacationPlanTab={reloadVacationPlanTab}
                                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                        <DetailViewCard
                            header={
                                <div className={"flex flex-row gap-2 items-center"}>
                                    <FormattedMessage id={"employees.detail.manualVacationCarryOvers"} defaultMessage={"Manual Vacation Carry Overs"}/>
                                    <TaimInfo
                                        size={"18"}
                                        text={intl.formatMessage({id: "employees.detail.manualVacationCarryOversInfo", defaultMessage: "The manual transfer of vacation days is used for the subsequent transfer of remaining vacation days from the previous year when using it for the first time."})}
                                    />
                                </div>
                            }
                            rows={[
                                {jsxElement: (
                                    <ManualVacationCarryOvers
                                        userUUID={employee.userUUID}
                                        reloadVacationPlanTab={reloadVacationPlanTab}
                                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                        <DetailViewCard
                            header={
                                <div className={"flex flex-row gap-2 items-center"}>
                                    <FormattedMessage id={"employees.detail.vacationExceptionRules"} defaultMessage={"Vacation Exception Rules"}/>
                                    <TaimInfo
                                        size={"18"}
                                        text={intl.formatMessage({id: "employees.detail.vacationExceptionRulesInfo", defaultMessage: "The exception rule for vacation carryovers applies only to the entered year to extend the expiration period."})}
                                    />
                                </div>
                            }
                            rows={[
                                {jsxElement: (
                                    <VacationExceptionRules
                                        userUUID={employee.userUUID}
                                        reloadVacationPlanTab={reloadVacationPlanTab}
                                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                                    />
                                )},
                            ]}
                            sizeFit={true}
                        />
                    </TabsContent>
                    <TabsContent value={workingTimeAccountPlanTab} className={"space-y-4"}>
                        <WorkingTimeAccount userUUID={employee.userUUID}/>
                    </TabsContent>
                </Tabs>
            </>
        ) : (
            <FormattedMessage
                id={"pick_a_record"}
                defaultMessage={"Select a record to view details."}
            />
        )}
    </>)
}




const EmployeesDetailView: React.FC<EmployeesDetailViewProps> = (
    {
        employee,
        rePopulateRecord,
        loading
    }
) => {
    const axiosInstance = useAxiosInstance();
    const {
        workingTimeProgress,
        reLoadWorkingTimeProgress
    } = useEmployeeWorkingTimeProgress(employee?.userUUID)
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>([])
    const [employmentType, setEmploymentType] = useState<EmploymentType>()



    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (employee && employmentTypes) setEmploymentType(employmentTypes.find((employmentType) => {
            return employmentType.type === employee.employmentType
        }))
    }, [employee, employmentTypes]);

    useEffect(() => {
        fetchEmployeeTypes()
    }, []);

    return <ContentComponent
        employee={employee}
        workingTimeProgress={workingTimeProgress}
        reLoadWorkingTimeProgress={reLoadWorkingTimeProgress}
        employmentType={employmentType}
        rePopulateRecord={rePopulateRecord}
        loading={loading}
    />
}


export default EmployeesDetailView;