import DataTable, {Column} from "Core/Table/data-table";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import DataTableTools, {Option} from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {Action} from "Core/Table/data-table-actions";
import {Badge} from "components/badge";
import statusVariants from "Core/functions/status-variants";
import {
    TimeCorrection,
    TimeCorrectionsPayload,
    TimeCorrectionsRequest, timeCorrectionsUrl
} from "AttendanceManagement/Controllers/time-corrections-controller";
import {TimeCorrectionFormatted} from "AttendanceManagement/Controllers/time-corrections-controller";
import {useIntl} from "react-intl";
import moment from "moment";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {DataTablePayload} from "../../Core/Table/interfaces";
import {nullOrUndefined} from "../../Core/constants/variables";
import {VacationFormatted} from "../Controllers/vactions-controller";

export const statuses: Option[] = [
  {
    value: "Pending",
    label: "Pending",
      translationName: 'pending'
  },
  {
    value: "Accepted",
    label: "Accepted",
      translationName: 'accepted'
  },
  {
    value: "Rejected",
    label: "Rejected",
      translationName: 'rejected'
  },
]


type DataTableProps = {
    timeCorrectionRequest: TimeCorrectionsRequest
    formattedData: TimeCorrectionFormatted[]
    selectedRecord?: string
    setSelectedRecord: React.Dispatch<SetStateAction<string | undefined>>
    payload: TimeCorrectionsPayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    detailData: TimeCorrectionFormatted | null
    setDetailData: React.Dispatch<TimeCorrectionFormatted | null>
    loading: boolean
    reFetch: Dispatch<SetStateAction<boolean>>
}

const TimeCorrectionsDataTable = ({
    timeCorrectionRequest,
    selectedRecord,
    setSelectedRecord,
    formattedData,
    payload,
    setPayload,
    detailData,
    setDetailData,
    loading
}: DataTableProps) => {
    const intl = useIntl();
    const timeCorrectionColumns: Column[] = [
        {
            backendKey: "userFullName",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.employee", defaultMessage: "Employee"}),
            sortable: true,
            isSorted: "asc"
        },
        {
            backendKey: "correctionCheckInDate",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.date", defaultMessage: "Date"}),
            sortable: true,
            isSorted: "desc"
        },
        {
            backendKey: "beforeCorrectionCheckInTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.currentIn", defaultMessage: "Current In"}),
            sortable: true
        },
        {
            backendKey: "beforeCorrectionCheckOutTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.currentOut", defaultMessage: "Current Out"}),
            sortable: true
        },
        {
            backendKey: "correctionCheckInTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.newIn", defaultMessage: "New In"}),
            sortable: true
        },
        {
            backendKey: "correctionCheckOutTime",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.newOut", defaultMessage: "New Out"}),
            sortable: true
        },
        {
            backendKey: "statusBadge",
            frontendKey: intl.formatMessage({id: "attendance.timeCorrections.table.status", defaultMessage: "Status"}),
            sortable: true
        },
        {backendKey: "actions", frontendKey: "actions"},
    ];

    const handleSetDetailData = (input: TimeCorrectionFormatted) => {
        if (!input) return;
        setSelectedRecord(input?.pk)
        setDetailData(input)
    }

    useEffect(() => {
        const getSelectedValue = () => {
            let v = formattedData.find(elem => elem.pk === selectedRecord)
            if (v) setDetailData(v)
        }

        getSelectedValue()
    }, [formattedData]);

    return(
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.status", defaultMessage: "Status"}),
                            filterBy: 'filter.status',
                            options: statuses ?? [],
                        },
                        {
                            type: "dateFromTo",
                            from: "filter.startDate",
                            to: "filter.endDate",
                            disabled: true
                        }
                    ]}
                />
                <DataTable
                    data={formattedData}
                    loading={loading}
                    setDetailData={handleSetDetailData}
                    detailData={detailData}
                    setPayload={setPayload}
                    columns={timeCorrectionColumns}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={timeCorrectionRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default TimeCorrectionsDataTable;