import {useEffect, useState} from "react";
import {arrayOutputType} from "zod";

export type FieldErrors = {
    [key: string]: string[]
}

export type ServerErrorsType = {
    detail?: string[]
    non_field_errors?: string[]
} | FieldErrors

export const useServerErrors = (
    errors?: ServerErrorsType,
    form?: any
) => {
    const [detailErrors, setDetailErrors] = useState<string[]>();
    const [nonFieldErrors, setNonFieldErrors] = useState<string[]>();
    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (!errors) return

        if (errors.hasOwnProperty("detail")) {
            setDetailErrors(errors.detail);
            setFieldErrors({});
            setNonFieldErrors(undefined);
        } else if (errors.hasOwnProperty("non_field_errors")) {
            setNonFieldErrors(errors.non_field_errors);
            setDetailErrors(undefined);
            setFieldErrors({});
        } else if (typeof errors === "object") {
            setFieldErrors(errors)
            setDetailErrors(undefined);
            setNonFieldErrors(undefined);
        } else {
            setFieldErrors({});
            setDetailErrors(undefined);
            setNonFieldErrors(undefined);
        }
    }, [errors]);

    useEffect(() => {
        if (fieldErrors) {
            Object.keys(fieldErrors).forEach((key) => {
                if (fieldErrors?.hasOwnProperty(key)) {
                    const isString = typeof fieldErrors[key][0] === "string";
                    const isObject = typeof fieldErrors[key] === "object";
                    if (isString) {
                        let message = fieldErrors[key][0] || '';
                        form.setError(key, { message });
                    } else if (isObject && key !== "records" && key !== "workingPlan") {
                        let nestedObject = fieldErrors[key]
                        Object.keys(nestedObject).forEach((nestedKey) => {
                            // @ts-ignore
                            let message = fieldErrors[key][nestedKey][0];
                            form.setError(`${key}.${nestedKey}`, {message});
                        })
                    }
                }
            });
        }
    }, [fieldErrors, form]);

    useEffect(() => {
        if (!errors) return

        if (Object.keys(errors).length === 0 && form) {
            form.clearErrors();
        }
    }, [errors]);

    return {fieldErrors, detailErrors, nonFieldErrors}
}