import DataTable, {Column} from "Core/Table/data-table";
import React, {SetStateAction, useContext, useEffect, useState} from "react";
import DataTableTools, {Option} from "Core/Table/data-table-tools";
import {
    Employee,
    EmployeesRequest,
    employeesUrl,
    EmploymentType,
    Role, useEmployeeWorkingTimeProgress
} from "EmployeeManagement/Controllers/employee-controller";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {employeeManagementUrl} from "EmployeeManagement";
import {FormattedMessage, useIntl} from "react-intl";
import {EmployeeFormatted} from "EmployeeManagement/Controllers/employee-controller";
import {PermissionContext} from "Core/utilities/PermissionProvider";
import {DataTablePayload} from "Core/Table/interfaces";
import DataTablePagination from "../../Core/Table/data-table-pagination";
import {role} from "../Controllers/role-controller";

type EmployeesDataTableProps = {
    formattedData: EmployeeFormatted[]
    employeesRequest: EmployeesRequest
    setDetailData: (v: EmployeeFormatted) => void
    // setDetailData: React.Dispatch<SetStateAction<Employee>>
    detailData: Employee
    payload: DataTablePayload
    setPayload: React.Dispatch<SetStateAction<DataTablePayload>>
    loading: boolean
    reFetch?: any
    triggerEmployeesCount: boolean
}


const EmployeesDataTable = (
{
    employeesRequest,
    formattedData,
    setDetailData,
    detailData,
    payload,
    setPayload,
    loading,
    triggerEmployeesCount
}: EmployeesDataTableProps) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [roles, setRoles] = useState<role[]>([]);
    const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>();
    const [roleFilters, setRoleFilters] = useState<Option[]>()
    const [employmentTypeFilters, setEmploymentTypeFilters] = useState<Option[]>()
    const [activeUsers, setActiveUsers] = useState<number>(0)
    const [countStatus, setStatus] = useState<"idle" | "pending" | "done">("idle");
    const permissionContext = useContext(PermissionContext)

    const employeeColumns: Column[] = [
        {
            backendKey: "fullName",
            frontendKey: intl.formatMessage({id: "employees.table.employeeName", defaultMessage: "Employee Name"}),
            sortable: true
        },
        {
            backendKey: "role",
            frontendKey: intl.formatMessage({id: "employees.table.role", defaultMessage: "Role"}),
            sortable: true
        },
        {
            backendKey: "employmentType",
            frontendKey: intl.formatMessage({id: "employees.table.employmentType", defaultMessage: "Employment Type"}),
            sortable: true
        },
        {
            backendKey: "email",
            frontendKey: intl.formatMessage({id: "employees.table.email", defaultMessage: "Email"}),
            sortable: true
        },
        {
            backendKey: "actions",
            frontendKey: "actions"
        },
    ]

    const fetchRoles = () => {
        axiosInstance.get(employeeManagementUrl + 'roles/')
            .then((res) => {
                setRoles(res.data)
            })
            .catch((err) => console.log(err))
    }

    const fetchEmployeeTypes = () => {
        axiosInstance.get(employeeManagementUrl + 'employment-types/')
            .then((res) => {
                setEmploymentTypes(res.data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchRoles();
        fetchEmployeeTypes();
    }, [permissionContext.isAdmin]);

    useEffect(() => {
        if (roles) {
            setRoleFilters(() => roles?.map((role: Role) => {
                return {label: role.name, value: role.pk.toString()} as Option
            }) as Option[])
        }
    }, [roles]);

    useEffect(() => {
        if (employmentTypes) {
            setEmploymentTypeFilters(() => employmentTypes?.map((employmentType: EmploymentType) => {
                return {label: employmentType.type, value: employmentType.type} as Option
            }) as Option[])
        }
    }, [employmentTypes]);

    useEffect(() => {
        setStatus("pending")

        const fetchEmployees = async () => {
            try {
                const res = await axiosInstance.get(employeesUrl);
                let employees: Employee[] = res.data;
                let activeEmployees = employees.filter(employee => employee.isActive)

                setActiveUsers(prevState => activeEmployees.length)
            } catch (err) {
                console.log(err);
            }
        }

        fetchEmployees()
            .finally(() => setStatus("done"));
    }, [employeesRequest, triggerEmployeesCount]);

    return (
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools
                    payload={payload}
                    setPayload={setPayload}
                    filters={[
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.role", defaultMessage: "Role"}),
                            filterBy: 'filter.role',
                            options: roleFilters ?? [],
                        },
                        {
                            type: "simple",
                            title: intl.formatMessage({id: "data.table.filter.employmentType", defaultMessage: "Employment Type"}),
                            filterBy: 'filter.employmentType',
                            options: employmentTypeFilters ?? [],
                        },
                    ]}
                >
                    <div className="grow text-right text-xs text-gray-400">
                        <FormattedMessage
                            id={"employees.table.employees_count"}
                            defaultMessage={"( Active Employees: 0 )"}
                            values={{
                                count: countStatus === "done" ? activeUsers.toString() : 'loading..'
                            }}
                        />
                    </div>
                </DataTableTools>
                <DataTable
                    data={formattedData}
                    setDetailData={setDetailData}
                    detailData={detailData}
                    setPayload={setPayload}
                    columns={employeeColumns}
                    loading={loading}
                    payload={payload}
                />
            </div>
            <DataTablePagination request={employeesRequest} payload={payload} setPayload={setPayload}/>
        </div>
    )
}

export default EmployeesDataTable;