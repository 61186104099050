import React, {useContext, useEffect, useState} from "react";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {WORKING_TIME_ACCOUNT_URL} from "./index";
import DataTable, {Column} from "../../../Core/Table/data-table";
import DataTablePagination from "../../../Core/Table/data-table-pagination";
import {DataTablePayload, DataTableRequest} from "../../../Core/Table/interfaces";
import {useIntl} from "react-intl";
import moment from "moment";
import DataTableTools from "../../../Core/Table/data-table-tools";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../../components/select";
import {FROM_YEAR, getYearsArray} from "../../../Core/constants/variables";

const DROPDOWN_YEARS = getYearsArray(FROM_YEAR.getFullYear(), new Date().getFullYear())

type MonthSummaries = {
    sickNoteTotalDays: number
    vacationTotalDays: number
    compensatoryTimeOffTotalDays: number
    publicHolidayTotalDays: number
    absenceTotalDays: number
    year: number
    plannedTime: string
    spentTime: string
    attendanceTotalHours: string
    sickNoteTotalHours: string
    vacationTotalHours: string
    compensatoryTimeOffTotalHours: string
    publicHolidayTotalHours: string
    absenceTotalHours: string
    percentage: number
    overtime: string
    prevOvertime: string
    status: string
    lowerLimit: string
    upperLimit: string
    workingTimeAccountIsActivated: boolean
    firstName: string
    lastName: string
    fullName: string
    userUUID: string
    month: number
    inflowDuration: string
    outflowDuration: string
    manualCarryoverCorrectionDuration: string
}

type FormattedMonthSummaries = MonthSummaries & {
    monthFormatted: string
}

export interface MonthSummariesResponse extends DataTableRequest {
    data: MonthSummaries[]
}

export const useMonthSummaries = (payload: DataTablePayload, userUUID: string, refetch: boolean, handleRefetch: (v: boolean) => void, year: number = 2024) => {
    const [response, setResponse] = useState<MonthSummariesResponse>({
        recordsTotal: 0,
        recordsFiltered: 0,
        next: "",
        previous: "",
        data: []
    })
    const [formattedData, setFormattedData] = useState<FormattedMonthSummaries[]>([])
    const [loading, setLoading] = useState(false)
    const permissionContext = useContext(PermissionContext)
    const axiosInstance = useAxiosInstance()

    const fetchData = () => {
        setLoading(true);

        axiosInstance.get(WORKING_TIME_ACCOUNT_URL + `${userUUID}/month-summaries/${year}`, {
            params: payload
        })
            .then(res => {
                let data: FormattedMonthSummaries[] = res.data?.data?.map((record: MonthSummaries) => ({
                    ...record,
                    ...(record.month && {monthFormatted: moment({
                            month: record.month - 1
                        }).format('MMMM')
                    })
                }))

                if (year === new Date().getFullYear()) {
                    data = data.filter((rec) => {
                        if (rec.month - 1 <= new Date().getMonth()) {
                            return rec
                        }
                    })
                }

                setResponse(res.data)
                setFormattedData(data)
            })
            .catch(err => console.log(err))
            .finally(() => {
                setLoading(false)
                handleRefetch(false)
            })
    }


    useEffect(() => {
        fetchData();
    }, [payload, userUUID, year, refetch, permissionContext.isAdmin]);

    return {response, formattedData, loading}
}

export const MonthSummariesTable: React.FC<{userUUID: string}> = ({userUUID}) => {
    const intl = useIntl()
    const [refetch, setRefetch] = useState(false)
    const handleRefetch = (v: boolean) => setRefetch(v)
    const [year,setYear] = useState(new Date().getFullYear())
    const [payload, setPayload] = useState<DataTablePayload>({
        start: 0,
        length: 5,
        ordering: '-month'
    })
    const {
        response,
        formattedData,
        loading
    } = useMonthSummaries(payload, userUUID, refetch, handleRefetch, year)

    const columns: Column[] = [
        {
            frontendKey: intl.formatMessage({id: "month", defaultMessage: "Month"}),
            backendKey: 'monthFormatted',
            sortable: false
        },
        {
            frontendKey: intl.formatMessage({id: "carryover", defaultMessage: "Carryover"}),
            backendKey: 'prevOvertime',
            sortable: false
        },
        {
            frontendKey: intl.formatMessage({id: "inflow", defaultMessage: "Inflow"}),
            backendKey: 'inflowDuration',
            sortable: false
        },
        {
            frontendKey: intl.formatMessage({id: "outflow", defaultMessage: "Outflow"}),
            backendKey: 'outflowDuration',
            sortable: false
        },
        {
            frontendKey: intl.formatMessage({id: "balance", defaultMessage: "Balance"}),
            backendKey: 'overtime',
            sortable: false
        },
    ]

    const handleSelect = (value: string) => {
        setYear(Number(value))
    }

    useEffect(() => {
        setYear(new Date().getFullYear())
    }, [userUUID]);

    return (
        <div className="flex flex-col space-y-4">
            <Select onValueChange={handleSelect} value={year.toString()}>
                <SelectTrigger className="w-[100px]">
                    <SelectValue placeholder={intl.formatMessage({id: "month", defaultMessage: "Month"})} />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {DROPDOWN_YEARS.map(year => (
                            <SelectItem value={year.toString()}>{year.toString()}</SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </Select>
            <DataTable
                columns={columns}
                data={formattedData}
                loading={loading}
                payload={payload}
                setPayload={setPayload}
            />
            <DataTablePagination request={response} payload={payload} setPayload={setPayload}/>
        </div>
    )
}