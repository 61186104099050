import {IntlProvider} from "react-intl";
import React, {createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import English from "../../languages/en-US.json";
import Dutch from "../../languages/de-DE.json";
import moment from "moment-timezone";
import 'moment/locale/de';
import 'moment/locale/en-gb';
import {Toaster} from "../../components/toaster";
import {useNavigate} from "react-router-dom";

moment.tz.setDefault('Europe/Berlin');

type TEnvironment = "LOCAL" | "STAGING" | "PRODUCTION"

type TWrapperContext = {
    language?: string
    changeLanguage?: Function
    environment?: TEnvironment
    currSystem?: TSystem
    changeCurrSystem?: (value:TSystem | undefined) => void
}

export type TSystem = {
    repr: string
    value: string
}

const getCurrEnvironment = () => {
    return localStorage.getItem("environment") as TEnvironment
}


export const Context: React.Context<TWrapperContext> = createContext({});


const navigatorLanguage = window.navigator.language || "en-US";

let lang: any;
if (navigatorLanguage.includes("de")) {
    lang = Dutch
    moment.locale("de")
} else {
    lang = English
    moment.locale("en-gb")
}

export const Wrapper = (props: {children: ReactNode}) => {
    const [language, setLanguage] = useState(navigatorLanguage);
    const [messages, setMessages] = useState(lang);
    const [currSystem,setCurrSystem] = useState<TSystem>()
    const environment = getCurrEnvironment();

    const changeCurrSystem = (value: TSystem | undefined) => {
        setCurrSystem(value)
    }

    const changeLanguage = (value: string) => {
        const newLanguage = value;
        setLanguage(newLanguage);
        if (newLanguage.includes("de")) {
            setMessages(Dutch)
            moment.locale("de")
        } else {
            setMessages(English)
            moment.locale("en-gb")
        }
    }

    return (
        <Context.Provider value={{ language, changeLanguage, environment, currSystem, changeCurrSystem }}>
            <IntlProvider locale={language} messages={messages}>
                <>{props.children}</>
                <Toaster/>
            </IntlProvider>
        </Context.Provider>
    )
}