import DataTable, {Column, DataTableI} from "Core/Table/data-table";
import {ServiceRequest, Service, servicesUrl} from "ProjectManagement/Controller/services-controller";
import React, {useEffect, useState} from "react";
import DataTableTools from "Core/Table/data-table-tools";
import DataTablePagination from "Core/Table/data-table-pagination";
import {Action} from "Core/Table/data-table-actions";
import {useAxiosInstance} from "Core/utilities/AxiosInstance";
import {toast} from "components/use-toast";
import {EditServiceForm} from "ProjectManagement/Forms/service-forms";
import {useIntl} from "react-intl";

export interface ServiceFormatted extends Service {
    actions?: Action[]
}
interface ServicesTable extends DataTableI<Service> {
    request: ServiceRequest
    reloadData: (value: boolean) => void
}


export const ServicesTable: React.FC<ServicesTable> = (
    {
        payload,
        setPayload,
        detailData,
        setDetailData,
        loading,
        request,
        reloadData
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const [formattedData, setFormattedData] = useState<ServiceFormatted[]>([]);
    const costCarrierColumns: Column[] = [
        {
            backendKey: "name",
            frontendKey: intl.formatMessage({id: "project.service.name", defaultMessage: "Service Name"}),
            sortable: true
        },
        {backendKey: "actions", frontendKey: "actions"},
    ]

    const rePopulateRecord = (data: Service) => {
        setFormattedData(prevState => {
            return prevState.map(service => {
                if (service.pk === data.pk) {
                    let updatedRec: ServiceFormatted = {
                        ...service,
                        ...data,
                        actions: [
                            {
                                type: "edit",
                                content: (<EditServiceForm service={{...data}} rePopulateRecord={rePopulateRecord}/>)
                            },
                            {
                                type: "delete",
                                handleAction: () => handleDelete(service.pk)
                            },
                        ]
                    }
                    setDetailData(updatedRec);
                    return updatedRec;
                }
                return service;
            })
        })
    }

    useEffect(() => {
        const formatData: ServiceFormatted[] = request?.data.map((service, index) => {
            return {
                ...service,
                actions: [
                    {
                        type: "edit",
                        content: (<EditServiceForm service={service} rePopulateRecord={rePopulateRecord}/>)
                    },
                    {
                        type: "delete",
                        handleAction: () => handleDelete(service.pk)
                    },
                ]
            }
        });

        setFormattedData(formatData);
    }, [request.data]);

    const handleDelete = (pk: string) => {
        axiosInstance.delete(servicesUrl + `${pk}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reloadData(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    return (
        <div className="space-y-4 h-full flex flex-col justify-between">
            <div className="flex flex-col gap-4">
                <DataTableTools payload={payload} setPayload={setPayload}/>
                <DataTable
                    columns={costCarrierColumns}
                    data={formattedData}
                    payload={payload}
                    setPayload={setPayload}
                    detailData={detailData}
                    setDetailData={setDetailData}
                    loading={loading}
                />
            </div>
            <DataTablePagination request={request} payload={payload} setPayload={setPayload}/>
        </div>
    )
}