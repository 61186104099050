import { zodResolver } from "@hookform/resolvers/zod"
import {ControllerRenderProps, FieldValues, useForm} from "react-hook-form"
import * as z from "zod"
import { Button } from "../../components/button"
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "../../components/form"
import {DialogClose, DialogFooter} from "../../components/dialog";
import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import ErrorMessageList from "../../Core/components/ErrorMessageList";
import {Input} from "../../components/input";
import * as XLSX from 'xlsx';
import {ADDRESS_FILE_FIELDS, BASE_FILE_FIELDS, EmployeeImported} from "../controller/employees_controller";

const MAX_FILE_SIZE = 5000000;
const ACCEPTED_FILE_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "application/vnd.ms-excel", // XLS
    "text/csv" // CSV
];

const formSchema = z.object({
    baseFile: z.any(),
    addressFile: z.any()
});


type FormEmployeeCreateProps = {
    storeData: (employees: EmployeeImported[]) =>  Promise<void>
}

export const FormImport: React.FC<FormEmployeeCreateProps>  = ({storeData}) => {
    const intl = useIntl();
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {},
    });
    const [importedFiles, setImportedFiles] = useState<any[]>([]);
    const closeModal = useRef<HTMLElement>();
    const [nonFieldErrors, setNonFieldErrors] = useState<string[]>([]);

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        if (importedFiles.length !== 2) {
            setNonFieldErrors(prevState => ['Please pick two files.'])
            return;
        }
        setNonFieldErrors([])

        const mergeSheets = (firstSheet: any[], secondSheet: any[]) => {
            let mergedArray: EmployeeImported[] = [...firstSheet];

            secondSheet.forEach((obj) => {
                const existing = mergedArray.find((e) => {
                    const accNumber = e["Personalnummer"] || e["Nummer"];
                    const objNumber = obj["Personalnummer"] || obj["Nummer"];
                    return accNumber === objNumber;
                });

                if (existing) {
                    Object.assign(existing, obj);
                } else {
                    mergedArray.push(obj);
                }
            });

            storeData(mergedArray)
                .then(() => {
                    console.log('data imported');
                    closeModal.current?.click();
                })
                .catch((err) => console.log(err))
        };

        mergeSheets(importedFiles[0], importedFiles[1])
    };


    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<FieldValues, any>) => {
        field.onChange(e);

        const file = e.target.files?.item(0);

        if (!file) {
            switch (field.name) {
                case 'baseFile': {
                    setImportedFiles(prevState => {
                        let newState = [...prevState]
                        newState[0] = null
                        return newState
                    })
                    break;
                }
                case 'addressFile': {
                    setImportedFiles(prevState => {
                        let newState = [...prevState]
                        newState[1] = null
                        return newState
                    })
                    break;
                }
                default:
                    break;
            }

            form.setError(field.name, { message: 'This field is required.' });

            return;
        } else if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
            form.setError(field.name, { message: 'Only .XSLX, .XLS, and .CSV formats are supported.' });
            return;
        } else if (file.size >= MAX_FILE_SIZE) {
            form.setError(field.name, { message: 'Max file size is 5MB.' });
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const workbook = XLSX.read(event?.target?.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const sheetData: any[] = XLSX.utils.sheet_to_json(sheet, { defval: "" });

            switch (field.name) {
                case 'baseFile': {
                    let includes;
                    if (sheetData.length) {
                        const keys = Object.keys(sheetData[0])

                        for (const key of keys) {
                            includes = BASE_FILE_FIELDS.includes(key);
                            if (!includes) break;
                        }
                    }

                    if (!includes) {
                        form.setError(field.name, {message: 'Please pick a correct file.'})
                        break;
                    }

                    setImportedFiles(prevState => {
                        let newState = [...prevState];
                        newState[0] = sheetData
                        return newState
                    })
                    break;
                }
                case 'addressFile': {
                    let includes;
                    if (sheetData.length) {
                        const keys = Object.keys(sheetData[0])

                        for (const key of keys) {
                            includes = ADDRESS_FILE_FIELDS.includes(key);
                            if (!includes) break;
                        }
                    }

                    if (!includes) {
                        form.setError(field.name, {message: 'Please pick a correct file.'})
                        break;
                    }

                    setImportedFiles(prevState => {
                        let newState = [...prevState];
                        newState[1] = sheetData
                        return newState
                    })
                    break;
                }
                default:
                    break;
            }
        };

        form.clearErrors(field.name);

        reader.readAsBinaryString(file);
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">

                <FormField
                    control={form.control}
                    name="baseFile"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"import_management.base_file"} defaultMessage={"Base File"}/>
                                </FormLabel>
                                    <FormControl>
                                        <Input
                                            type="file"
                                            placeholder={intl.formatMessage({id: "import_management.base_file_placeholder", defaultMessage: "import data"})}
                                            value={field.value}
                                            onChange={(e) => handleFileUpload(e, field)}
                                        />
                                    </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <FormField
                    control={form.control}
                    name="addressFile"
                    render={({ field }) => {
                        return (
                            <FormItem>
                                <FormLabel>
                                    <FormattedMessage id={"import_management.address_file"} defaultMessage={"Address File"}/>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        type="file"
                                        placeholder={intl.formatMessage({id: "import_management.base_file_placeholder", defaultMessage: "import data"})}
                                        value={field.value}
                                        onChange={(e) => handleFileUpload(e, field)}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        );
                    }}
                />

                <ErrorMessageList errors={nonFieldErrors}/>

                <DialogFooter className="justify-between space-x-2 px-0 pb-0">
                    <DialogClose ref={closeModal as any} asChild>
                        <Button variant="outline">
                            <FormattedMessage id={"button.leave"} defaultMessage={"Leave"}/>
                        </Button>
                    </DialogClose>
                    <Button
                        variant="taimDefault"
                        type="submit"
                    ><FormattedMessage id={"button.submit"} defaultMessage={"Submit"}/></Button>
                </DialogFooter>
            </form>
        </Form>
    )
}
