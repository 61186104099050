import {useIntl} from "react-intl";
import {PageShape, PageShapeProps} from "../Core/layout/page-shape";
import {Devices} from "./Pages/Devices";
import {Systems} from "./Pages/Systems";
import {useContext, useEffect} from "react";
import {PermissionContext} from "../Core/utilities/PermissionProvider";
import {useNavigate} from "react-router-dom";



export const systemsUrl = 'system-management/customer-systems/';
export const devicesUrl = 'system-management/devices/';

export const SystemsManagement = () => {
    const intl = useIntl();
    const permissionContext = useContext(PermissionContext);
    const navigate = useNavigate();
    const systemsName = intl.formatMessage({id: "sd.systems", defaultMessage: "Systems"});
    const devicesName = intl.formatMessage({id: "sd.devices", defaultMessage: "Devices"});


    const pageContent: PageShapeProps = {
        header: intl.formatMessage({id: "sd.header", defaultMessage: "Systems Management"}),
        pageEntries: [
            props => <Systems name={systemsName} pagesState={props.pagesState}/>,
            props => <Devices name={devicesName} pagesState={props.pagesState}/>
        ],
        sections: [
            {name: systemsName},
            {name: devicesName},
        ],
        defaultSection: systemsName
    }

    useEffect(() => {
        if (!permissionContext.isAdmin) {
            navigate('/')
        }
    }, []);

    return <PageShape {...pageContent}/>
}