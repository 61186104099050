import React, {useEffect, useState} from 'react';
import {Routes, Route, BrowserRouter, useLocation, Outlet} from "react-router-dom";
import AppLayout from "./Core/layout/app-layout";
import Dashboard from "./Dashboard";
import Attendance from "./AttendanceManagement";
import Timesheet from "./TimesheetManagement";
import EmployeeManagement from "./EmployeeManagement";
import {ProjectManagement} from "./ProjectManagement";
import {FallbackPage} from "./Core/AppSections/fallback-page";
import {ComingSoonPage} from "./Core/AppSections/coming-soon-page";
import AuthenticationPage from "./Authentication";
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "./components/dialog";
import {WifiHigh, WifiSlash} from "@phosphor-icons/react";
import {AuthMiddleware} from "./Core/utilities/Authenticator";
import {SystemsManagement} from "./SystemsManagement";
import ImportManagement from "./ImportManagement";
import ShiftManagement from "./ShiftManagement";
import {MultiSelect} from "./Core/components/multi-select";
import { Cat, Dog, Fish, Rabbit, Turtle } from "lucide-react";
import {useForm} from "react-hook-form";
import * as z from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./components/form"
import {zodResolver} from "@hookform/resolvers/zod";
import {FormattedMessage} from "react-intl";
import {TimeValue} from "react-aria";
import {TimePicker} from "./components/date-time-picker/time-picker";
import {LoadingBtn} from "./TestComponents/loading-btn";

const NetworkNotice = () => {
    const location = useLocation()
    const [online, setOnline] = useState<boolean>(window.navigator.onLine)
    const [open, setOpen] = useState<boolean>(false)

    const handleOnline = () => {
        setOnline(true)
        handleOpen()
        setTimeout(() => {
            setOpen(false)
        }, 3000)
    };
    const handleOffline = () => {
        setOnline(false)
        handleOpen()
    };

    const handleOpen = () => setOpen(!open)

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        if (!online) handleOffline()
    }, [location]);

    return (
        <Dialog open={open} onOpenChange={handleOpen}>
          <DialogContent onInteractOutside={(e) => e.preventDefault()}>
            <DialogHeader>
              <DialogTitle>{online ? "Network Restored" : "Network Disconnected"}</DialogTitle>
              <DialogDescription className="flex flex-row items-center p-6">
                <div className="mr-4">
                  {online ? <WifiHigh size={72}/> : <WifiSlash size={72}/>}
                </div>
                <div className="">
                  {online ?
                    <p className="text-lg">Your connection is back.</p> :
                    <p className="text-lg">Oops! Looks like you're offline.</p>
                  }
                  {!online &&
                    <p className="text-sm text-gray-600 mt-2">Please check your network settings and try again.</p>
                  }
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
    )
}


function App() {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={
                        <>
                            <NetworkNotice/>
                            <Outlet/>
                        </>
                    }>
                        <Route path="/account-management/login" element={<AuthenticationPage/>}/>
                        <Route element={<AuthMiddleware><AppLayout/></AuthMiddleware>}>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="/employee-management" element={<EmployeeManagement/>}/>
                            <Route path="/project-management" element={<ProjectManagement/>}/>
                            <Route path="/attendance-management" element={<Attendance/>}/>
                            <Route path="/shift-management" element={<ShiftManagement/>}/>
                            <Route path="/systems-management" element={<SystemsManagement/>}/>
                            <Route path="/import-management" element={<ImportManagement/>}/>
                            <Route path="/timesheet-management" element={<Timesheet/>}/>
                            <Route path="/help-center" element={<ComingSoonPage/>}/>
                            <Route path="/contact-us" element={<ComingSoonPage/>}/>
                            <Route path="/testing-page" element={
                                <div className="flex flex-col gap-10">
                                    <LoadingBtn/>
                                </div>
                            }/>
                        </Route>
                        <Route path="*" element={<FallbackPage/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
