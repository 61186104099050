import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {Skeleton} from "../../../components/skeleton";
import {FormattedMessage, useIntl} from "react-intl";
import DataTable, {Column} from "../../../Core/Table/data-table";
import {Button} from "../../../components/button";
import {AddAnnualVacation, EditAnnualVacation} from "./forms";
import {useAxiosInstance} from "../../../Core/utilities/AxiosInstance";
import {employeesUrl} from "../../Controllers/employee-controller";
import {toast} from "components/use-toast";
import {TaimModal} from "Core/components/taim-modal";
import {TAnnualVacation, TAnnualVacationPayload, useAnnualVacations} from "./controller";
import DataTablePagination from "Core/Table/data-table-pagination";
import {PermissionContext} from "../../../Core/utilities/PermissionProvider";

type AnnualVacationProps = {
    userUUID: string
    reloadVacationPlanTab: boolean
    setReloadVacationPlanTab: Dispatch<SetStateAction<boolean>>
}

export const AnnualVacation: React.FC<AnnualVacationProps> = (
    {
        userUUID,
        reloadVacationPlanTab,
        setReloadVacationPlanTab
    }
) => {
    const intl = useIntl();
    const axiosInstance = useAxiosInstance();
    const permissionContext = useContext(PermissionContext);
    const [payload, setPayload] = useState<TAnnualVacationPayload>({
        start: 0,
        length: 5,
    })
    const {
        annualVacations,
        loadingAnnualVacations,
        reFetchAnnualVacations
    } = useAnnualVacations(payload, userUUID, reloadVacationPlanTab);
    const [formattedData, setFormattedData] = useState<TAnnualVacation[]>([])

    const annualVacationColumns: Column[] = [
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.annualVacation.year",
                defaultMessage: "Since Year"
            }),
            backendKey: "year"
        },
        {
            frontendKey: intl.formatMessage({
                id: "employees.detail.annualVacation.days",
                defaultMessage: "Days"
            }),
            backendKey: "days"
        },
        {
            frontendKey: "actions",
            backendKey: "actions"
        },
    ]

    useEffect(() => {
        const formatData: TAnnualVacation[] = annualVacations.data.map((annualVacation) => {
            return {
                ...annualVacation,
                actions: [
                    {
                        type: "edit",
                        content: <EditAnnualVacation
                            userUUID={userUUID}
                            annualVacation={annualVacation}
                            reFetch={reFetchAnnualVacations}
                            setReloadVacationPlanTab={setReloadVacationPlanTab}
                        />
                    },
                    {type: "delete", handleAction: () => handleDelete(annualVacation.pk)}
                ]
            }
        })

        setFormattedData(formatData);
    }, [annualVacations.data]);

    const handleDelete = (id: string) => {
        axiosInstance.delete(employeesUrl + `${userUUID}/annual-vacations/${id}/`)
            .then((res) => {
                toast({
                    title: intl.formatMessage({id: "toast.success", defaultMessage: "Great!"}),
                    description: intl.formatMessage({id: "toast.success.deletedSuccessfully", defaultMessage: "The item has been deleted successfully."})
                });
                reFetchAnnualVacations(true);
                setReloadVacationPlanTab(true);
            })
            .catch((err) => {
                toast({
                    variant: "destructive",
                    title: intl.formatMessage({id: "toast.error", defaultMessage: "Error!"}),
                    description: err.response.data?.detail ?? intl.formatMessage({id: "toast.error.unableToDelete", defaultMessage: "There was an error deleting the item. Please try again."})
                });
            })
    }

    return <div className="flex flex-col gap-4">
        {formattedData.length && (loadingAnnualVacations || !loadingAnnualVacations) ? (
            <>
                <DataTable columns={annualVacationColumns} data={formattedData}/>
                <DataTablePagination request={annualVacations} payload={payload} setPayload={setPayload}/>
            </>
        ) : (!formattedData.length && loadingAnnualVacations) ? (
            <div className="flex flex-col gap-4">
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-1/3 h-[15px]"/>
                <Skeleton className="w-1/2 h-[15px]"/>
                <Skeleton className="w-full h-[15px]"/>
                <Skeleton className="w-1/6 h-[15px]"/>
            </div>
        ) : (
            <FormattedMessage id="no_results" defaultMessage="No results"/>
        )}
        {permissionContext.isAdmin && (
            <div>
                <TaimModal
                    button={(
                        <Button variant="taimDefault2">
                            <FormattedMessage id="employees.detail.annualVacation.create" defaultMessage="Add Annual Vacation"/>
                        </Button>
                    )}
                    header={intl.formatMessage({id: "employees.detail.annualVacation.create", defaultMessage: "Add Annual Vacation"})}
                    dialogContentClassName={"min-w-[600px]"}
                >
                    <AddAnnualVacation
                        userUUID={userUUID}
                        reFetch={reFetchAnnualVacations}
                        setReloadVacationPlanTab={setReloadVacationPlanTab}
                    />
                </TaimModal>
            </div>
        )}
    </div>
}