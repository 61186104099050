import React, {useEffect, useState} from "react";

export class Step<T> {
    constructor(
        public name: string,
        public data: T,
        public isOpen: boolean = false,
        public next: Step<any> | null = null,
        public previous: Step<any> | null = null
    ) {
        this.name = name
        this.data = data
        this.isOpen = !previous
        this.next = next
        this.previous = previous
    }

    getStep (name?: string) {
        if (name?.length) {
            let stepName = this.name
            let nextStep = this.next
            while (stepName !== name && nextStep) {
                stepName = nextStep.name
                if (stepName === name) break
                nextStep = nextStep?.next
            }
            return nextStep
        }
        return this
    }

    setNextStep (next: Step<any>) {
        this.next = next
        next.previous = this
        next.isOpen = false
    }

    setPreviousStep (previous: Step<any>) {
        this.previous = previous
        previous.next = this
    }
}

type TStep = {
    name: string
    title: string
    isOpen: boolean
}


export const useSteps = (inputSteps: string[], entryStep: string) => {
    const [steps, setSteps] = useState<TStep[]>([])
    const [currentStep, setCurrentStep] = useState<string>(entryStep)

    useEffect(() => {
        if (inputSteps.length) {
            let currentIndex = inputSteps.findIndex(elem => elem === entryStep)
            setSteps(inputSteps.map((elem, index) => ({
                name: elem,
                title: elem.split('_').map((word) => word.slice(0,1).toUpperCase() + word.slice(1,)).join(' '),
                isOpen: index <= currentIndex
            })))
        }
    }, [inputSteps.length]);

    const goToNextStep = () => {
        let nextStepIndex = steps.findIndex(elem => elem.name === currentStep) + 1
        if (nextStepIndex) {
            setSteps(prevState => prevState.map((step, index) => {
                if (nextStepIndex === index) {
                    setCurrentStep(step.name)
                    return {
                        ...step,
                        isOpen: true
                    }
                }
                return step
            }))
        }
    }

    const goToPreviousStep = () => {
        let previousStep = steps.findIndex(elem => elem.name === currentStep) - 1
        if (previousStep >= 0) {
            steps.forEach((step, index) => {
                if (previousStep === index) {
                    setCurrentStep(step.name)
                }
            })
        }
    }

    return {steps, setSteps, goToNextStep, goToPreviousStep, currentStep, setCurrentStep}
}