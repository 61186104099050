import React, {useEffect} from "react";
import EmployeesEntry from "./Pages/employees";
import RolesEntry from "./Pages/roles";
import EmploymentTypesEntry from "./Pages/employment-types";
import {useIntl} from "react-intl";
import CompanyGuidelines from "./Pages/company-guidelines";
import {PageShapeExperimental, PageShapeProps} from "../Core/layout/page-shape-experimental";

export const employeeManagementUrl = 'employee-management/'

const EMPLOYEES_TAB = "employees";
const ROLES_TAB = "roles";
const EMPLOYMENT_TYPES_TAB = "employment-types";
const COMPANY_GUIDLINES_TAB = "company-guidelines";

const EmployeeManagement = () => {
    const intl = useIntl();

    const employeesName = intl.formatMessage({id: "employeeManagement.employees", defaultMessage: "Employees"});
    const rolesName = intl.formatMessage({id: "employeeManagement.roles", defaultMessage: "Roles"});
    const employmentTypesName = intl.formatMessage({id: "employeeManagement.employmentTypes", defaultMessage: "Employment Types"});
    const companyGuidelinesName = intl.formatMessage({id: "employeeManagement.companyGuidelines", defaultMessage: "Company Guidelines"});
    const sections = [
        {label: employeesName, value: EMPLOYEES_TAB},
        {label: rolesName, value: ROLES_TAB},
        {label: employmentTypesName, value: EMPLOYMENT_TYPES_TAB},
        {label: companyGuidelinesName, value: COMPANY_GUIDLINES_TAB},
    ]


    const PageContent: PageShapeProps = {
        header: intl.formatMessage({id: "employeeManagement.header", defaultMessage: "Employee Management"}),
        sections: sections,
        pageEntries: [
            props => <EmployeesEntry name={EMPLOYEES_TAB} pagesState={props.pagesState}/>,
            props => <RolesEntry name={ROLES_TAB} pagesState={props.pagesState}/>,
            props => <EmploymentTypesEntry name={EMPLOYMENT_TYPES_TAB} pagesState={props.pagesState}/>,
            props => <CompanyGuidelines name={COMPANY_GUIDLINES_TAB} pagesState={props.pagesState}/>,
        ],
        defaultSection: sections[0],
    }

    return <PageShapeExperimental {...PageContent}/>
}

export default EmployeeManagement;